import { useQuery } from '@tanstack/react-query';
import useExpress from '../../../../useExpress';

const getTrailerEvents = (routeSessionId) => {
    const params = {
        populate: {
            created_by: ['name', 'full_name'],
            'geofence_id.user_id': ['name', 'full_name'],
        },
    };

    if (routeSessionId) {
        params.filter = {
            route_session_id: { $eq: routeSessionId },
        };
    }

    const endpoint = '/trailer-event';
    return useExpress({ endpoint, params });
};

const useGetTrailerEvents = ({ routeSessionId, enabled = true } = {}) => {
    const queryFn = getTrailerEvents(routeSessionId);
    const queryKey = ['trailer-event', routeSessionId];
    return useQuery({ queryKey, queryFn, enabled });
};

export default useGetTrailerEvents;
