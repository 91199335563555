import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import * as Actions from '../../store/actions';
import { TextFieldFormsy } from '@fuse';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import { getUser } from '../../../utils';

class CreateTrailerForm extends Component {
    state = {
        canSubmit: false,
        compartment1: '0',
        compartment2: '0',
        compartment3: '0',
        empty_weight: '0',
        hauler: this.props.defaultHauler ? { name: this.props.defaultHauler.name, value: this.props.defaultHauler.id } : ' ',
    };

    componentDidMount() {
        const { editData, region } = this.props;

        if (!!editData) {
            this.setState({
                compartment1: editData.compartment1 === 0 ? '0' : editData.compartment1,
                compartment2: editData.compartment2 === 0 ? '0' : editData.compartment2,
                compartment3: editData.compartment3 === 0 ? '0' : editData.compartment3,
                empty_weight: region === 'UDA' ? editData.empty_weight : null,
            });
        } else {
            const { id, name, is_subuser, hauling_id, role } = getUser();

            let hauler;
            if (is_subuser && role === 'transport') {
                hauler = this.props.haulingCompanies.find((entry) => entry.value === hauling_id);
            } else hauler = { name, value: id };

            this.setState({ hauler });
        }
    }

    handleHauler = (event, value) => {
        this.setState({ hauler: value });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        const { editData, editTrailer, addTrailer, showMessage, history, region } = { ...this.state, ...this.props };
        const submitModel = { ...model };

        submitModel.compartment1 = parseInt(submitModel.compartment1.toString());
        submitModel.compartment2 = parseInt(submitModel.compartment2.toString());
        submitModel.compartment3 = parseInt(submitModel.compartment3.toString());
        submitModel.capacity = parseInt(submitModel.capacity.toString());
        submitModel.hauling_id = this.state.hauler.value;
        if (submitModel.hauling_id === ' ') submitModel.hauling_id = null;
        if (submitModel.barcode === '') submitModel.barcode = null;

        if (region === 'UDA') {
            submitModel.empty_weight = submitModel.empty_weight ? parseFloat(submitModel.empty_weight.toString()) : 0;
        }

        if (editData) {
            editTrailer(submitModel, editData.id)
                .then(() => {
                    showMessage({ message: 'Successfully Edited Trailer.' });
                    history.replace({ pathname: '/list-trailers' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        } else {
            addTrailer(submitModel)
                .then(() => {
                    showMessage({ message: 'Successfully Added Trailer.' });
                    history.replace({ pathname: '/list-trailers' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        }
    };

    onChangeCompartment = (event) => {
        if (event.target.name === 'compartment1') {
            this.setState({ compartment1: event.target.value });
        } else if (event.target.name === 'compartment2') {
            this.setState({ compartment2: event.target.value });
        } else {
            this.setState({ compartment3: event.target.value });
        }
    };

    render() {
        const { role } = getUser();
        const { canSubmit, compartment1, compartment2, compartment3, hauler } = this.state;
        const { editData, haulingCompanies, region } = this.props;

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="trailer_number"
                                label={`${localization.general.trailer} Number`}
                                value={editData ? editData.trailer_number : ''}
                                validations="minLength:1"
                                validationError={`This is not a valid ${localization.general.trailer} number`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                local_shipping
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <SelectSearchFormsy
                                className="my-16"
                                name="type"
                                label={`${localization.general.trailer} Type`}
                                variant="standard"
                                options={[
                                    { value: 'Tridem', name: 'Tridem' },
                                    { value: 'Tri Axel', name: 'Tri Axel' },
                                    { value: 'Quad', name: 'Quad' },
                                    { value: 'B-Train', name: 'B-Train' },
                                    { value: 'Super', name: 'Super' },
                                    { value: 'Regular', name: 'Regular' },
                                ]}
                                value={editData ? editData.type : ' '}
                                validations="minLength:2"
                                validationError="Please select a type"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="compartment1"
                                label="Front Compartment Capacity"
                                onChange={this.onChangeCompartment}
                                value={editData ? (editData.compartment1 === 0 ? '0' : editData.compartment1) : ''}
                                validations={{ isNumeric: true, isInt: true }}
                                validationError="This is not a valid number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                local_drink
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="compartment2"
                                label="Middle Compartment Capacity (please input 0 if not applicable)"
                                onChange={this.onChangeCompartment}
                                value={editData ? (editData.compartment2 === 0 ? '0' : editData.compartment2) : ''}
                                validations={{ isNumeric: true, isInt: true }}
                                validationError="This is not a valid number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                local_drink
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                required
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="compartment3"
                                label="Back Compartment Capacity (please input 0 if not applicable)"
                                onChange={this.onChangeCompartment}
                                value={editData ? (editData.compartment3 === 0 ? '0' : editData.compartment3) : ''}
                                validations={{ isNumeric: true, isInt: true }}
                                validationError="This is not a valid number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                local_drink
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                required
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="capacity"
                                label="Capacity"
                                value={(parseInt(compartment1.toString()) + parseInt(compartment2.toString()) + parseInt(compartment3.toString())).toString()}
                                validations={{ isNumeric: true, isInt: true, matchRegexp: /^(?!0+$)[0-9]{1,10}$/ }}
                                validationError="This is not a valid amount for trailer capacity"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                local_drink
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                disabled
                                required
                            />

                            {region === 'UDA' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="number"
                                    name="empty_weight"
                                    label="Empty Weight"
                                    value={editData ? editData.empty_weight : ''}
                                    validations={{ isNumeric: true }}
                                    validationError="This is not a valid number"
                                    variant="outlined" //
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="barcode"
                                label="Barcode Value"
                                placeholder="Barcode Value"
                                value={editData ? editData.barcode : ''}
                                validations={{ minLength: 10, isAlphanumeric: true }}
                                validationError="Unique barcodes must be at least 10 letters or numbers"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                list_alt
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            <SearchableSelectAll
                                className="my-16"
                                options={[{ value: ' ', name: 'None' }, ...haulingCompanies.map((entry) => ({ value: entry.value, name: entry.name }))]}
                                value={hauler}
                                onChange={this.handleHauler}
                                label={`${localization.general.hauling_singular}`}
                                disabled={!(role === 'admin' || role === 'sub-admin')} // Wrap
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="mx-auto my-16"
                                aria-label={editData ? 'EDIT TRAILER' : 'ADD TRAILER'}
                                disabled={!canSubmit} // Wrap
                            >
                                {`${editData ? 'Edit' : 'Add'} ${localization.general.trailer}`}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addTrailer: Actions.addTrailer,
            editTrailer: Actions.editTrailer,
            showMessage: Actions.showMessage,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateTrailerForm));
