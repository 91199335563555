import 'core-js/features/regexp';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'typeface-muli';
import './react-table-defaults';
import './styles/index.css';
import './intercom';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/App';
import * as FullStory from '@fullstory/browser';
import * as serviceWorker from './service-worker';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { getEnvironment, getRegion, getExpressAPIHost, setAPIHostnames, getAPIHost } from './utils';
import scheduleMeasurement from './util-files/observability-utils';

setAPIHostnames(window.location.href);

FullStory.init({ orgId: 'T3HTN' });

const ddService = getRegion(window.location.hostname).toLowerCase();
const environment = getEnvironment(window.location.hostname).toLowerCase();
const tracingOrigins = [getExpressAPIHost(), getAPIHost()];
const appVersion = process.env.REACT_APP_VERSION;

datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: ddService,
    env: environment,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['clickmap'],
    beforeSend: () => {
        if (window.location.hostname.includes('localhost')) {
            return false;
        }
    },
    allowedTracingUrls: tracingOrigins,
});

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: ddService,
    env: environment,
    version: appVersion,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    beforeSend: () => {
        if (window.location.hostname.includes('localhost')) {
            return false;
        }
    },
});

scheduleMeasurement(); // start observability measurement after DD Logs is initialized

const root = document.getElementById('root');

ReactDOM.render(<App />, root);

root.classList.add('fs-unmask');

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async (names) => {
                await Promise.all(names.map((name) => caches.delete(name)));
            });
        }
    });
}

serviceWorker.unregister();
