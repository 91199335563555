import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Divider, InputAdornment, TextField, IconButton } from '@mui/material';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import listOfStates from 'us-state-converter';
import moment, { isDate } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { TextFieldFormsy } from '@fuse';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import * as Actions from '../../store/actions';
import Region from '../../constants/region';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { formatUserErrorMessage, getRole } from '../../../utils';
import { PermissionSelector } from 'app/custom-widgets';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';

class CreateDriverForm extends Component {
    constructor(props) {
        super(props);

        const getDate = (date) => (date ? moment(date) : null);

        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            canSubmit: false,
            alert: null,
            warning: null,
            permissions: [],
            hireDate: getDate(this.props.editData?.hire_date),
            expiryDate: getDate(this.props.editData?.expiry_date),
            inspectionDate: getDate(this.props.editData?.inspection_date),
        };
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListUsers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-drivers' });
    };

    handlePermissionChange = (newPermissions) => {
        this.setState({ permissions: newPermissions });
    };

    handleDateChange = ({ field, date }) => {
        this.setState({ [field]: date });
    };

    onSubmit = (model) => {
        const { editData, editUser, showMessage, history, addDriver } = { ...this.state, ...this.props };
        const submitModel = { ...model };
        submitModel.permissions = this.state.permissions;

        if (this.state.hireDate) submitModel.hire_date = moment(this.state.hireDate).format();
        if (this.state.expiryDate) submitModel.expiry_date = moment(this.state.expiryDate).format();
        if (this.state.inspectionDate) submitModel.inspection_date = moment(this.state.inspectionDate).format();

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            editUser(submitModel, editData.id)
                .then((response) => {
                    showMessage({ message: 'Driver edited successfully.' });
                    this.setState({ alert: null });
                    this.navigateToListUsers();
                })
                .catch((err) => {
                    showMessage({ message: formatUserErrorMessage(err, 'Driver', 'edit') });
                });
        } else {
            addDriver(submitModel)
                .then((response) => {
                    showMessage({ message: 'Driver added successfully.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListUsers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'Unable to create a new Driver' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    render() {
        const { canSubmit } = this.state;
        const { haulingCompanies, editData, region } = this.props;
        const datePickerConfig = [
            { field: 'expiryDate', label: 'License Expiry Date' },
            { field: 'hireDate', label: 'Hire Date' },
            { field: 'inspectionDate', label: 'Last Inspection Date' },
        ];
        const states = listOfStates();
        const isAdmin = getRole() === 'admin';

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />

                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {this.state.alert && (
                                <Alert className="mt-32 mb-16" color="error">
                                    {this.state.alert}
                                </Alert>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                label="Name"
                                value={editData ? editData.name : ''}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                label="Username"
                                value={editData ? editData.username : ''}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationErrors={{ minLength: 'Min character length is 4' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    validations={{ minLength: 8, equalsField: 'password' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label="Email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                value={editData ? editData.email : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            <SelectSearchFormsy
                                className="my-16"
                                name="hauling_id"
                                label={`${localization.general.hauling_singular}`}
                                variant="standard"
                                options={[{ value: ' ', name: 'None' }, ...haulingCompanies.map((entry) => ({ value: entry.value, name: entry.name }))]}
                                value={editData ? editData.hauling_id : haulingCompanies.length === 1 ? haulingCompanies[0].value : ' '}
                                validations="minLength:2"
                                validationError={`Please select a ${localization.general.hauling_singular}`}
                                disabled={haulingCompanies.length === 1}
                                required
                            />

                            {![Region.NL, Region.PEI, Region.RF, Region.PRAIRIE].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="license_number"
                                    label={'License'}
                                    placeholder={'License Number'}
                                    value={editData ? editData.license_number : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    list_alt
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {[Region.PRAIRIE].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="driver_number"
                                    label={'Sampler License Number'}
                                    placeholder={'License Number'}
                                    value={editData ? editData.driver_number : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    list_alt
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {![Region.NL, Region.PEI, Region.RF].includes(region) && (
                                <>
                                    <SelectSearchFormsy className="my-16" name="state_of_license" label="State of License" variant="standard" options={[{ value: ' ', name: 'None' }, ...states.map((entry) => ({ value: entry.usps, name: entry.name }))]} value={editData ? editData.state_of_license : ' '} />
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        {datePickerConfig.map((config) => (
                                            <div className="w-full my-16">
                                                <DatePicker
                                                    label={config.label}
                                                    value={this.state[config.field]}
                                                    onChange={(newDate) => this.handleDateChange({ field: config.field, date: moment(newDate) })}
                                                    validations={isDate}
                                                    format="DD MMMM YYYY"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton sx={{ m: 0.55 }} onClick={() => this.handleDateChange({ field: config.field, date: null })} edge="end">
                                                                    <i className="text-20 material-icons" color="action">
                                                                        cancel
                                                                    </i>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                                    fullWidth
                                                />
                                            </div>
                                        ))}
                                    </MuiPickersUtilsProvider>
                                </>
                            )}

                            {isAdmin && (
                                <PermissionSelector
                                    permissionType="Driver"
                                    handlePermissionChange={this.handlePermissionChange}
                                    /* eslint no-unneeded-ternary:0 */
                                    selectedValues={editData?.permissions || this.permissions}
                                    showError={false}
                                />
                            )}

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT DRIVER' : 'ADD DRIVER'} disabled={!canSubmit}>
                                {editData ? 'Edit Driver' : 'Add Driver'}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addDriver: Actions.addDriver,
            editUser: Actions.editUser,
            showMessage: Actions.showMessage,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateDriverForm));
