import React, { useState } from 'react';
import moment from 'moment';
import { Box, Card, Typography, useTheme } from '@mui/material';
import getUsersName from '../../../../helpers/users/utils';
import SymbolDropoff from '../../symbols/SymbolDropoff';
import SymbolPickup from '../../symbols/SymbolPickup';

function TrailerEventDetails({ event }) {
    const theme = useTheme();
    const [showInfo, setShowInfo] = useState(false);

    const { type, created_by, geofence_id, created_at } = event;
    const fill = theme.palette.warning.main;
    const driverName = getUsersName(created_by);
    const geofenceName = getUsersName(geofence_id?.user_id);
    const isDropTrailer = type === 'dropTrailer';

    const handleMouseEnter = () => {
        setShowInfo(true);
    };

    const handleMouseLeave = () => {
        setShowInfo(false);
    };

    return (
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Box sx={{ zIndex: 1 }}>{isDropTrailer ? <SymbolDropoff circleFill={fill} /> : <SymbolPickup circleFill={fill} />}</Box>
            {showInfo && (
                <Card
                    sx={{
                        position: 'absolute',
                        left: 25,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        // wrap
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            margin: '8px',
                            width: '150px',
                            // wrap
                        }}
                    >
                        <Typography variant="subtitle2">{isDropTrailer ? 'Drop Trailer' : 'Enter Route Session'}</Typography>
                        {driverName && <Typography variant="p">{driverName}</Typography>}
                        {geofenceName && <Typography variant="p">{geofenceName}</Typography>}
                        <Typography variant="p">{moment(created_at).format('MMMM Do, YYYY - h:mm a')}</Typography>
                    </Box>
                </Card>
            )}
        </Box>
    );
}

export default TrailerEventDetails;
