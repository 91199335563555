import getLocalization from '../../../localization/config';

export const Dashboard = {
    label: 'View Dashboard',
    value: 'GetDashboardDetails',
};

export const ListAdmins = {
    label: 'Manage Admins (View, Create, Update, Delete)',
    value: 'ListAdmins',
};

export const ListProducers = {
    label: 'Manage Producers (View, Create, Update, Delete)',
    value: 'ListProducers',
};

export const ListProcessors = (region) => {
    const localization = getLocalization(region).general.processor_plural;
    return {
        label: `Manage ${localization} (View, Create, Update, Delete)`,
        value: 'ListProcessors',
    };
};

export const ListTrailers = {
    label: 'Manage Trailers (View, Create, Update, Delete)',
    value: 'ListTrailers',
};

export const ListTrucks = {
    label: 'Manage Trucks (View, Create, Update, Delete)',
    value: 'ListTrucks',
};

export const ListHaulingCompanies = (region) => {
    const localization = getLocalization(region).general.hauling_plural;
    return {
        label: `Manage ${localization} (View, Create, Update, Delete)`,
        value: 'ListHaulingCompanies',
    };
};

export const ListDrivers = {
    label: 'Manage Drivers (View, Create, Update, Delete)',
    value: 'ListDrivers',
};

export const ListRoutes = {
    label: 'Manage Routes (View, Create, Update, Delete)',
    value: 'ListRoutes',
};

export const ListMonthlyReports = (region, prefix = 'View', suffix = '') => {
    const label = region === 'RF' ? `${prefix} Monthly Payment Reports ${suffix}` : `${prefix} Monthly Reports ${suffix}`;
    return { label, value: 'ListMonthlyReports' };
};

export const ListScheduleRoutes = {
    label: 'Manage Routes (View, Create, Update, Delete)',
    value: 'ListScheduleRoutes',
};

export const ListRouteSessions = (region, prefix = 'Manage', suffix = '(View, Create, Update, Delete)') => {
    const localization = getLocalization(region).general.routeSession;
    return { label: `${prefix} ${localization}s ${suffix}`, value: 'ListRouteSessions' };
};

export const ListDropoffs = (prefix = 'Manage', suffix = '(View, Create, Update, Delete)') => {
    return { label: `${prefix} Dropoffs ${suffix}`, value: 'ListDropoffs' };
};

export const ListPickups = (prefix = 'Manage', suffix = '(View, Create, Update, Delete)') => {
    return { label: `${prefix} Pickups ${suffix}`, value: 'ListPickups' };
};

export const ListLabReports = (prefix = 'Manage', suffix = '(View, Create, Update, Delete)') => {
    return { label: `${prefix} Lab Reports ${suffix}`, value: 'ListLabReports' };
};

export const ListSubAdmins = {
    label: 'Manage Sub Users (View, Create, Update, Delete)',
    value: 'ListSubAdmins',
};

export const ListSilos = {
    label: 'Manage Silos (View, Create, Update, Delete)',
    value: 'ListSilos',
};

export const ListCIPWash = {
    label: 'Manage CIP Wash (View, Create, Update, Delete)',
    value: 'ListCIPWash',
};

export const ManageEquity = {
    label: 'Manage Equity Features (View, Create, Update, Delete)',
    value: 'ManageEquity',
};

export const ManagePoolingDecisions = {
    label: 'Manage Pooling Decisions',
    value: 'ManagePoolingDecisions',
};

export const ManageProcessorTotals = {
    label: 'Manage Processor Totals',
    value: 'ManageProcessorTotals',
};

export const ManageProducerTouchBases = {
    label: 'Manage Producer Touch Bases',
    value: 'ManageProducerTouchBases',
};

export const OverrideQualityStatus = {
    label: 'Manage Quality Status (View, Create, Update, Delete)',
    value: 'OverrideQualityStatus',
};

export const ListInvoicing = {
    label: 'Manage Invoicing (View, Create, Update, Delete)',
    value: 'ListInvoicing',
};

export const ListBaseTransfers = (region) => {
    const localization = getLocalization(region).navigation['Base Transfers'];
    return {
        label: `Manage ${localization} (View, Create, Update, Delete)`,
        value: 'ListBaseTransfers',
    };
};

export const ListPickupsForProcessors = {
    label: 'View Pickups for Processors',
    value: 'ViewPickupsForProcessor',
};

export const ListBactoAlert = (region) => {
    const localization = getLocalization(region)?.navigation['Penalty Status'] ?? 'Penalty Status';
    return {
        label: `View ${localization}`,
        value: 'ListBactoAlert',
    };
};

export const ListManifests = {
    label: 'Manage Manifest View (View, Create, Update, Delete)',
    value: 'ListManifests',
};

export const ListUserGroups = {
    label: 'Manage User Groups (View, Create, Update, Delete)',
    value: 'ListUserGroups',
};

export const GetMilkScheduleDetails = (prefix = 'Manage', suffix = '(View, Create, Update, Delete)') => {
    return { label: `${prefix} Milk Schedule ${suffix}`, value: 'GetMilkScheduleDetails' };
};

export const GetFluidScheduleDetails = {
    label: 'Manage Processed Milk Schedule (View, Create, Update, Delete)',
    value: 'GetFluidScheduleDetails',
};

export const ListSharedFiles = {
    label: 'Manage Shared Files (View, Create, Update, Delete)',
    value: 'ListSharedFiles',
};

export const ManageClosePeriod = {
    label: 'Manage Close Period (View, Open, Close)',
    value: 'ManageClosePeriod',
};

export const ManageCloseDay = {
    label: 'Manage Close Day (View, Open, Close)',
    value: 'ManageCloseDay',
};

export const ManageCloseMonth = {
    label: 'Manage Close Month (View, Open, Close)',
    value: 'ManageCloseMonth',
};

export const ViewDriverLocations = {
    label: 'View Driver Locations',
    value: 'ViewDriverLocations',
};

export const ViewProcessedFluidsDriverLocations = {
    label: 'View Processed Fluids Driver Locations',
    value: 'ViewProcessedFluidsDriverLocations',
};

export const UploadDownload = {
    label: 'View Upload / Download',
    value: 'UploadDownload',
};

export const ManageRapidTest = {
    label: 'Manage Rapid Test',
    value: 'ManageRapidTest',
};

export const EditSiloInvoices = {
    label: 'Edit Silo Invoices',
    value: 'EditSiloInvoices',
};

export const QARapidTest = {
    label: 'QA Rapid Test',
    value: 'QARapidTest',
};

export const ViewAccountingReports = {
    label: 'View Accounting Reports',
    value: 'ViewAccountingReports',
};

export const ManageProducerAssignments = {
    label: 'Manage Producer Assignments',
    value: 'ManageProducerAssignments',
};

export const ManageMilkPrices = {
    label: 'Manage Milk Prices',
    value: 'ManageMilkPrices',
};

export const ManageProducerPayReports = {
    label: 'Manage Producer Pay Reports',
    value: 'ManageProducerPayReports',
};

export const ManageAssignees = {
    label: 'Manage Assignees',
    value: 'ManageAssignees',
};

export const ManageDeferrals = {
    label: 'Manage Deferrals',
    value: 'ManageDeferrals',
};

export const ManageMerchandise = (region) => {
    const localization = getLocalization(region).general.merchandise ?? 'Merchandise';
    return {
        label: `Manage ${localization}`,
        value: 'ManageMerchandise',
    };
};

export const ManageHaulerRates = {
    label: 'Manage Hauler Rates',
    value: 'ManageHaulerRates',
};

export const ManageSplitProducers = {
    label: 'Manage Split Producers',
    value: 'ManageSplitProducers',
};

export const ManageHaulerAssignments = {
    label: 'Manage Hauler Assignments',
    value: 'ManageHaulerAssignments',
};

export const ManageHaulingCharges = (prefix = 'Manage') => {
    return { label: `${prefix} Hauling Charges`, value: 'ManageHaulingCharges' };
};

export const ManageHaulingContracts = (prefix = 'Manage') => {
    return { label: `${prefix} Hauling Contracts`, value: 'ManageHaulingContracts' };
};

export const ManageHaulerPayReports = {
    label: 'Manage Hauler Pay Reports',
    value: 'ManageHaulerPayReports',
};

export const ManageVendorPayReports = {
    label: 'Manage Vendor Pay Reports',
    value: 'ManageVendorPayReports',
};

export const ManagePaymentStatus = {
    label: 'Manage Payment Status',
    value: 'ManagePaymentStatus',
};

export const ManageAssignments = {
    label: 'Manage Assignments',
    value: 'ManageAssignments',
};

export const ManagePooling = {
    label: 'Manage Pooling',
    value: 'ManagePooling',
};

export const ManageHaulingRates = {
    label: 'Manage Hauling Rates',
    value: 'ManageHaulingRates',
};

export const ListPickupsAndLabs = {
    label: 'View Pickups And Labs',
    value: 'ListPickupsAndLabs',
};

export const ListSubProducers = {
    label: 'View Sub Producers',
    value: 'ListSubProducers',
};

export const ListSubProcessors = (region) => {
    const localization = getLocalization(region).general.processor_plural;
    return {
        label: `Manage Sub ${localization} (View, Create, Update, Delete)`,
        value: 'ListSubProcessors',
    };
};

export const ListReceivers = {
    label: 'Manage Receivers (View, Create, Update, Delete)',
    value: 'ListReceivers',
};

export const ListSubHauling = (region) => {
    const localization = getLocalization(region).general.hauling_plural;
    return {
        label: `Manage ${localization} (View, Create, Update, Delete)`,
        value: 'ListSubHauling',
    };
};

export const ViewProducerHaulingCharges = {
    label: 'View Producer Hauling Charges',
    value: 'ViewProducerHaulingCharges',
};

export const ViewHaulerPayReport = {
    label: 'View Hauler Pay Report',
    value: 'ViewHaulerPayReport',
};

export const ViewPickupsForProcessor = {
    label: 'View Pickups for Processors',
    value: 'ViewPickupsForProcessor',
};

export const ManageReports = {
    label: 'Manage Reports',
    value: 'ManageReports',
};

export const ManageCreamLoads = {
    label: 'Manage Cream Loads',
    value: 'ManageCreamLoads',
};

export const ProducerHaulingContractsAndCharges = {
    label: 'Producer Hauling Contracts / Charges',
    value: 'ViewProducerHaulingCharges',
};

export const ManageProcessedFluidsPickups = {
    label: 'Manage Processed Fluids Pickups',
    value: 'ManageProcessedFluidPickups',
};

export const ManageProcessedFluidsDropoffs = {
    label: 'Manage Processed Fluids Dropoffs',
    value: 'ManageProcessedFluidDropoffs',
};

export const ManageProcessedFluidsRouteSessions = {
    label: 'Manage Processed Fluids RouteSessions',
    value: 'ManageProcessedFluidRouteSessions',
};

export const ViewReceivingDropoffs = {
    label: 'View Receiving Dropoffs',
    value: 'ViewReceivingDropoffs',
};

export const ManageDeliveryReports = {
    label: 'Manage Delivery Reports (Create, Send, Delete)',
    value: 'ManageDeliveryReports',
};

export const ViewDocumentation = {
    label: 'View Table Documentation',
    value: 'ViewDocumentation',
};

export const ViewMemberCenter = {
    label: 'View Member Center',
    value: 'ViewMemberCenter',
    split: 'can_use_member_center',
};

export const AccessPlantManagement = {
    label: 'Access Plant Management Module',
    value: 'AccessPlantManagement',
};

export const AccessHaulerManagement = {
    label: 'Access Hauler Management Module',
    value: 'AccessHaulerManagement',
};

export const ManageFuelTracking = {
    label: 'Manage Fuel Tracking',
    value: 'ManageFuelTracking',
};

export const AccessProducerManagement = {
    label: 'Access Producer Management Module',
    value: 'AccessProducerManagement',
};

export const UploadDemurrageDocuments = {
    label: 'Upload Demurrage Documents',
    value: 'UploadDemurrageDocuments',
};
