import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel/index';
import { Switch } from '@material-ui/core';
import { generateTestId } from '../../../utils';

const SwitchLabel = ({ mainLabel, secondaryLabel }) => {
    return (
        <div>
            <div style={{ fontSize: '14px' }}>{mainLabel}</div>
            <div style={{ fontSize: '10px' }}>{secondaryLabel}</div>
        </div>
    );
};

const SwitchWithLabel = ({ name, mainLabel, secondaryLabel, defaultValue = true, shouldUnregister }) => {
    const methods = useFormContext();
    return (
        <Controller
            name={name}
            control={methods.control}
            defaultValue={defaultValue}
            shouldUnregister={shouldUnregister}
            render={({ field: { onChange, value } }) => {
                return <FormControlLabel control={<Switch checked={value} onChange={onChange} data-testid={generateTestId(name, 'switch')} />} label={<SwitchLabel mainLabel={mainLabel} secondaryLabel={secondaryLabel} />} />;
            }}
        />
    );
};

export default SwitchWithLabel;
