import { useRef } from 'react';
import { buildExpressQuery } from './repository/queries/builders';
import axios from 'axios';

const getCancelToken = (callbackCancel) => {
    return new axios.CancelToken((cancel) => {
        callbackCancel(() => {
            cancel('Request canceled');
        });
    });
};

/**
 * Curry function for building express axios requests
 * @param {{endpoint: string, params: any, options: import('axios').AxiosRequestConfig}} config
 */
export const useExpressQuery = ({ endpoint, params, options = {} }) => {
    const cancelRequest = useRef(() => null);

    const request = async () => {
        const query = buildExpressQuery(endpoint, { params });
        const response = await axios.get(query, options);

        return response.data;
    };

    return request;
};

/**
 * Executes axios request to express
 * @param {{ endpoint: string, params: any,  options: import('axios').AxiosRequestConfig}} config
 */
export const expressQuery = async ({ endpoint, params, options = {} }) => {
    const query = buildExpressQuery(endpoint, { params });
    const response = await axios.get(query, options);

    return response.data;
};

/**
 * Executes axios mutation request to express
 * @param {{method: import('axios').AxiosRequestConfig["method"], endpoint: string, params: any, body: any, options: import('axios').AxiosRequestConfig}} config
 */
export const expressMutation = async ({ method = 'POST', endpoint, params, body, options = {} }) => {
    const request = buildExpressQuery(endpoint, { params, body });
    let { url, body: data } = request;
    if (!body) {
        url = request;
        data = undefined;
    }
    const response = await axios({
        method,
        url,
        data,
        ...options,
    });

    return response.data;
};

export default useExpressQuery;
