import { FuseLoadable } from '@fuse';

const LoanManagement = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/loan-management',
            component: FuseLoadable({
                loader: () => {
                    return import('./LoanManagement');
                },
            }),
        },
    ],
};

const Loans = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/loan-management/loans/create-loan',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/MutateLoan');
                },
            }),
        },
        {
            path: '/loan-management/loans/edit-loan/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/MutateLoan');
                },
            }),
        },
        {
            path: '/loan-management/loans/view-loan/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/ViewLoan');
                },
            }),
        },
        {
            path: '/loan-management/loans/view-original-amortization/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/ViewOriginalAmortization');
                },
            }),
        },
    ],
};

const ExtraPayments = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/loan-management/extra-payments/create-extra-payment',
            component: FuseLoadable({
                loader: () => {
                    return import('./extra-payments/MutateExtraPayment');
                },
            }),
        },
        {
            path: '/loan-management/extra-payments/edit-extra-payment/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./extra-payments/MutateExtraPayment');
                },
            }),
        },
    ],
};

export default [Loans, ExtraPayments, LoanManagement];
