import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { TextFieldFormsy } from '@fuse';
import * as Actions from '../../store/actions';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { Alert } from '@mui/material';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';

class CreateReceiverForm extends Component {
    state = { canSubmit: false, alert: null, warning: null };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListReceivers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-receivers' });
    };

    onSubmit = (model) => {
        const { editData, editUser, showMessage, history, addReceiver } = { ...this.state, ...this.props };
        const submitModel = { ...model };
        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            editUser(submitModel, editData.id)
                .then(() => {
                    showMessage({ message: 'Receiver edited successfully.' });
                    this.setState({ alert: null });
                    this.navigateToListReceivers();
                })
                .catch((err) => {
                    showMessage({ message: 'An error occurred editing receiver details.' });
                });
        } else {
            addReceiver(submitModel)
                .then((response) => {
                    showMessage({ message: 'Receiver added successfully.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListReceivers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'An error occurred adding a new receiver.' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    render() {
        const { canSubmit } = this.state;
        const { processors, editData } = this.props;

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />
                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {this.state.alert && (
                                <Alert className="mt-32 mb-16" color="error">
                                    {this.state.alert}
                                </Alert>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                label="Name"
                                value={editData ? editData.name : ''}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                label="Username"
                                value={editData ? editData.username : ''}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationErrors={{ minLength: 'Min character length is 4' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    validations={{ minLength: 8, equalsField: 'password' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label="Email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                value={editData ? editData.email : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />

                            <SelectSearchFormsy
                                className="my-16"
                                name="processor_id"
                                label={`${localization.general?.processor_singular ?? 'Processor'}`}
                                variant="standard"
                                options={[{ value: ' ', name: 'None' }, ...processors.map((entry) => ({ value: entry.value, name: entry.name }))]}
                                value={editData ? editData.processor_id : processors.length === 1 ? processors[0].value : ' '}
                                validations="minLength:2"
                                validationError={`Please select a ${localization.general?.processor_singular ?? 'Processor'}`}
                                disabled={processors.length === 1}
                                required
                            />

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT RECEIVER' : 'ADD RECEIVER'} disabled={!canSubmit}>
                                {editData ? 'Edit Receiver' : 'Add Receiver'}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addReceiver: Actions.addReceiver,
            editUser: Actions.editUser,
            showMessage: Actions.showMessage,
        },
        dispatch
    );
}

export default withRouter(connect(null, mapDispatchToProps)(CreateReceiverForm));
