import React from 'react';
import { Icon, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

class MonthPicker extends React.Component {
    state = { months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] };

    componentDidMount() {
        const { match, onChange } = this.props;
        if (match && match.params && match.params.date) {
            const dateWanted = match.params.date;
            onChange([dateWanted.substring(0, 2), dateWanted.substring(2)]);
        }
    }

    prevMonth = ({ month, year, onChange }) => {
        onChange(month > 1 ? [parseInt(month) - 1, year] : [12, parseInt(year) - 1]);
    };

    nextMonth = ({ month, year, onChange }) => {
        onChange(month < 12 ? [parseInt(month) + 1, year] : [1, parseInt(year) + 1]);
    };

    render() {
        const { months } = this.state;
        const { month, year } = this.props;

        return (
            <div className="pt-16 pb-16 inline-flex" style={{ width: '60%' }}>
                <Icon fontSize="large" className="float-left" style={{ minWidth: '10%' }} onClick={() => this.prevMonth(this.props)}>
                    arrow_back_ios
                </Icon>
                <div className="flex flex-col items-center justify-center" style={{ minWidth: '80%' }}>
                    <Typography variant="h6" color="inherit">
                        {months[month - 1]}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        {year}
                    </Typography>
                </div>
                <Icon fontSize="large" className="float-right" style={{ minWidth: '10%' }} onClick={() => this.nextMonth(this.props)}>
                    arrow_forward_ios
                </Icon>
            </div>
        );
    }
}

export default withRouter(MonthPicker);
