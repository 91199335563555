import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import * as Actions from '../../store/actions';
import deleteRecord from '../../repository/deleteRecord';
import { getAPIHost, getExpressAPIHost, resolveId } from '../../../utils';
import { LoadingDialog } from '.';
import Split from '../../split';

function PaperComponent(props) {
    return <Paper {...props} />;
}

class DeleteDialog extends React.Component {
    state = { open: false, loading: false };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDelete = (data, dataType, variables, { showMessage, forceFetch, getSingleInstance, editRouteSession }, event) => {
        if (event) {
            event.stopPropagation();
        }
        this.setState({ loading: true, open: false });
        const [endpoint, key, useExpress] = variables;

        if (dataType === 'HaulerRate') {
            axios
                .delete(`${getExpressAPIHost() + endpoint}/${key}`)
                .then(() => {
                    showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                    forceFetch();
                })
                .catch((err) => {
                    if (err.response && err.response.data && err.response.data.toString().includes('UserReadableError:')) {
                        showMessage({ message: `${err.response.data.toString().replace(/UserReadableError:/, '')}` });
                    } else {
                        showMessage({ message: 'There was a problem during deletion. Please contact support.' });
                    }
                });
        } else if (dataType === 'Shared File') {
            const item = variables[1];
            const { generatedName, sentToIds } = item;
            axios
                .patch(`${getAPIHost() + endpoint}`, { generated_name: generatedName, sentTo_ids: sentToIds })
                .then(() => {
                    showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                    forceFetch();
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    showMessage({ message: 'There was a problem during deletion. Please contact support.' });
                });
        } else if (['Route Session', 'Hauling Route Session'].includes(dataType)) {
            if (!data.volume && !parseFloat(data.dropoffVolume)) {
                axios
                    .delete(`${getExpressAPIHost() + endpoint}/${key}`)
                    .then(() => {
                        showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                        forceFetch();
                    })
                    .catch((err) => {
                        showMessage({ message: 'There was a problem during deletion. Please contact support.' });
                    });
            } else {
                showMessage({ message: 'A Route Session can only be deleted if it has no pickups or dropoffs.' });
            }
        } else if (dataType === 'Seal') {
            getSingleInstance('route-sessions', data.route_session_id).then((value) => {
                const routeSessionSeals = value.payload.routeSession[0].seals;
                const sealToUpdate = _.find(routeSessionSeals, { barcode: data.barcode });
                const sealToNotUpdate = routeSessionSeals.filter((seal) => seal.barcode !== data.barcode);
                sealToUpdate.deleted_at = new Date();
                const submitModel = { seals: _.sortBy([...sealToNotUpdate, sealToUpdate], 'created_at') };
                editRouteSession(submitModel, data.route_session_id)
                    .then(() => {
                        showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                        forceFetch();
                    })
                    .catch((err) => {
                        showMessage({ message: 'There was a problem during deletion. Please contact support.' });
                    });
            });
        } else {
            deleteRecord(endpoint, key, useExpress)
                .then(() => {
                    showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                    forceFetch();
                })
                .catch((err) => {
                    showMessage({ message: 'There was a problem during deletion. Please contact support.' });
                });
        }
        this.setState({ loading: false });
    };

    /**
     *
     * @param {*} data row data from type
     * @param {*} dataType type of record
     * @returns {[]} first element is endpoint. second element is typically record id.
     * third element is boolean to indicate if express server is used
     */
    getVariables = (data, dataType) => {
        switch (dataType) {
            case 'Admin':
            case 'Driver':
            case 'Receiver':
            case 'Hauling Company':
            case 'Lab Provider':
            case 'Producer':
            case 'Sub Producer':
                return ['/users', data.id, false];
            case 'Producer Business':
                // eslint-disable-next-line no-underscore-dangle
                return ['/users', data._id, false];
            case 'Processor':
                return ['/users', data.id, true];
            case 'Pickup':
                return ['/pickups', data.id, Split.getBooleanTreatment('use_express_pickup_endpoint')];
            case 'Dropoff':
                return ['/dropoffs', data.id, Split.getBooleanTreatment('use_express_dropoff_endpoint')];
            case 'Lab Report':
                return ['/lab-reports', data.id, Split.getBooleanTreatment('use_express_lab_endpoint')];
            case 'Dropoff Sample':
                // eslint-disable-next-line no-underscore-dangle
                return ['/dropoff-samples', data._id, true];
            case 'Monthly Report':
                return ['/producer-monthly-reports', data.id, false];
            case 'Route':
                return ['/routes', data.id, true];
            case 'Route Session':
                return ['/route-sessions', data.id, Split.getBooleanTreatment('use_express_route_session_endpoint')];
            case 'Processed Fluid Route Session':
                return ['/processed-fluid/route-sessions', data.id, true];
            case 'Hauling Route Session':
                return ['/route-sessions', data.id, Split.getBooleanTreatment('use_express_route_session_endpoint')];
            case 'Trailer':
                return ['/trailers', data.id, false];
            case 'Truck':
                // eslint-disable-next-line no-underscore-dangle
                return ['/trucks', data._id, false];
            case 'Shared File':
                return ['/shared-files/remove-users', data, false];
            case 'Shared Folder':
                return ['/shared-folder', data.id, false];
            case 'Comment':
                return ['/comments', [data], false];
            case 'Silo':
                return ['/silos', data.id, false];
            case 'Assignee':
                // eslint-disable-next-line no-underscore-dangle
                return ['/assignees', data._id, true];
            case 'Assignment':
                // eslint-disable-next-line no-underscore-dangle
                return ['/assignments', data._id, true];
            case 'Assignment Configuration':
                // eslint-disable-next-line no-underscore-dangle
                return ['/assignment-configuration', data._id, true];
            case 'Merchandise Product':
                // eslint-disable-next-line no-underscore-dangle
                return ['/merchandise', data._id, true];
            case 'Merchandise Purchase':
                // eslint-disable-next-line no-underscore-dangle
                return ['/merchandise-purchase', data._id, true];
            case 'Deferral':
                // eslint-disable-next-line no-underscore-dangle
                return ['/deferrals', data._id, true];
            case 'Wash':
                // eslint-disable-next-line no-underscore-dangle
                return ['/washes', data._id, true];
            case 'WashFacility':
                // eslint-disable-next-line no-underscore-dangle
                return ['/wash-facilities', data._id, true];
            case 'Base Transfer':
                // eslint-disable-next-line no-underscore-dangle
                return ['/base-transfers', data._id, true];
            case 'Seal':
                // eslint-disable-next-line no-underscore-dangle
                return ['/route-sessions', data.id, true];
            case 'multi-site user link':
                // eslint-disable-next-line no-underscore-dangle
                return ['/link-user', data._id, true];
            case 'Split Producer':
                // eslint-disable-next-line no-underscore-dangle
                return ['/split-producer-agreement', data._id, true];
            case 'Hauling Contract':
                // eslint-disable-next-line no-underscore-dangle
                return ['/hauling-contract', data._id, true];
            case 'Hauler Assignment':
                // eslint-disable-next-line no-underscore-dangle
                return ['/hauler-assignment', data._id, true];
            case 'SampleBarcodeBatch':
                // eslint-disable-next-line no-underscore-dangle
                return ['/sample-barcode-batches', data._id, true];
            case 'MiscSample':
                // eslint-disable-next-line no-underscore-dangle
                return ['/misc-samples', data._id, true];
            case 'Barn Score':
                // eslint-disable-next-line no-underscore-dangle
                return ['/barn-scores', data._id, true];
            case 'HaulerRate':
                return ['/hauler-rate', data._id, false];
            case 'In Progress Dropoff':
                return ['/in-progress/dropoffs', data._id, true];
            default:
                return [];
        }
    };

    getDialogContentText = (datatype) => {
        switch (datatype) {
            case 'Route':
                return `Delete ${datatype}? All Schedule loads with this Route Number will also be removed.`;
            case 'Shared Folder':
                return `Delete ${datatype} and ALL Contents Within?`;
            default:
                return `Delete ${datatype}?`;
        }
    };

    disableCheck = () => {
        const { dataType, data, disable = false } = this.props;
        if (['Route Session', 'Hauling Route Session'].includes(dataType) && data.volume !== 0) {
            return true;
        }

        if (['Processed Fluid Route Session'].includes(dataType) && (data.dropoff_id !== null || data.pickup_id !== null)) {
            return true;
        }
        if (disable) {
            return true;
        }
    };

    render() {
        const { title, dataType, data, icon, disable = false } = this.props;
        const { open, loading } = this.state;

        return (
            <div>
                {!icon && (
                    <Button variant="contained" color={'error'} sx={{ my: '16px' }} aria-label={title} onClick={this.handleClickOpen} disabled={this.disableCheck()}>
                        {title}
                    </Button>
                )}
                {icon && (
                    <IconButton aria-label={title} onClick={this.handleClickOpen} disabled={((dataType === 'Route Session' || dataType === 'Hauling Route Session') && data.volume !== 0) || disable}>
                        <CloseIcon style={{ fill: 'darkred' }} />
                    </IconButton>
                )}
                <LoadingDialog showDialog={loading} />
                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Delete</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-black">{this.getDialogContentText(dataType)}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(e) => this.handleDelete(data, dataType, this.getVariables(resolveId(data), dataType), { ...this.props, ...this.state }, e)} variant="contained" color="primary">
                            Delete
                        </Button>
                        <Button onClick={this.handleClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: Actions.showMessage, editRouteSession: Actions.editRouteSession, getSingleInstance: Actions.getSingleInstance }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);
