import React from 'react';
import { Button } from '@material-ui/core';
import { Grid } from '@mui/material';

export const FORM_CONTROLLER_ACTIONS_SUBMIT_ID = 'form-controller-actions-submit-button';

/**
 * @typedef HandleCancelFunction
 * @description Called with success if submit is successful, return without doing anything, refreshAnyway to do logic
 *  (eg do something if there is a stageError)
 * @function
 * @param {*} success - Item signifying operation was successful (eg. form submitted successfully)
 * @param {*} cancel - Return without doing anything.
 * @param {boolean} refreshAnyway - Signals that the parent Table or Page should refresh even if it is a cancel and not a success.
 */

/**
 * @typedef AdditionalFormElementsObject
 * @property {React.ReactElement} start - Item to be rendered before the 'Cancel' button.
 * @property {React.ReactElement} middle - Item to be rendered in between 'Cancel' and 'Submit' buttons.
 * @property {React.ReactElement} end - Item to be rendered after the 'Submit' button.
 */

/**
 * Form actions component for react hook forms.
 *
 * @param {Object} props
 * @param {HandleCancelFunction} props.handleCancel function for closing the modal.
 * @param {string} [props.submitText] - Text for the submit button. Defaults to 'Submit'
 * @param {string} [props.cancelText] - Text to be displayed on the cancel button
 * @param {boolean} props.disabled whether form is disabled
 * @param {AdditionalFormElementsObject} props.Additional - Items (Chips, other buttons, ect.) to be injected into button row.
 * @returns {React.ReactElement}
 */
function FormControllerActions({ handleCancel, submitText, cancelText, disabled, AdditionalFormActions = {}, hideCancel, hideSubmit }) {
    return (
        <Grid item container spacing={2} justifyContent="flex-end">
            {AdditionalFormActions.start && (
                <Grid item xs="auto">
                    {AdditionalFormActions.start}
                </Grid>
            )}

            <Grid item xs="auto">
                {!hideCancel && handleCancel && (
                    <Button onClick={handleCancel} variant="outlined">
                        {cancelText ?? 'Cancel'}
                    </Button>
                )}
            </Grid>

            {AdditionalFormActions.middle && (
                <Grid item xs="auto">
                    {AdditionalFormActions.middle}
                </Grid>
            )}

            <Grid item xs="auto">
                {!hideSubmit && (
                    <Button
                        id={FORM_CONTROLLER_ACTIONS_SUBMIT_ID}
                        disabled={disabled}
                        color="secondary"
                        variant="contained" //
                        type="submit"
                    >
                        {submitText ?? 'Submit'}
                    </Button>
                )}
            </Grid>

            {AdditionalFormActions.end ? (
                <Grid item xs="auto">
                    {AdditionalFormActions.end}
                </Grid>
            ) : null}
        </Grid>
    );
}

export default FormControllerActions;
