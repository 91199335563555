import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles/index';
import { TextFieldFormsy } from '@fuse';
import * as Actions from '../../store/actions';

const styles = (theme) => ({ root: { display: 'flex' }, formControl: { margin: theme.spacing(3) } });

class AddFolderForm extends Component {
    state = { canSubmit: false };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        const { addFolder, showMessage, history, folderId } = { ...this.state, ...this.props };

        // eslint-disable-next-line no-param-reassign
        model = { ...model, ...(folderId && { folder_id: folderId }) };

        addFolder(model)
            .then(() => {
                showMessage({ message: 'Folder added successfully.' });
                history.goBack();
            })
            .catch((err) => {
                showMessage({ message: err.message });
            });
    };

    render() {
        const { canSubmit } = this.state;

        return (
            <div className="min-w-3/4 max-w-3/4">
                <Divider />

                <Formsy
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-col justify-center"
                >
                    <TextFieldFormsy
                        className="my-16 pb-16"
                        type="text"
                        name="name"
                        label="Folder Name"
                        validations={{ minLength: 2 }}
                        validationErrors={{ minLength: 'Min character length is 2' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        person
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                    />

                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label="ADD FOLDER" disabled={!canSubmit}>
                        Add Folder
                    </Button>
                </Formsy>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ addFolder: Actions.addFolder, showMessage: Actions.showMessage }, dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(AddFolderForm)));
