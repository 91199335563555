/**
 * This file is deprecated and no longer used
 * See LabReportFrom.jsx for the new implementation
 */

import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment, Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Actions from '../../store/actions';
import { CheckboxFormsy, SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { getLabreportFromBarcode } from '../../repository';
import { getPickupsQuery, getRouteSessionsQuery } from '../../repository/queries';
import getThirtyDayAverages from '../../repository/getThirtyDayAverages';
import { arrayToMap, barcodeLastFourDigits, boundedRange, getLabAlreadyExistsMsg, getUserRegion, isLabAlreadyExistsMsg } from '../../../utils';
import LoadingDialog from '../../custom-widgets/dialogs/LoadingDialog';
import { ConfirmationDialog, RestrictedDatePicker } from '../../custom-widgets';
import { getBulkQueryData } from '../../repository/utils';
import store from '../../store';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import { LocalizationConsumer } from '../../localization/LocalizationContext';

class EditLabReportForm extends Component {
    state = {
        canSubmit: false,
        selectedDate: moment().toDate(),
        sccTestDate: null,
        spcTestDate: null,
        waterTestDate: null,
        discarded: null,
        checked: false,
        qualityChecked: false,
        componentChecked: false,
        officialChecked: false,
        foundLabReport: 'notChecked',
        producer: '',
        pickups: [],
        selectedPickup: null,
        barcode: '0',
        tank: '',
        tanks: 1,
        lab_pickup_amount: null,
        fat: 0,
        fatWeight: 0,
        protein: 0,
        proteinWeight: 0,
        lactose: 0,
        scc: 0,
        shouldOverrideDemeritCalculation: false,
        labAverages: null,
        manual_adjustment: false,
        disabled: false,
        numBarcodes: null,
        sampleBarcodes: null,
        four_dat_average: null,
        bol: null,
        loading: false,
        otherSolids: '0',
        solidsNotFat: '0',
        total_protein_percentage: 0,
    };

    componentDidMount() {
        const { editData, producers, region } = this.props;
        const usingDateRange = store.getState().persisted.dateDetails.usingCustomDateRange;
        const producerMap = arrayToMap(producers, 'id');
        if (usingDateRange) {
            const dateRangeEnd = store.getState().persisted.dateDetails.customDateRangeEnd;
            this.setState({ selectedDate: moment(dateRangeEnd).startOf('day').add(12, 'hours') });
        }

        if (editData) {
            this.setState({
                selectedDate: moment(editData.date_tested),
                sccTestDate: editData?.somatic_cell_count_date || null,
                spcTestDate: editData?.standard_plate_count_date || null,
                waterTestDate: editData?.water_test_date || null,
                discarded: editData.is_outlier,
                qualityChecked: editData.is_outlier,
                frequencyChecked: editData.test_frequency === 'daily',
                componentChecked: editData.is_component_outlier,
                officialChecked: editData.is_official,
                bol: editData.BOL,
                // tank: editData.tank,
                tanks: _.find(producers, { label: editData?.producer })?.tanks,
                producer: producerMap[editData?.producer_id],
                barcode: editData?.sample_barcode,
                lab_pickup_amount: ['CDI', 'CACIQUE'].includes(region) ? editData.lab_pickup_amount : null,
                fat: editData.fat,
                is_outlier: editData.is_outlier,
                protein: editData.protein,
                scc: editData.somatic_cell_count,
                lactose: editData.lactose,
                manual_adjustment: editData.manual_adjustment,
                sampleBarcodes: editData?.sample_barcodes || null,
                numBarcodes: editData.sample_barcodes?.length || null,
                four_day_average: editData?.four_day_average || null,
                otherSolids: editData?.other_solids?.toFixed(2).toString(),
                solidsNotFat: editData?.solids_not_fat?.toFixed(2).toString(),
                tank: { value: editData?.tank?.toString(), name: editData?.tank?.toString() },
                total_protein_percentage: editData.total_protein_percentage,
            });
            this.getPickups(moment(editData.date_tested));
        } else {
            this.getPickups();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { producer, selectedDate, selectedPickup, fat, protein } = this.state;
        if (producer && producer !== prevState.producer) {
            this.getPickups();
        }

        if (selectedDate !== prevState.selectedDate) {
            this.getPickups();
        }

        if (selectedPickup !== prevState.selectedPickup && !!selectedPickup) {
            this.setState({
                tank: { value: selectedPickup?.tank_number, name: selectedPickup?.tank_number },
                barcode: selectedPickup.component_barcode,
                fatWeight: (fat * parseFloat(selectedPickup?.volume || 0)) / 100,
                proteinWeight: (protein * parseFloat(selectedPickup?.volume || 0)) / 100,
                sampleBarcodes: selectedPickup.sample_barcodes,
            });
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        const {
            selectedPickup,
            editLabReport,
            addLabReport,
            showMessage,
            history,
            selectedDate,
            sccTestDate,
            spcTestDate,
            waterTestDate,
            numBarcodes,
            editData,
            addComment,
            barcode,
            discarded,
            componentChecked,
            officialChecked,
            qualityChecked,
            frequencyChecked,
            foundLabReport,
            region,
            is_outlier,
            producer,
            tank,
            //
        } = {
            ...this.state,
            ...this.props,
        };
        const submitModel = { ...model };
        submitModel.pickup_id = selectedPickup?.id;
        submitModel.producer_id = producer?.id || null;

        submitModel.date = selectedDate ? moment(selectedDate).format() : moment().format();
        submitModel.tank = parseInt(tank?.value) || '';
        submitModel.fat = parseFloat(submitModel?.fat?.toString()) || undefined;
        submitModel.protein = parseFloat(submitModel?.protein?.toString()) || undefined;

        if (['NL', 'PEI', 'UDA'].includes(region)) {
            submitModel.somatic_cell_count = parseFloat(submitModel.somatic_cell_count.toString());
        }
        if (['UDA'].includes(region)) {
            submitModel.lactose = parseFloat(submitModel?.lactose?.toString());
        }
        if (['NL', 'PEI', 'RF'].includes(region)) {
            submitModel.temperature = parseFloat(submitModel.temperature.toString());
        }
        if (region === 'NL') {
            submitModel.bacteria_PLC = parseFloat(submitModel.bacteria_PLC.toString());
            submitModel.LOS = parseFloat(submitModel.LOS.toString());
            submitModel.bacto_scan = parseFloat(submitModel.bacto_scan.toString());
        }
        if (region === 'PEI') {
            submitModel.is_outlier = !!discarded;
            submitModel.coliform_count = parseFloat(submitModel.coliform_count.toString());
            submitModel.lab_pasteurization_count = parseFloat(submitModel.lab_pasteurization_count.toString());
            submitModel.LOS = parseFloat(submitModel.LOS.toString());
            submitModel.bacto_scan = parseFloat(submitModel.bacto_scan.toString());
            submitModel.fat_weight = selectedPickup?.volume && submitModel.fat ? parseFloat((submitModel.fat * selectedPickup.volume) / 100) : null;
            submitModel.protein_weight = selectedPickup?.volume && submitModel.protein ? parseFloat((submitModel.protein * selectedPickup.volume) / 100) : null;
        }
        if (region === 'RF') {
            submitModel.bulk_milk_cell_count = parseFloat(submitModel.bulk_milk_cell_count.toString());
            submitModel.thermo_plate_count = parseFloat(submitModel.thermo_plate_count.toString());
            submitModel.colostrum_percentage = parseFloat(submitModel.colostrum_percentage.toString());
            submitModel.demerit_points = submitModel.demerit_points ? parseFloat(submitModel.demerit_points.toString()) : undefined;
            submitModel.sediment_present = submitModel.sediment_present !== ' ' ? submitModel.sediment_present : null;
            submitModel.inhibitory_substances_present = submitModel.inhibitory_substances_present !== ' ' ? submitModel.inhibitory_substances_present : null;
            submitModel.LOS = parseFloat(submitModel.LOS.toString());
            submitModel.los_percentage = submitModel.LOS;
            submitModel.bacto_scan = parseFloat(submitModel.bacto_scan.toString());
            submitModel.sample_barcode = submitModel.sample_barcode ? submitModel.sample_barcode.toString() : '';
            submitModel.fat_weight = selectedPickup?.volume && submitModel.fat ? parseFloat((submitModel.fat * selectedPickup.volume) / 100) : null;
            submitModel.fat_percentage = submitModel.fat;
            submitModel.protein_weight = selectedPickup?.volume && submitModel.protein ? parseFloat((submitModel.protein * selectedPickup.volume) / 100) : null;
            submitModel.protein_percentage = submitModel.protein;
            submitModel.pickup_id = selectedPickup?.value || selectedPickup?.id || null;
        }
        if (['CDI', 'CACIQUE'].includes(region)) {
            submitModel.is_outlier = !!qualityChecked;
            submitModel.is_component_outlier = !!componentChecked;
            submitModel.lab_pickup_amount = submitModel.lab_pickup_amount ? parseFloat(submitModel.lab_pickup_amount.toString()) : 0;
            submitModel.somatic_cell_count = submitModel.somatic_cell_count && submitModel.somatic_cell_count !== '0' ? parseFloat(submitModel.somatic_cell_count.toString()) : null;
            submitModel.lactose = parseFloat(submitModel.lactose.toString());
            submitModel.other_solids = parseFloat(submitModel.other_solids.toString());
            submitModel.fat = parseFloat(submitModel.fat.toString());
            submitModel.protein = parseFloat(submitModel.protein.toString());
            submitModel.solids_not_fat = parseFloat(submitModel.solids_not_fat.toString());
            submitModel.freeze_point = parseFloat(submitModel.freeze_point);
            submitModel.standard_plate_count = submitModel.standard_plate_count && submitModel.standard_plate_count !== '0' ? parseFloat(submitModel.standard_plate_count.toString()) : null;
            submitModel.lab_pasteurization_count = parseFloat(submitModel.lab_pasteurization_count.toString());
            submitModel.milk_urea_nitrogen = submitModel.milk_urea_nitrogen && submitModel.milk_urea_nitrogen !== '0' ? parseFloat(submitModel.milk_urea_nitrogen.toString()) : null;
            submitModel.coliform_count = parseFloat(submitModel.coliform_count.toString());
            submitModel.preliminary_incubation = submitModel.preliminary_incubation && submitModel.preliminary_incubation !== '0' ? parseFloat(submitModel.preliminary_incubation.toString()) : null;
            submitModel.freeze_point = submitModel.freeze_point && submitModel.freeze_point !== '0' ? parseFloat(submitModel.freeze_point.toString()) : null;
            submitModel.ropy = submitModel.ropy && submitModel.ropy !== '0' ? parseFloat(submitModel.ropy.toString()) : null;
            submitModel.sample_barcodes = submitModel.sample_barcodes && submitModel.sample_barcodes[0] ? submitModel.sample_barcodes[0].toString() : '';
            submitModel.fat_weight = selectedPickup?.volume && submitModel.fat ? parseFloat((submitModel.fat * selectedPickup.volume) / 100) : null;
            submitModel.protein_weight = selectedPickup?.volume && submitModel.protein ? parseFloat((submitModel.protein * selectedPickup.volume) / 100) : null;

            submitModel.pickup_id = selectedPickup?.value || selectedPickup?.id || null;
            submitModel.sample_barcodes = [...Array(numBarcodes).keys()]
                .map((index) => {
                    const sample_code = model[`sample_barcode_${index}`];
                    delete submitModel[`sample_barcode_${index}`];
                    return sample_code;
                })
                .filter((sample_code) => !_.isEmpty(sample_code));
        }
        if (['MMPA'].includes(region)) {
            submitModel.is_component_outlier = !!componentChecked;
            submitModel.inhibitory_substances_present = submitModel.inhibitory_substances_present !== ' ' ? submitModel.inhibitory_substances_present : undefined;
            submitModel.denovo_fatty_acid = parseFloat(submitModel.denovo_fatty_acid.toString());
            submitModel.preformed_fatty_acid = parseFloat(submitModel.preformed_fatty_acid.toString());
            submitModel.mixed_fatty_acid = parseFloat(submitModel.mixed_fatty_acid.toString());
        }
        if (['LEGACY'].includes(region)) {
            delete submitModel.BOL;
            submitModel.is_outlier = !!qualityChecked;
            submitModel.is_component_outlier = !!componentChecked;
            submitModel.temperature = parseFloat(submitModel.temperature.toString());
            submitModel.test_frequency = frequencyChecked ? 'daily' : 'weekly';

            submitModel.somatic_cell_count = submitModel.somatic_cell_count && submitModel.somatic_cell_count !== '0' ? parseFloat(submitModel.somatic_cell_count.toString()) : null;
            submitModel.lactose = parseFloat(submitModel.lactose.toString());
            submitModel.other_solids = parseFloat(submitModel.other_solids.toString());
            submitModel.fat = parseFloat(submitModel.fat.toString());
            submitModel.protein = parseFloat(submitModel.protein.toString());
            submitModel.solids_not_fat = parseFloat(submitModel.solids_not_fat.toString());

            submitModel.standard_plate_count = submitModel.standard_plate_count && submitModel.standard_plate_count !== '0' ? parseFloat(submitModel.standard_plate_count.toString()) : null;
            submitModel.lab_pasteurization_count = submitModel.lab_pasteurization_count && submitModel.lab_pasteurization_count !== '0' ? parseFloat(submitModel.lab_pasteurization_count.toString()) : null;
            submitModel.milk_urea_nitrogen = submitModel.milk_urea_nitrogen && submitModel.milk_urea_nitrogen !== '0' ? parseFloat(submitModel.milk_urea_nitrogen.toString()) : null;
            submitModel.coliform_count = parseFloat(submitModel.coliform_count.toString());
            submitModel.preliminary_incubation = submitModel.preliminary_incubation && submitModel.preliminary_incubation !== '0' ? parseFloat(submitModel.preliminary_incubation.toString()) : null;
            submitModel.sample_barcodes = submitModel.sample_barcodes && submitModel.sample_barcodes[0] ? submitModel.sample_barcodes[0].toString() : '';
            submitModel.aflatoxin = submitModel.aflatoxin && submitModel.aflatoxin !== '0' ? parseFloat(submitModel.aflatoxin.toString()) : null;
            submitModel.pesticides = submitModel.pesticides && submitModel.pesticides !== '0' ? submitModel.pesticides.toString() : null;
            submitModel.sediments = submitModel.sediments && submitModel.sediments !== '0' ? submitModel.sediments.toString() : null;
            submitModel.pickup_id = selectedPickup?.value || selectedPickup?.id || null;
            submitModel.sample_barcodes = [...Array(numBarcodes).keys()]
                .map((index) => {
                    const sample_code = model[`sample_barcode_${index}`];
                    delete submitModel[`sample_barcode_${index}`];
                    return sample_code;
                })
                .filter((sample_code) => !_.isEmpty(sample_code));
            submitModel.total_protein_percentage = parseFloat(submitModel.total_protein_percentage);
        }
        if (region === 'UDA') {
            submitModel.is_outlier = !!is_outlier;

            submitModel.solids_not_fat = parseFloat(submitModel.solids_not_fat.toString());
            submitModel.standard_plate_count = submitModel.standard_plate_count && submitModel.standard_plate_count !== '0' ? parseFloat(submitModel.standard_plate_count.toString()) : null;
            submitModel.lab_pasteurization_count = parseFloat(submitModel.lab_pasteurization_count.toString());
            submitModel.milk_urea_nitrogen = submitModel.milk_urea_nitrogen && submitModel.milk_urea_nitrogen !== '0' ? parseFloat(submitModel.milk_urea_nitrogen.toString()) : null;
            submitModel.preliminary_incubation = submitModel.preliminary_incubation && submitModel.preliminary_incubation !== '0' ? parseFloat(submitModel.preliminary_incubation.toString()) : null;
            submitModel.coli_vrb = submitModel.coli_vrb && submitModel.coli_vrb !== '0' ? parseFloat(submitModel.coli_vrb.toString()) : null;
            submitModel.aflatoxin = submitModel.aflatoxin && submitModel.aflatoxin !== '0' ? parseFloat(submitModel.aflatoxin.toString()) : null;
            submitModel.pesticides = submitModel.pesticides && submitModel.pesticides !== '0' ? submitModel.pesticides.toString() : null;
            submitModel.sediments = submitModel.sediments && submitModel.sediments !== '0' ? submitModel.sediments.toString() : null;
            submitModel.sample_barcode = submitModel.sample_barcode ? submitModel.sample_barcode.toString() : '';
            submitModel.fat_weight = selectedPickup?.volume && submitModel.fat ? parseFloat((submitModel.fat * selectedPickup.volume) / 100) : null;
            submitModel.protein_weight = selectedPickup?.volume && submitModel.protein ? parseFloat((submitModel.protein * selectedPickup.volume) / 100) : null;
            submitModel.pickup_id = selectedPickup?.value || selectedPickup?.id || null;
            submitModel.sample_barcodes = [...Array(numBarcodes).keys()]
                .map((index) => {
                    const sample_code = model[`sample_barcode_${index}`];
                    delete submitModel[`sample_barcode_${index}`];
                    return sample_code;
                })
                .filter((sample_code) => !_.isEmpty(sample_code));
            submitModel.mycoplasma = submitModel.mycoplasma === ' ' ? (submitModel.mycoplasma = null) : submitModel.mycoplasma;
        }
        if (region === 'BORDEN') {
            submitModel.other_solids = submitModel.other_solids && submitModel.other_solids !== '0' ? parseFloat(submitModel.other_solids.toString()) : null;
            submitModel.total_solids = submitModel.total_solids && submitModel.total_solids !== '0' ? parseFloat(submitModel.total_solids.toString()) : null;
            submitModel.fat_weight = selectedPickup?.volume && submitModel.fat ? parseFloat((submitModel.fat * selectedPickup.volume) / 100) : null;
            submitModel.protein_weight = selectedPickup?.volume && submitModel.protein ? parseFloat((submitModel.protein * selectedPickup.volume) / 100) : null;
        }

        if (!['UDA'].includes(region) && typeof foundLabReport === 'object') {
            submitModel.pickup_id = foundLabReport.pickup_id;
        }

        if (['PRAIRIE'].includes(region)) {
            const somatic_cell_count = parseFloat(submitModel?.somatic_cell_count?.toString());
            const freeze_point = parseFloat(submitModel?.freeze_point);
            const standard_plate_count = parseFloat(submitModel?.standard_plate_count);
            const preliminary_incubation = parseFloat(submitModel?.preliminary_incubation);

            submitModel.somatic_cell_count_date = sccTestDate ? moment(sccTestDate).format() : undefined;
            submitModel.standard_plate_count_date = spcTestDate ? moment(spcTestDate).format() : undefined;
            submitModel.water_test_date = waterTestDate ? moment(waterTestDate).format() : undefined;
            submitModel.inhibitory_substances_present = submitModel.inhibitory_substances_present !== ' ' ? submitModel.inhibitory_substances_present : null;
            submitModel.water_added = submitModel.water_added !== ' ' ? submitModel.water_added : null;
            submitModel.is_official = !!officialChecked;
            submitModel.other_solids = parseFloat(submitModel?.other_solids) || undefined;
            submitModel.total_solids = parseFloat(submitModel?.total_solids) || undefined;
            submitModel.solids_not_fat = parseFloat(submitModel?.solids_not_fat) || undefined;
            submitModel.milk_urea_nitrogen = parseFloat(submitModel?.milk_urea_nitrogen) || undefined;
            submitModel.lactose = parseFloat(submitModel?.lactose?.toString()) || undefined;

            submitModel.somatic_cell_count = somatic_cell_count === 0 ? 0 : somatic_cell_count || undefined;
            submitModel.freeze_point = freeze_point === 0 ? 0 : freeze_point || undefined;
            submitModel.standard_plate_count = standard_plate_count === 0 ? 0 : standard_plate_count || undefined;
            submitModel.preliminary_incubation = preliminary_incubation === 0 ? 0 : preliminary_incubation || undefined;

            submitModel.sample_barcodes = [...Array(numBarcodes).keys()]
                .map((index) => {
                    const sample_code = model[`sample_barcode_${index}`];
                    delete submitModel[`sample_barcode_${index}`];
                    return sample_code;
                })
                .filter((sample_code) => !_.isEmpty(sample_code));
        }

        if (!submitModel.sample_barcode && barcode) delete submitModel.sample_barcode;
        delete submitModel.pickup;
        delete submitModel.barcode;

        // eslint-disable-next-line no-underscore-dangle
        const labId = editData?.id ? editData.id : editData?._id;

        if (editData) {
            this.setState({ loading: true });
            editLabReport(submitModel, labId)
                .then((data) => {
                    if (isLabAlreadyExistsMsg(data?.payload?.message)) {
                        showMessage({ message: getLabAlreadyExistsMsg(data?.payload?.message) });
                    } else {
                        showMessage({ message: 'Successfully Edited Lab Report.' });
                        addComment('lab_report', labId, 'lab report', true);
                    }
                    history.goBack();
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        } else {
            this.setState({ loading: true });
            addLabReport(submitModel)
                .then((data) => {
                    if (isLabAlreadyExistsMsg(data?.payload?.message)) {
                        showMessage({ message: getLabAlreadyExistsMsg(data?.payload?.message) });
                        history.goBack();
                    } else {
                        showMessage({ message: 'Successfully Added Lab Report.' });
                        history.push({ pathname: '/lab-reports' });
                    }
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        }
    };

    handleDateTimeChange = (date) => {
        this.setState({ selectedDate: date });
        this.getPickups(date);
    };

    handleSCCDateTimeChange = (date) => {
        this.setState({ sccTestDate: date });
    };

    handleSPCDateTimeChange = (date) => {
        this.setState({ spcTestDate: date });
    };

    handleWaterTestDateTimeChange = (date) => {
        this.setState({ waterTestDate: date });
    };

    handleProducerChange = (event, value) => {
        this.setState({ producer: value });
    };

    handleDiscardChange =
        ({ discarded }) =>
        () => {
            this.setState({ discarded: !discarded });
        };

    handleComponentChange =
        ({ componentChecked }) =>
        () => {
            this.setState({ componentChecked: !componentChecked });
        };

    handleOfficialCheckChange =
        ({ officialChecked }) =>
        () => {
            this.setState({ officialChecked: !officialChecked });
        };

    handleComponentAverages = async ({ producer, tank, selectedDate }) => {
        const labAverages = await getThirtyDayAverages(producer.id, tank.value, selectedDate);
        this.setState({ labAverages: { ...labAverages } });
        this.setState((prevState) => ({ manual_adjustment: !prevState.manual_adjustment }));
        this.setState((prevState) => ({ is_outlier: !prevState.is_outlier }));
    };

    handleComponentOverwrite = ({ editData }) => {
        this.setState((prevState) => ({ is_outlier: !prevState.is_outlier }));
    };

    handleQualityChange =
        ({ qualityChecked }) =>
        () => {
            this.setState({ qualityChecked: !qualityChecked });
        };

    handleFrequencyChange =
        ({ frequencyChecked }) =>
        () => {
            this.setState({ frequencyChecked: !frequencyChecked });
        };

    handleGetTanks = (event) => {
        const { producers } = this.props;
        const { value } = event.target;
        if (!!value) {
            const { tanks } = _.find(producers, { value });
            this.setState({ tanks, tank: 1 });
        }
        this.getPickups();
    };

    getPickups = (date) => {
        const { selectedDate, producer, sampleBarcodes, numBarcodes } = this.state;
        const { producers, editData, region } = this.props;
        const pickupDate = date || selectedDate;

        const pickupsQuery = getPickupsQuery({
            fields: ['id', 'producer_id', 'created_at', 'component_barcode', 'tank_number', 'route_session_id', 'volume', 'sample_barcodes', 'lab_report_ids'],
            start: moment(pickupDate).subtract(2, 'days').startOf('day').format(),
            end: moment(pickupDate).add(2, 'days').endOf('day').format(),
            producerId: editData?.producer ? _.find(producers, { label: editData.producer })?.value : producer.id,
            filterDeleted: true,
            filterType: producers.find((p) => p.value === producer)?.type,
        });

        axios.get(pickupsQuery).then(async (pickupResults) => {
            const pickups = pickupResults.data;

            const linkingIds = _.uniq(_.map(pickups, 'route_session_id'));
            const routeSessions = await getBulkQueryData(getRouteSessionsQuery, { fields: ['id', 'BOL', 'status', 'manifest_number'], ids: linkingIds });

            const pickupsWithRouteSession = _.sortBy(
                pickups.map((p) => {
                    const session = routeSessions.find((rs) => rs.id === p.route_session_id);

                    return {
                        ...p,
                        BOL: session?.BOL,
                        status: session?.status,
                        manifest_number: session?.manifest_number,
                    };
                }),
                ['created_at', 'BOL', 'tank_number']
            );

            // eslint-disable-next-line no-underscore-dangle
            const labId = editData?.id ? editData.id : editData?._id;
            if (editData && (editData.pickup_id || editData.sample_barcodes || labId)) {
                if (editData.pickup_id && pickupsWithRouteSession.find((p) => p.id === editData.pickup_id)) {
                    this.setState({ selectedPickup: pickupsWithRouteSession.find((p) => p.id === editData.pickup_id) });
                    this.setState({ sampleBarcodes: pickupsWithRouteSession.find((p) => p.id === editData.pickup_id)?.sample_barcodes || sampleBarcodes });
                    this.setState({ numBarcodes: pickupsWithRouteSession.find((p) => p.id === editData.pickup_id)?.sample_barcodes?.length || numBarcodes });
                } else if (editData.sample_barcodes && pickupsWithRouteSession.find((p) => p?.sample_barcodes?.[0] === editData?.sample_barcodes?.[0])) {
                    this.setState({ selectedPickup: pickupsWithRouteSession.find((p) => p.sample_barcodes?.includes(editData.sample_barcodes[0])) });
                    this.setState({ sampleBarcodes: pickupsWithRouteSession.find((p) => p.sample_barcodes?.includes(editData.sample_barcodes[0]))?.sample_barcodes || sampleBarcodes });
                    this.setState({ numBarcodes: pickupsWithRouteSession.find((p) => p.sample_barcodes?.includes(editData.sample_barcodes[0]))?.sample_barcodes?.length || numBarcodes });
                } else if (labId && pickupsWithRouteSession.find((p) => p?.lab_report_ids?.[0] === labId)) {
                    this.setState({ selectedPickup: pickupsWithRouteSession.find((p) => p?.lab_report_ids?.includes(labId)) });
                    this.setState({ sampleBarcodes: pickupsWithRouteSession.find((p) => p?.lab_report_ids?.includes(labId))?.sample_barcodes || sampleBarcodes });
                    this.setState({ numBarcodes: pickupsWithRouteSession.find((p) => p?.lab_report_ids?.includes(labId))?.sample_barcodes?.length || numBarcodes });
                }
            }
            this.setState({ pickups: pickupsWithRouteSession });
        });
    };

    handleTankChange = (event, value) => {
        this.setState({ tank: value });
    };

    handlePickupChange = (event, value) => {
        const { pickups, sampleBarcodes, numBarcodes } = this.state;
        const newPickup = pickups.find((p) => p?.id === value?.id);
        this.setState({ selectedPickup: newPickup });
        // don't overwrite if the barcodes is an array of empty strings
        this.setState({ sampleBarcodes: newPickup?.sample_barcodes?.includes('') ? sampleBarcodes : newPickup?.sample_barcodes });
        this.setState({ numBarcodes: newPickup?.sample_barcodes?.includes('') ? numBarcodes : newPickup?.sample_barcodes?.length });
    };

    checkBarcode = (event) => {
        if (!event.target.value) {
            this.setState({ foundLabReport: 'notChecked' });
        } else {
            this.setState({ foundLabReport: 'notChecked' });
            getLabreportFromBarcode(event.target.value).then((payload) => {
                if (payload.lab_reports) {
                    this.setState({ foundLabReport: payload.lab_reports });
                } else {
                    this.setState({ foundLabReport: false });
                }
            });
        }
    };

    pickupsToString = (entry) => {
        const region = getUserRegion();
        switch (region) {
            case 'CDI':
            case 'CACIQUE':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} - Manifest: ${entry.manifest_number} - Tank: ${entry.tank_number} - Amount: ${entry.volume}lbs`;
            case 'UDA':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} - Invoice: ${entry.BOL} - Tank: ${entry.tank_number} - Amount: ${entry.volume}lbs`;
            case 'BORDEN':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} - Amount: ${entry.volume}lbs - Ticket Number: ${entry.component_barcode}`;
            case 'PEI':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} - Volume: ${entry.volume}`;
            case 'PRAIRIE':
            case 'LEGACY':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} ${entry?.sample_barcodes?.[0] ? `- Barcode: ${entry.sample_barcodes}` : ''} - Tank: ${entry.tank_number} - Amount: ${entry.volume}lbs`;
            case 'MMPA':
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} ${entry?.sample_barcodes?.[0] ? `- Barcode: ${barcodeLastFourDigits(entry.sample_barcodes[0])}` : ''} - Tank: ${entry.tank_number} - Amount: ${entry.volume}lbs`;
            default:
                return `Date: ${moment(entry.created_at).format('YYYY/MM/DD')} - Volume: ${entry.volume} - Barcode: ${entry.component_barcode}`;
        }
    };

    render() {
        const {
            canSubmit,
            selectedDate,
            sccTestDate,
            spcTestDate,
            waterTestDate,
            discarded,
            numBarcodes,
            sampleBarcodes,
            tank,
            pickups,
            barcode,
            selectedPickup,
            fat,
            fatWeight,
            protein,
            proteinWeight,
            componentChecked,
            officialChecked,
            qualityChecked,
            frequencyChecked,
            labAverages,
            producer,
            lactose,
            scc,
            manual_adjustment,
            is_outlier,
            bol,
            loading,
            otherSolids,
            solidsNotFat,
            total_protein_percentage,
        } = this.state;
        const { producers, editData, region } = this.props;

        if (loading) {
            return <LoadingDialog showDialog={loading} />;
        }

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                        <Divider />

                        <Formsy
                            onValidSubmit={_.debounce(this.onSubmit, 500)}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {!['PRAIRIE'].includes(region) && (
                                <div className="w-full my-16">
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <RestrictedDatePicker label="Date" value={selectedDate} onChange={this.handleDateTimeChange} inputVariant="outlined" />
                                    </MuiPickersUtilsProvider>
                                </div>
                            )}
                            {['PRAIRIE'].includes(region) && (
                                <>
                                    {editData && (
                                        <div className="flex flex-row items-center justify-between">
                                            <div className="w-full my-16">
                                                <TextField label="Batch Received Date" value={editData?.batch?.received_date ? moment(editData?.batch?.received_date).format('DD-MM-YYYY') : '-'} disabled />
                                            </div>
                                            <div className="w-full my-16" style={{ marginRight: '12px' }}>
                                                <TextField label="Batch Received Temp." value={editData?.batch?.received_temperature || '-'} disabled />
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex flex-row items-center justify-between">
                                        <div className="w-full my-16">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <RestrictedDatePicker label="Date" value={selectedDate} onChange={this.handleDateTimeChange} inputVariant="outlined" />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="w-full my-16">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <RestrictedDatePicker label="Water Test Date" value={waterTestDate} onChange={this.handleWaterTestDateTimeChange} inputVariant="outlined" />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </>
                            )}

                            <SearchableSelectAll label="Producers" options={producers} value={producer} required onChange={this.handleProducerChange} />

                            {region !== 'NL' && <SearchableSelectAll label="Pickups" options={pickups} value={selectedPickup} customRender={this.pickupsToString} onChange={this.handlePickupChange} />}

                            <SearchableSelectAll label="Tank" required options={Array.from({ length: 9 }, (_e, i) => i + 1).map((entry) => ({ value: `${entry}`, name: `${entry}` }))} value={tank} onChange={this.handleTankChange} />

                            {['LEGACY'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="BOL"
                                    label="LT Number"
                                    value={bol}
                                    validations={{ isAlphanumeric: true }}
                                    validationError="This is not a valid LT Number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    fingerprint
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    disabled
                                    variant="outlined"
                                />
                            )}
                            {['RF', 'BORDEN'].includes(region) && (!editData || !editData.pickup_id) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="sample_barcode"
                                    label={region === 'BORDEN' ? 'Ticket Number' : 'Barcode'}
                                    onBlur={this.checkBarcode}
                                    placeholder="Barcode"
                                    value={barcode}
                                    // customErrorMessage={!foundLabReport ? 'The entered barcode does not exist' : null}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/barcode.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['PRAIRIE'].includes(region) && (
                                <>
                                    {[...Array(numBarcodes).keys()].map((index) => {
                                        return (
                                            <TextFieldFormsy
                                                className="my-16"
                                                type="text"
                                                name={`sample_barcode_${index}`}
                                                label={`Sample Barcode ${index + 1}`}
                                                onBlur={this.checkBarcode}
                                                placeholder="Barcode"
                                                value={sampleBarcodes?.[index] || ''}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/barcode.png" alt="logo" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </>
                            )}
                            {['UDA', 'CDI', 'CACIQUE', 'LEGACY'].includes(region) && sampleBarcodes && (
                                <>
                                    {[...Array(numBarcodes).keys()].map((index) => {
                                        return (
                                            <TextFieldFormsy
                                                className="my-16"
                                                type="text"
                                                name={`sample_barcode_${index}`}
                                                label={`Sample Barcode ${index + 1}`}
                                                onBlur={this.checkBarcode}
                                                placeholder="Barcode"
                                                value={sampleBarcodes?.[index] || ''}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/barcode.png" alt="logo" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                        );
                                    })}
                                </>
                            )}
                            {['UDA', 'CDI', 'CACIQUE', 'LEGACY'].includes(region) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="mx-auto my-16"
                                    onClick={() =>
                                        this.setState((prevState, props) => ({
                                            numBarcodes: prevState.numBarcodes + 1,
                                        }))
                                    }
                                >
                                    Add Barcode
                                </Button>
                            )}
                            {['LEGACY'].includes(region) && (
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox checked={!!frequencyChecked} onChange={this.handleFrequencyChange({ ...this.state })} inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard from Quality Program" />

                                    <FormControlLabel control={<Checkbox checked={!!componentChecked} onChange={this.handleComponentChange({ ...this.state })} value="is_component_outlier" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard Component Sample" />
                                </FormGroup>
                            )}
                            {['CDI', 'CACIQUE'].includes(region) && !!editData && (
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox checked={!!qualityChecked} onChange={this.handleQualityChange({ ...this.state })} value="is_outlier" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard Quality Sample" />

                                    <FormControlLabel control={<Checkbox checked={!!componentChecked} onChange={this.handleComponentChange({ ...this.state })} value="is_component_outlier" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard Component Sample" />
                                </FormGroup>
                            )}

                            {['MMPA'].includes(region) && !!editData && (
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox checked={!!componentChecked} onChange={this.handleComponentChange({ ...this.state })} value="is_component_outlier" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard Component Sample" />
                                </FormGroup>
                            )}

                            {['UDA'].includes(region) && editData && (
                                <div className="mx-auto my-16">
                                    <ConfirmationDialog title={'Overwrite Outlier Values?'} disabled={!is_outlier} message={'Are you sure you want to overwrite outlier values?'} action={() => this.handleComponentOverwrite({ ...this.state, ...this.props })} />
                                </div>
                            )}

                            {['UDA'].includes(region) && !editData && (
                                <div className="mx-auto my-16">
                                    <ConfirmationDialog disabled={!!manual_adjustment || !producer} title={'Populate Components from Average Values?'} message={'Are you sure you want to populate components from average values?'} action={() => this.handleComponentAverages({ ...this.state })} />
                                </div>
                            )}

                            {['CDI'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="number"
                                    name="lab_pickup_amount"
                                    label="Lab Provider Volume"
                                    placeholder="Lab Provider Volume"
                                    value={editData && editData.lab_pickup_amount ? editData.lab_pickup_amount : ''}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid lab provider volume"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-21 material-icons" color="action">
                                                    local_drink
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['PRAIRIE'].includes(region) && (
                                <>
                                    <div className="flex flex-row items-center justify-between">
                                        <div className="flex flex-col w-2/3 my-16">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <RestrictedDatePicker label="SPC Test Date" value={spcTestDate} onChange={this.handleSPCDateTimeChange} inputVariant="outlined" />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="flex flex-col w-2/3 my-16">
                                            <TextFieldFormsy
                                                type="text"
                                                name="standard_plate_count"
                                                label="SPC"
                                                placeholder="SPC"
                                                value={editData && editData.standard_plate_count ? editData.standard_plate_count : '0'}
                                                validation={{ isNumeric: true }}
                                                validationError="This is not a valid SPC value"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/forensic-science.png" alt="logo" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-row items-center justify-between">
                                        <div className="flex flex-col w-2/3 my-16">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <RestrictedDatePicker label="SCC Test Date" value={sccTestDate} onChange={this.handleSCCDateTimeChange} inputVariant="outlined" />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <div className="flex flex-col w-2/3 my-16">
                                            <TextFieldFormsy
                                                type="text"
                                                name="somatic_cell_count"
                                                label="SCC"
                                                placeholder="SCC"
                                                value={labAverages?.averageScc ? labAverages.averageScc.toFixed().toString() : scc?.toFixed().toString()}
                                                validation={{ isNumeric: true }}
                                                validationError="This is not a valid SCC value"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/molecule.png" alt="logo" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="fat"
                                label={['CDI', 'CACIQUE', 'RF', 'UDA'].includes(region) ? 'Fat Percentage' : 'Butterfat Percentage'}
                                placeholder="Butterfat"
                                value={labAverages?.averageFat ? labAverages.averageFat.toFixed(2).toString() : fat?.toFixed(2).toString()}
                                validation={{ isNumeric: true }}
                                validationError="This is not a valid butter fat value"
                                required={!['PRAIRIE'].includes(region)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/butter.png" alt="logo" />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                onChange={(event) => (region !== 'NL' && selectedPickup ? this.setState({ fatWeight: (parseFloat(event?.target?.value || 0) * selectedPickup?.volume || 0) / 100 }) : '0')}
                            />
                            {!['NL', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="fat_weight"
                                    label={region === 'RF' ? 'Fat Weight' : 'Butterfat Weight'}
                                    placeholder={region === 'RF' ? 'Fat Weight' : 'Butterfat Weight'}
                                    value={selectedPickup !== '' && (fat !== null || fat !== undefined) ? fatWeight.toFixed(2) : 0}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/butter.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    disabled
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="protein"
                                label="Protein Percentage"
                                placeholder="Protein"
                                value={labAverages?.averageProtein ? labAverages.averageProtein.toFixed(2).toString() : protein?.toFixed(2).toString()}
                                validation={{ isNumeric: true }}
                                validationError="This is not a valid protein value"
                                required={!['PRAIRIE'].includes(region)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img className="mr-4" width="21" src="assets/MMAssets/icons/muscle.png" alt="logo" />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                onChange={(event) =>
                                    !['NL', 'CDI', 'CACIQUE'].includes(region) && selectedPickup ? this.setState({ proteinWeight: (parseFloat(event?.target?.value || 0) * selectedPickup?.volume || 0) / 100 }) : ['CDI', 'CACIQUE'].includes(region) ? this.setState({ otherSolids: solidsNotFat - parseFloat(event?.target?.value) }) : '0'
                                }
                            />

                            {['LEGACY'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="total_protein_percentage"
                                    label="Total Protein Percentage"
                                    placeholder="Total Protein"
                                    value={total_protein_percentage?.toFixed(2).toString()}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid total protein percentage value"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/muscle.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {!['NL', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="protein_weight"
                                    label="Protein Weight"
                                    placeholder="Protein Weight"
                                    value={selectedPickup !== '' && (protein !== null || protein !== undefined) ? proteinWeight.toFixed(2) : 0}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/muscle.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    disabled
                                />
                            )}
                            {(region === 'NL' || region === 'PEI' || region === 'RF') && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="LOS"
                                    label="LOS"
                                    placeholder="LOS"
                                    value={editData && editData.los ? editData.los.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid LOS value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['CDI', 'CACIQUE', 'UDA', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="lactose"
                                    label="Lactose"
                                    placeholder="Lactose"
                                    value={labAverages?.averageLactose.toFixed(2).toString() || lactose?.toFixed(2).toString() || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid lactose value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['CDI', 'CACIQUE', 'BORDEN', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="other_solids"
                                    label="Other Solids"
                                    placeholder="Other Solids"
                                    value={otherSolids || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid other solids value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    disabled={['CDI', 'CACIQUE'].includes(region)}
                                />
                            )}
                            {['BORDEN', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="total_solids"
                                    label="Total Solids"
                                    placeholder="Total Solids"
                                    value={editData && editData.total_solids ? editData.total_solids.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid total solids value"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['NL', 'PEI', 'CDI', 'CACIQUE', 'UDA', 'LEGACY', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="somatic_cell_count"
                                    label="SCC"
                                    placeholder="SCC"
                                    value={labAverages?.averageScc ? labAverages.averageScc.toFixed().toString() : scc?.toFixed().toString()}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid SCC value"
                                    required={!['CDI', 'CACIQUE'].includes(region)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/molecule.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {region === 'RF' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="bulk_milk_cell_count"
                                    label="BMCC"
                                    placeholder="BMCC"
                                    value={editData && editData.bmcc ? editData.bmcc : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid BMCC value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/molecule.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['NL', 'PEI', 'RF', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="bacto_scan"
                                    label={['MMPA'].includes(region) ? 'RAW' : 'BACTOSCAN (IBC/ml x1000)'}
                                    placeholder="BACTOSCAN (IBC/ml x1000)"
                                    value={editData && editData.bacto_scan ? editData.bacto_scan : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid bacto scan value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/forensic-science.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['CDI', 'CACIQUE', 'UDA', 'LEGACY'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="standard_plate_count"
                                    label="SPC"
                                    placeholder="SPC"
                                    value={editData && editData.standard_plate_count ? editData.standard_plate_count : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid SPC value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/forensic-science.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['CDI', 'CACIQUE', 'UDA', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="milk_urea_nitrogen"
                                    label="MUN"
                                    placeholder="MUN"
                                    value={editData && editData.milk_urea_nitrogen ? editData.milk_urea_nitrogen : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid MUN value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/forensic-science.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {region === 'NL' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="bacteria_PLC"
                                    label="Bacteria PLC (CFU/ml)"
                                    placeholder="Bacteria PLC (CFU/ml)"
                                    value={editData && editData.bacto_plc ? editData.bacto_plc : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid bacto PLC value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/virus.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['PEI', 'CDI', 'CACIQUE', 'UDA', 'LEGACY', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="lab_pasteurization_count"
                                    label={region === 'PEI' ? 'Lab Pasteurization Count' : 'LPC'}
                                    placeholder="Lab Pasteurization Count"
                                    value={editData?.LPC || editData?.lab_pasteurization_count || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid LPC value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/virus.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['CDI', 'CACIQUE', 'PEI', 'LEGACY', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="coliform_count"
                                    label="Coliform Count"
                                    placeholder="Coliform Count"
                                    value={editData?.CC || editData?.coliform_count || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid CC value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/virus.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {['CDI', 'CACIQUE', 'UDA', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="solids_not_fat"
                                    label="SNF"
                                    placeholder="SNF"
                                    value={labAverages?.averageSNF.toFixed(2).toString() || solidsNotFat || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid SNF value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    onChange={(event) => (['CDI', 'CACIQUE'].includes(region) && selectedPickup ? this.setState({ otherSolids: parseFloat(event?.target?.value) - protein }) : '0')}
                                />
                            )}
                            {['CDI', 'CACIQUE', 'UDA', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="preliminary_incubation"
                                    label={['MMPA'].includes(region) ? 'PIC' : 'PI'}
                                    placeholder="PI"
                                    value={editData?.preliminary_incubation?.toFixed(2).toString() || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid PI value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['CDI', 'CACIQUE', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="freeze_point"
                                    label="Freezing Point"
                                    placeholder="Freezing Point"
                                    value={editData && editData.freeze_point ? editData.freeze_point.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid Freezing Point value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['CDI', 'CACIQUE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="ropy"
                                    label="Ropy"
                                    placeholder="Ropy"
                                    value={editData && editData.ropy ? editData.ropy.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid ropy value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {region === 'UDA' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="coli_vrb"
                                    label="Coliform"
                                    placeholder="Coliform"
                                    value={editData && editData.coli_vrb ? editData.coli_vrb.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid value"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/test-tube.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['UDA', 'LEGACY'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="aflatoxin"
                                    label="Aflatoxin"
                                    placeholder="Aflatoxin"
                                    value={editData && editData.aflatoxin ? editData.aflatoxin : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid thermo plate count value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/tank.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['UDA', 'LEGACY'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="pesticides"
                                    label="Pesticides"
                                    placeholder="Pesticides"
                                    value={editData && editData.pesticides ? editData.pesticides : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid value"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/speedometer.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['UDA', 'LEGACY', 'PRAIRIE', 'MMPA'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="sediments"
                                    label="Sediments"
                                    placeholder="Sediments"
                                    value={editData?.sediments || '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid value"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/speedometer.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {region === 'RF' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="thermo_plate_count"
                                    label="Thermo Plate Count"
                                    placeholder="Thermo Plate Count"
                                    value={editData && editData.thermo_plate_count ? editData.thermo_plate_count : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid thermo plate count value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/tank.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}

                            {region === 'RF' && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="colostrum_percentage"
                                    label="Colostrum Percentage"
                                    placeholder="Colostrum Percentage"
                                    value={editData && editData.colostrum_percentage ? editData.colostrum_percentage : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid colostrum percentage value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img className="mr-4" width="21" src="assets/MMAssets/icons/speedometer.png" alt="logo" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['RF', 'PEI', 'NL'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="text"
                                    name="temperature"
                                    label={region === 'RF' ? 'Temperature' : 'Freezing Point'}
                                    placeholder={region === 'RF' ? 'Temperature' : 'Freezing Point'}
                                    value={editData && editData.freezing_point ? editData.freezing_point.toFixed(2).toString() : '0'}
                                    validation={{ isNumeric: true }}
                                    validationError="This is not a valid temperature value"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-21 material-icons" color="action">
                                                    ac_unit
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['LEGACY'].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="temperature"
                                        label="Temperature"
                                        placeholder="Temperature"
                                        value={editData && editData.temperature ? editData.temperature.toFixed(2).toString() : '0'}
                                        validation={{ isNumeric: true }}
                                        validationError="This is not a valid temperature value"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-21 material-icons" color="action">
                                                        ac_unit
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {region === 'RF' && (
                                <>
                                    <FormControlLabel control={<Checkbox name="checkedD" checked={this.state.shouldOverrideDemeritCalculation} onChange={() => this.setState((preState) => ({ shouldOverrideDemeritCalculation: !preState.shouldOverrideDemeritCalculation }))} />} label="Override Automatic Demerit Calculation" />
                                    {this.state.shouldOverrideDemeritCalculation && (
                                        <TextFieldFormsy
                                            className="my-16"
                                            autoFocus
                                            type="text"
                                            name="demerit_points"
                                            label="Demerit Points"
                                            placeholder="Demerit Points"
                                            disabled={!this.state.shouldOverrideDemeritCalculation}
                                            value={this.state.shouldOverrideDemeritCalculation ? (editData && editData.demerit_points ? editData.demerit_points : '0') : null}
                                            validation={{ isNumeric: true }}
                                            validationError="This is not a valid demerit points value"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <img className="mr-4" width="21" src="assets/MMAssets/icons/edit.png" alt="logo" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    )}
                                </>
                            )}

                            {region === 'RF' && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="sediment_present"
                                    label="Sediment"
                                    variant="standard"
                                    options={[
                                        { value: ' ', name: 'None' },
                                        { value: true, name: 'Present' },
                                        { value: false, name: 'Absent' },
                                    ]}
                                    value={editData && (editData.sediment_present ? editData.sediment_present : editData.sediment_present === false ? false : ' ')}
                                    validationError="Please select"
                                    required
                                />
                            )}

                            {['RF'].includes(region) && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="inhibitory_substances_present"
                                    label={['MMPA'].includes(region) ? 'Antibiotics Positive Load' : `${localization.general.inhibitor}`}
                                    variant="standard"
                                    options={[
                                        { value: ' ', name: 'None' },
                                        { value: true, name: 'Present' },
                                        { value: false, name: 'Absent' },
                                    ]}
                                    value={editData && (editData.inhibitory_substances_present ? editData.inhibitory_substances_present : editData.inhibitory_substances_present === false ? false : ' ')}
                                    validationError="Please select"
                                    required
                                />
                            )}
                            {['MMPA'].includes(region) && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="inhibitory_substances_present"
                                    label={'Antibiotics Positive Load'}
                                    variant="standard"
                                    options={[
                                        { value: ' ', name: 'Not Tested' },
                                        { value: true, name: 'Y' },
                                        { value: false, name: 'N' },
                                    ]}
                                    value={editData && (editData.inhibitory_substances_present ? editData.inhibitory_substances_present : editData.inhibitory_substances_present === false ? false : ' ')}
                                    validationError="Please select"
                                    required
                                />
                            )}

                            {['PRAIRIE'].includes(region) && (
                                <>
                                    <FormGroup row>
                                        <FormControlLabel control={<Checkbox checked={!!officialChecked} onChange={this.handleOfficialCheckChange({ ...this.state })} value="is_official" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Official" />
                                    </FormGroup>

                                    <div className="flex flex-row items-center justify-between">
                                        <SelectSearchFormsy
                                            className="my-16 w-1/2"
                                            variant="outlined"
                                            name="inhibitory_substances_present"
                                            label={`${localization.general.inhibitor}`}
                                            options={[
                                                { value: ' ', name: 'Not Done' },
                                                { value: true, name: 'Found' },
                                                { value: false, name: 'Not Found' },
                                            ]}
                                            value={!editData || editData.inhibitory_substances_present === null ? ' ' : editData.inhibitory_substances_present}
                                            validationError="Please select"
                                        />

                                        <SelectSearchFormsy
                                            className="my-16 w-1/2"
                                            variant="outlined"
                                            name="water_added"
                                            label="Water Added"
                                            options={[
                                                { value: ' ', name: 'Not Done' },
                                                { value: true, name: 'Found' },
                                                { value: false, name: 'Not Found' },
                                            ]}
                                            value={!editData || editData.water_added === null ? ' ' : editData.water_added}
                                            validationError="Please select"
                                        />
                                    </div>
                                </>
                            )}
                            {['UDA'].includes(region) && (
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="mycoplasma"
                                    label="Mycoplasma"
                                    variant="standard"
                                    options={[
                                        { value: ' ', name: 'Not Tested' },
                                        { value: true, name: 'Present' },
                                        { value: false, name: 'Absent' },
                                    ]}
                                    value={editData && (editData.mycoplasma ? editData.mycoplasma : editData.mycoplasma === false ? false : ' ')}
                                    validationError="Please select"
                                />
                            )}
                            {['MMPA'].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="denovo_fatty_acid"
                                        label="DNO"
                                        placeholder="DNO"
                                        value={editData && editData.denovo_fatty_acid ? editData.denovo_fatty_acid.toFixed(2).toString() : '0'}
                                        validation={{ isNumeric: true }}
                                        validationError="This is not a valid value"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-21 material-icons" color="action">
                                                        ac_unit
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {['MMPA'].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="mixed_fatty_acid"
                                        label="MIX"
                                        placeholder="MIX"
                                        value={editData && editData.mixed_fatty_acid ? editData.mixed_fatty_acid.toFixed(2).toString() : '0'}
                                        validation={{ isNumeric: true }}
                                        validationError="This is not a valid value"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-21 material-icons" color="action">
                                                        ac_unit
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {['MMPA'].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="preformed_fatty_acid"
                                        label="PRE"
                                        placeholder="PRE"
                                        value={editData && editData.preformed_fatty_acid ? editData.preformed_fatty_acid.toFixed(2).toString() : '0'}
                                        validation={{ isNumeric: true }}
                                        validationError="This is not a valid value"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-21 material-icons" color="action">
                                                        ac_unit
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}
                            {['MMPA'].includes(region) && editData && (
                                <>
                                    <CheckboxFormsy name="staph_ag_present" label="SAG" variant="standard" value={editData.staph_ag_present} />
                                    <CheckboxFormsy name="staph_non_ag_present" label="STNAG" variant="standard" value={editData.staph_non_ag_present} />
                                    <CheckboxFormsy name="staph_aureus_present" label="SAU" variant="standard" value={editData.staph_aureus_present} />
                                    <CheckboxFormsy name="positive_drug_residue" label="Positive Drug Residue?" variant="standard" value={editData.positive_drug_residue} />
                                    <CheckboxFormsy name="positive_drug_residue" label="Positive Drug Residue?" variant="standard" value={editData.positive_drug_residue} />
                                    <CheckboxFormsy name="abnormal_freeze_point" label="Abnormal Freeze Point?" variant="standard" value={editData.abnormal_freeze_point} />
                                    <CheckboxFormsy name="three_or_four_sediment" label="#3 or #4 Sediment?" variant="standard" value={editData.three_or_four_sediment} />
                                    <CheckboxFormsy name="high_or_rejected_load" label="High/Rejected Load?" variant="standard" value={editData.high_or_rejected_load} />
                                </>
                            )}

                            {region === 'PEI' && !!editData && discarded !== null && <FormControlLabel control={<Checkbox checked={!!discarded} onChange={this.handleDiscardChange({ ...this.state })} value="is_outlier" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Discard results from averages" />}

                            <Button data-testId="lab-report-submit-button" type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label="EDIT LAB REPORT" disabled={!canSubmit}>
                                {!!editData ? 'Edit Lab Report' : 'Create Lab Report'}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region, role: persisted.auth.user.role };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            editLabReport: Actions.editLabReport,
            addLabReport: Actions.addLabReport,
            showMessage: Actions.showMessage,
            addComment: Actions.addComment,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditLabReportForm));
