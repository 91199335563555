import _ from 'lodash';
import moment from 'moment-timezone';
import { getAPIHost, getId, getRole, getUserRegion } from '../../../utils';
import HaulingContractType from '../../constants/hauling';
import { PaymentType, ReportNamesByRegion } from '../../constants/report';
import { buildExpressQuery, buildLoopbackQuery } from './builders';
import buildServerlessLabQuery from './builders/buildServerlessLabQuery';

export { default as batchQuery } from './batchQuery';
export { default as bulkQuery } from './bulkQuery';

export const buildFieldFilter = (fields, include = true) =>
    _.reduce(
        fields,
        (acc, val) => {
            acc[val] = include;
            return acc;
        },
        {}
    );

export const getPickupsQuery = ({ fields, id, start, end, limit, producerId, producerIds, routeSessionIds, driverIds, componentBarcode, sampleBarcode, pickupManifestNumber, filterDeleted, removeOrder, filterType, hint, populate, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/pickups';
    const params = {};
    if (!removeOrder) {
        params.order = 'created_at DESC';
    }
    params.where = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (limit) {
        params.limit = limit;
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (producerId) {
        params.where = { ...params.where, producer_id: producerId };
    }
    if (producerIds) {
        params.where = { ...params.where, producer_id: { inq: producerIds } };
    }
    if (routeSessionIds) {
        params.where = { ...params.where, route_session_id: { inq: routeSessionIds } };
    }
    if (driverIds) {
        params.where = { ...params.where, driver_id: { inq: driverIds } };
    }
    if (componentBarcode) {
        params.where = { ...params.where, component_barcode: componentBarcode };
    }
    if (sampleBarcode) {
        params.where = { ...params.where, sample_barcodes: sampleBarcode };
    }
    if (pickupManifestNumber) {
        params.where = { ...params.where, pickup_manifest_number: pickupManifestNumber };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterType === 'cream') {
        params.where = { ...params.where, type_of_fluid: { eq: 'cream' } };
    }
    if (filterType === 'milk') {
        params.where = { ...params.where, type_of_fluid: { eq: 'milk' } };
    }
    if (hint) {
        params.where = { ...params.where, hint };
    }
    if (populate) {
        params.populate = { ...params.populate, ...populate };
    }
    return queryBuilder(endpoint, { params });
};

export const getProducerBusinessPickUpsQuery = ({ producerBusinessId, start, end }) => {
    const params = {
        where: {
            producerBusinessId: { eq: producerBusinessId },
            startDate: { eq: start },
            endDate: { eq: end },
        },
    };
    return buildExpressQuery('/pickups/producer-business', { params });
};

export const getPenaltyStatusQuery = ({ date, queryBuilder = buildExpressQuery }) => {
    const producerId = getId();
    const endpoint = `/users/quality-status/${date}/${producerId}`;
    const params = {};
    return queryBuilder(endpoint, { params });
};

export const getDropoffsQuery = ({ id, fields, start, end, limit, processorId, routeSessionIds, driverIds, filterDeleted, filterType, hint, sampleBarcode, populate, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/dropoffs';
    const params = {};
    params.order = 'created_at DESC';
    params.where = {};
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (limit) {
        params.limit = limit;
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (routeSessionIds) {
        params.where = { ...params.where, route_session_id: { inq: routeSessionIds } };
    }
    if (driverIds) {
        params.where = { ...params.where, driver_id: { inq: driverIds } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterType === 'cream') {
        params.where = { ...params.where, type_of_fluid: { eq: 'cream' } };
    }
    if (filterType === 'milk') {
        params.where = { ...params.where, type_of_fluid: { eq: 'milk' } };
    }
    if (hint) {
        params.where = { ...params.where, hint };
    }
    if (sampleBarcode) {
        params.where = { ...params.where, sample_barcodes: sampleBarcode };
    }
    if (populate) {
        params.populate = { ...params.populate, ...populate };
    }
    return queryBuilder(endpoint, { params });
};

export const getLabProvidersQuery = ({ fields, ids, processorId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'lab_provider' };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getDriversQuery = ({ fields, ids, haulingId, filterDeleted, desiredOrder, limit, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'driver' };
    if (desiredOrder) {
        params.order = desiredOrder;
    }
    if (limit) {
        params.limit = limit;
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getReceiversQuery = ({ fields, ids, processorId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'receiver' };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getProducersQuery = ({ fields, excludeFields = ['bulk_charts'], ids, filterDeleted, filterInactive, filterSplitProducer, orderBy, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'producer', subuser: { neq: true } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    } else if (excludeFields) {
        // fields can't be both included & excluded
        params.fields = buildFieldFilter(excludeFields, false);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterInactive) {
        params.where = { ...params.where, status: { neq: 'Inactive' } };
    }
    if (filterSplitProducer) {
        params.where = { ...params.where, split_producer: { neq: true } };
    }
    if (orderBy) {
        params.order = `${orderBy} ASC`;
    }

    return queryBuilder(endpoint, { params });
};

export const getSplitProducerWithParentOrChildProducerQuery = (producerId, queryBuilder = buildExpressQuery) => {
    const endpoint = `/split-producer-agreement/producer-id/${producerId}`;
    return queryBuilder(endpoint, { params: {} });
};

export const getProducerTouchBaseDetailsQuery = (date, queryBuilder = buildExpressQuery) => {
    const endpoint = '/users/producer-touch-base-details';
    const params = {
        where: {
            date: { eq: date },
        },
    };
    return queryBuilder(endpoint, { params });
};

export const getBarcodeManagementQuery = (queryBuilder = buildExpressQuery) => {
    const endpoint = '/users/barcode-management';
    const params = {};
    return queryBuilder(endpoint, { params });
};

export const getProducerBusinessesQuery = ({ fields, ids, producerBusinessId, filterDeleted, orderBy, populate, queryBuilder = buildExpressQuery }) => {
    let endpoint = '/users';
    const params = {};
    params.where = { role: 'producer_business', subuser: { eq: false } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (orderBy) {
        params.order = `${orderBy} ASC`;
    }
    if (populate) {
        endpoint += '/populated';
        params.populate = { ...params.populate, ...populate };
    }
    if (producerBusinessId) {
        params.where = { ...params.where, id: producerBusinessId };
    }
    return queryBuilder(endpoint, { params });
};

export const getEquityMembersQuery = ({ fields, ids, filterDeleted, filterSubusers = true, populate, queryBuilder = buildExpressQuery }) => {
    let endpoint = '/users';
    const params = {};
    params.where = { role: 'producer' };

    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterSubusers) {
        params.where = { ...params.where, subuser: { neq: true } };
    }

    if (populate) {
        endpoint += '/populated';
        params.populate = { ...params.populate, ...populate };
    }
    return queryBuilder(endpoint, { params });
};

export const getProcessorsQuery = ({ fields, ids, processorId, filterDeleted, filterInactive, names, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'processor', subuser: { neq: true } };

    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (names) {
        params.where = { ...params.where, name: { inq: names } };
    }
    if (processorId) {
        params.where = { ...params.where, id: processorId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterInactive) {
        params.where = { ...params.where, status: { neq: 'Inactive' } };
    }
    return queryBuilder(endpoint, { params });
};

export const getSilosQuery = ({ fields, ids, processorId, filterDeleted }) => {
    const endpoint = '/silos';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getAdminsQuery = ({ fields, filterDeleted, filterSubUsers, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'admin' };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterSubUsers) {
        params.where = { ...params.where, subuser: { eq: false } };
    }
    return queryBuilder(endpoint, { params });
};

export const getHaulingCompaniesQuery = ({ fields, ids, haulingId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'transport', subuser: { eq: false } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (haulingId) {
        params.where = { ...params.where, id: haulingId };
    }
    return queryBuilder(endpoint, { params });
};

export const getRouteSessionsQuery = ({ fields, excludeFields = ['route_points'], manifest_number, start, end, id, ids, driverIds, truckIds, routeIds, haulingId, limit, status, filterDeleted, removeOrder, filterLocked, filterType, route_number, BOL, hint, populate, queryBuilder = buildLoopbackQuery }) => {
    let endpoint = '/route-sessions';
    const params = {};

    if (!removeOrder) {
        params.order = 'created_at DESC';
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    } else if (excludeFields) {
        // fields can't be both included & excluded
        params.fields = buildFieldFilter(excludeFields, false);
    }
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (driverIds) {
        params.where = { ...params.where, created_by: { inq: driverIds } };
    }
    if (truckIds) {
        params.where = { ...params.where, truck_id: { inq: truckIds } };
    }
    if (routeIds) {
        params.where = { ...params.where, route_id: { inq: routeIds } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (manifest_number) {
        params.where = { ...params.where, manifest_number };
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (limit) {
        params.limit = limit;
    }
    if (status) {
        params.where = { ...params.where, status };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterLocked) {
        params.where = { ...params.where, is_locked: { eq: null } };
    }
    if (filterType === 'cream') {
        params.where = { ...params.where, type_of_fluid: { eq: 'cream' } };
    }
    if (filterType === 'milk') {
        params.where = { ...params.where, type_of_fluid: { eq: 'milk' } };
    }
    if (filterType === 'all') {
        params.where = { ...params.where, type_of_fluid: { neq: null } };
    }
    if (hint) {
        params.where = { ...params.where, hint };
    }
    if (route_number) {
        params.where = { ...params.where, route_number: { eq: route_number } };
    }
    if (BOL) {
        params.where = { ...params.where, BOL: { eq: BOL } };
    }
    if (populate) {
        params.populate = populate;
        endpoint += '/populated';
    }
    return queryBuilder(endpoint, { params });
};

export const getAdjustmentsQuery = ({ fields, id, ids, filterDeleted, route_session_id, queryBuilder = buildExpressQuery }) => {
    const endpoint = '/route-sessions/adjustments';
    const params = {};
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (route_session_id) {
        params.where = { ...params.where, route_session_id: { eq: route_session_id } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }

    return queryBuilder(endpoint, { params });
};

export const getRoutesQuery = ({ fields, id, ids, filterDeleted, filterType, haulingId, name, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/routes';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterType === 'cream') {
        params.where = { ...params.where, type_of_fluid: { eq: 'cream' } };
    }
    if (filterType === 'milk') {
        params.where = { ...params.where, type_of_fluid: { eq: 'milk' } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_companies: haulingId };
    }
    if (name) {
        params.where = { ...params.where, name };
    }

    return queryBuilder(endpoint, { params });
};

export const getCommentsQuery = ({ fields, type, itemId, filterDeleted }) => {
    const endpoint = '/comments';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (type && itemId) {
        params.where = { commented_type: type, commented_id: itemId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getTrailersQuery = ({ fields, id, ids, filterDeleted, haulingId, desiredOrder, limit }) => {
    const endpoint = '/trailers';
    const params = {};
    if (desiredOrder) {
        params.order = desiredOrder;
    }
    if (limit) {
        params.limit = limit;
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (id) {
        params.where = { ...params.where, id: { eq: id } };
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getTrucksQuery = ({ fields, ids, filterDeleted, haulingId, truckNumber }) => {
    const endpoint = '/trucks';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_company: haulingId };
    }
    if (truckNumber) {
        params.where = { ...params.where, truck_number: truckNumber };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getSubUsersQuery = ({ fields, ids, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { subuser: { eq: true } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getNonSubUsersQuery = ({ fields, roles, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { subuser: { eq: false }, role: { inq: roles } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getProducerSubUsersQuery = ({ fields, producerId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'producer', subuser: { eq: true } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (producerId) {
        params.where = { ...params.where, producer_id: producerId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getProcessorSubUsersQuery = ({ fields, processorId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'processor', subuser: { eq: true } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getHaulingSubUsersQuery = ({ fields, haulingId, filterDeleted, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    params.where = { role: 'transport', subuser: { eq: true } };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return queryBuilder(endpoint, { params });
};

export const getUsersQuery = ({ fields, ids, filterSubUsers, filterDeleted, role, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/users';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterSubUsers) {
        params.where = { ...params.where, subuser: { eq: false } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (role) {
        params.where = { ...params.where, role };
    }
    return queryBuilder(endpoint, { params });
};

export const getMultisiteAccessListQuery = () => {
    const endpoint = '/link-user';
    const params = { populate: { user_id: ['username'], access_id: ['username'] }, where: { deleted_at: { eq: null } } };
    return buildExpressQuery(endpoint, { params });
};

export const getAdminSharedFilesQuery = () => {
    const endpoint = '/admin-shared-files/shared';
    return buildLoopbackQuery(endpoint, {});
};

export const getSharedFilesQuery = ({ fields, userId, filterDeleted }) => {
    const endpoint = '/shared-files';
    const params = {};
    params.order = 'created_at DESC';
    params.where = { is_folder: false };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (userId) {
        params.where = { ...params.where, owner_id: userId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getSharedFileByIdQuery = ({ fileId }) => {
    const endpoint = `/shared-files/${fileId}`;
    const params = {};
    return buildLoopbackQuery(endpoint, { params });
};

export const addUsersToSharedFileQuery = () => {
    const endpoint = '/shared-files/add-users';
    return buildLoopbackQuery(endpoint, {});
};

export const getAdminReceivedFilesQuery = () => {
    const endpoint = '/admin-shared-files/received';
    return buildLoopbackQuery(endpoint, {});
};

export const getReceivedFilesQuery = ({ fields, userId, filterDeleted, limit }) => {
    const endpoint = '/shared-files';
    const params = {};
    params.order = 'created_at DESC';
    params.where = { is_folder: false };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (userId) {
        params.where = { ...params.where, sentTo_id: { elemMatch: { $eq: userId } } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (limit) {
        params.limit = limit;
    }

    return buildLoopbackQuery(endpoint, { params });
};

export const getAdminFoldersQuery = () => {
    const endpoint = '/admin-shared-files/folders';
    return buildLoopbackQuery(endpoint, {});
};

export const getSharedFoldersQuery = ({ fields, ids, ownerId, filterDeleted }) => {
    const endpoint = '/shared-files';
    const params = {};
    params.where = { is_folder: true };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (ownerId) {
        params.where = { ...params.where, owner_id: ownerId };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getLabReportsQuery = ({ labId, fields, start, end, producerIds, pickupId, processorId, limit, hasBactoScan, sampleBarcode, filterDeleted, filterOutliers, tank, queryBuilder = buildExpressQuery }) => {
    const endpoint = '/lab-reports';
    const params = {};
    params.order = 'date DESC';
    if (labId) {
        params.where = { ...params.where, _id: { eq: labId } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (limit) {
        params.limit = limit;
    }
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (producerIds) {
        params.where = { ...params.where, producer_id: { inq: producerIds } };
    }
    if (pickupId) {
        params.where = { ...params.where, pickup_id: { eq: pickupId } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: { inq: processorId } };
    }
    if (hasBactoScan) {
        params.where = { ...params.where, bacto_scan: { gt: 0 } };
    }
    if (sampleBarcode) {
        params.where = { ...params.where, sample_barcodes: sampleBarcode };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (filterOutliers) {
        params.where = { ...params.where, is_outlier: { eq: false } };
    }
    if (tank) {
        params.where = { ...params.where, tank };
    }
    return queryBuilder(endpoint, { params });
};

export const getMonthlyReportsQuery = ({ fields, start, end, producerId, filterDeleted, filterForPayment, filterForMilkPrice }) => {
    const endpoint = '/producer-monthly-reports';
    const params = {};
    params.order = 'created_at DESC';
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (producerId) {
        params.where = { ...params.where, or: [{ producer_id: producerId }, { producer_id: { exists: false } }] };
    } // producer_id === null represents a report for all producers
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (filterForPayment) {
        params.where = { ...params.where, type: { eq: 'Payment' } };
    }
    if (filterForMilkPrice) {
        params.where = { ...params.where, type: { eq: 'Milk Price' } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getLastPickupsQuery = () => {
    const endpoint = '/users/latest-pickups';
    const params = {};

    return buildLoopbackQuery(endpoint, { params });
};

export const getLatestProductionQuery = () => {
    const endpoint = '/users/latest-production';
    const params = {};

    return buildLoopbackQuery(endpoint, { params });
};

export const getMonthlyQuotaTotalsQuery = ({ fields, start, end, filterDeleted }) => {
    const endpoint = '/monthly-quota-totals';
    const params = {};
    params.order = 'created_at DESC';
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getNotificationsQuery = ({ fields, userId, limit }) => {
    const endpoint = '/notifications';
    const params = {};
    params.order = 'created_at DESC';
    if (limit) {
        params.limit = limit;
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (userId) {
        params.where = { ...params.where, users: { elemMatch: { $eq: userId } } };
    }
    params.where = { ...params.where, deleted_at: { eq: null } };
    return buildLoopbackQuery(endpoint, { params });
};

export const getProductionQuery = ({ fields, start, end, limit, producerId, tank, filterDeleted }) => {
    const endpoint = '/productions';
    const params = {};
    params.order = 'created_at DESC';
    params.where = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (limit) {
        params.limit = limit;
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (producerId) {
        params.where = { ...params.where, producer_id: producerId };
    }
    if (tank) {
        params.where = { ...params.where, tank };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getMultisiteAccessQuery = ({ id }) => {
    const endpoint = '/multisite-accesses';
    const params = {};
    params.order = 'created_at DESC';
    if (id) {
        params.where = { ...params.where, user_id: id };
    }
    params.where = { ...params.where, deleted_at: null };
    return buildLoopbackQuery(endpoint, { params });
};

export const getScheduleQuery = ({ date }) => {
    const endpoint = '/schedule';
    const params = {};
    params.order = 'created_at DESC';
    params.limit = 1;
    if (date) {
        params.where = { ...params.where, date };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getScheduleItemsQuery = ({ start, end, demand, haulingId, processorId }) => {
    const endpoint = `/schedule-items/${demand ? 'demand' : 'supply'}`;
    const params = {};
    params.order = 'id ASC';
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (processorId) {
        params.where = { ...params.where, or: [{ processor_id: processorId }, { processor_id: { exists: false } }] };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getScheduleMilkSupplyItemQuery = ({ start, end, haulingId, processorId, populate, fields, ids, filterDeleted, queryBuilder = buildExpressQuery, filter }) => {
    const endpoint = '/schedule-supply-item';
    const params = {};
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (start && end) {
        params.where = { ...params.where, start_date: { between: [start, end] } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (populate) {
        params.populate = populate;
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    // TODO this is a quick initial stab at introducing mongo filter expression support
    // this should be refactored to be less gross but it works for now
    // this basically can replace everything that gets appended to the where clause
    if (filter) {
        params.filter = filter;
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    return queryBuilder(endpoint, { params });
};

export const getSchedulingStatsQuery = ({ processorIds, start, end }) => {
    const endpoint = '/schedule-supply-item/stats';
    const params = {};
    if (processorIds) {
        params.where = { ...params.where, processor_id: { inq: processorIds } };
    }
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getDriverStatsQuery = ({ haulingIds, start, end }) => {
    const endpoint = '/users/driver-stats';
    const params = {};
    if (haulingIds) {
        params.where = { ...params.where, id: { inq: haulingIds } };
    }
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    params.where = { ...params.where, role: 'transport' };
    return buildExpressQuery(endpoint, { params });
};

/**
 * @param {Object} data - The input data for the schedule.
 * @param {string} data.start - The start date/time for the schedule.
 * @param {string} data.end - The end date/time for the schedule.
 * @param {string[]} data.haulingIds - List of hauling IDs.
 * @param {string[]} data.processorIds - List of processor IDs.
 * @param {string} data.scheduleType - The type of schedule.
 */
export const getSendScheduleQuery = ({ start, end, haulingIds, processorIds, scheduleType }) => {
    const endpoint = '/schedule-supply-item/schedule-complete';
    const params = {};
    // params.order = 'id ASC';
    if (scheduleType) {
        params.where = { ...params.where, schedule_type: scheduleType };
    }
    if (start) {
        params.where = { ...params.where, start_date: start };
    }
    if (end) {
        params.where = { ...params.where, end_date: end };
    }
    if (haulingIds) {
        params.where = { ...params.where, hauler_ids: { inq: haulingIds } };
    }
    if (processorIds) {
        params.where = { ...params.where, processor_ids: { inq: processorIds } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getScheduleFluidItemQuery = ({ start, end, processorId, supplyProcessorId, haulingId, id, status, populate, filterByEndDate = false }) => {
    const endpoint = '/schedule-fluid-item';
    const params = {};
    let filterDate = filterByEndDate ? 'date' : 'start_date';

    const isUsingPickupDate = getRole() === 'admin' || supplyProcessorId === getId() || getRole() === 'processor';

    if (filterDate === 'start_date' && isUsingPickupDate) {
        filterDate = 'pickup_ready_time';
    }

    if (start && end) {
        params.where = { ...params.where, [filterDate]: { between: [start, end] } };
    }
    if (processorId) {
        params.where = { ...params.where, destination_processor_id: processorId };
    }
    if (supplyProcessorId) {
        params.where = { ...params.where, origin_processor_id: supplyProcessorId };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    if (status) {
        params.where = { ...params.where, status };
    }
    if (populate) {
        params.populate = populate;
    }
    return buildExpressQuery(endpoint, { params });
};

export const getLTNumberQuery = ({ date }) => {
    const lt_date = date ? moment(date).toISOString() : null;
    const endpoint = `/lt-number${lt_date ? `/${lt_date}` : ''}`;
    return buildExpressQuery(endpoint, {});
};

export const createFluidItemQuery = ({ model }) => {
    const endpoint = '/schedule-fluid-item';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const changeFluidItemDateQuery = ({ id, date }) => {
    const endpoint = `/schedule-fluid-item/changeItemDate/${id}/${date}`;
    return buildExpressQuery(endpoint, {});
};

export const changeSupplyItemDateQuery = ({ id, date }) => {
    const endpoint = `/schedule-supply-item/changeItemDate/${id}/${date}`;
    return buildExpressQuery(endpoint, {});
};

export const editFluidItemQuery = ({ key, model }) => {
    const endpoint = `/schedule-fluid-item/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const copyFluidItemQuery = ({ id, dates }) => {
    const endpoint = `/schedule-fluid-item/copy-item/${id}`;
    const body = { dates };
    return buildExpressQuery(endpoint, { body });
};

export const deleteFluidItemQuery = ({ key, date }) => {
    const endpoint = `/schedule-fluid-item/${date ? `${date}/` : ''}${key}`;
    return buildExpressQuery(endpoint, {});
};

export const getScheduleMilkDemandItemQuery = ({ start, end, processorId }) => {
    const endpoint = '/schedule-demand-item';
    const params = {};
    // params.order = 'id ASC';
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getCloseDayQuery = ({ date }) => {
    const endpoint = '/locked-days';
    const params = {
        where: {
            is_locked: { neq: null },
        },
    };

    if (date) {
        params.where = { ...params.where, date: { eq: moment(date).format() } };
    }

    return buildExpressQuery(endpoint, { params });
};

export const getCloseMonthQuery = ({ startDate, endDate }) => {
    const endpoint = '/locked-days';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, startDate: { eq: startDate }, endDate: { eq: endDate } };
    } else {
        params.where = { ...params.where, is_locked: { eq: 'closedViaPickup' } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getClosePeriodQuery = ({ startDate, endDate }) => {
    const endpoint = '/locked-days';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, startDate: { eq: startDate }, endDate: { eq: endDate } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getCloseMonthUnmetCriteriaQuery = ({ startDate, endDate }) => {
    const endpoint = '/locked-days/unmet-criteria';
    const params = {};
    params.where = { ...params.where, startDate, endDate };
    return buildExpressQuery(endpoint, { params });
};

export const getMonthlyAveragesQuery = ({ fields, userIds, start, end, allUsers = false, routeRegions }) => {
    // { inq: ids }
    const endpoint = '/monthly-averages';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.order = 'date DESC';
    params.where = { ...params.where, user_id: userIds ? { inq: userIds } : allUsers ? { neq: null } : null };
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (!!routeRegions) {
        params.where = { ...params.where, route_region: { inq: routeRegions } };
    } else {
        params.where = { ...params.where, or: [{ route_region: null }, { route_region: { exists: false } }] };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getProcessorMonthlyAveragesQuery = ({ fields, userIds, start, end, allUsers = false }) => {
    const endpoint = '/processor-monthly-averages';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.order = 'date DESC';
    params.where = { ...params.where, user_id: userIds ? { inq: userIds } : allUsers ? { neq: null } : null };
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getWashesQuery = ({ start, end, fields, ids, filterDeleted, haulingId }) => {
    const endpoint = '/washes';
    const params = {};
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauling_id: haulingId };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getWashFacilitiesQuery = ({ fields, ids, filterDeleted }) => {
    const endpoint = '/wash-facilities';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getBaseTransfersQuery = ({ start, end, producerId }) => {
    const endpoint = '/base-transfers';
    const params = { deleted_at: { eq: null } };

    params.order = 'start_date DESC';

    if (start && end) {
        params.where = { ...params.where, start_date: { between: [start, end] } };
    }
    if (producerId) {
        params.where = { ...params.where, or: [{ producer_to: producerId }, { producer_from: producerId }] };
    }
    params.populate = {
        producer_to: ['name', 'license_number', 'state_number'],
        producer_from: ['name', 'license_number', 'state_number'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const getDeferralsQuery = (startDate, endDate) => {
    const endpoint = '/deferrals';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, deferred_payment_date: { between: [startDate, endDate] } };
    }
    params.populate = {
        producer: ['name', 'license_number', 'bank_name', 'payment_method', 'account_number'],
    };

    return buildExpressQuery(endpoint, { params });
};

export const getDropoffSampleQuery = ({ fields, start, end, processorId, filterDeleted, populate }) => {
    const endpoint = '/dropoff-samples';
    const params = {};
    params.order = 'date DESC';
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (processorId) {
        params.where = { ...params.where, processor_id: processorId };
    }
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (populate) {
        params.populate = { ...params.populate, ...populate };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getDeferralReportsQuery = ({ startDate, endDate, producerId }) => {
    const endpoint = '/deferrals';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, deferred_payment_date: { between: [startDate, endDate] } };
    }
    if (producerId) {
        params.where = { ...params.where, producer: producerId };
    }
    params.populate = {
        producer: ['full_name', 'name', 'license_number'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const getPayReportsQuery = ({ startDate, endDate, region, producerId, producerBusinessId }) => {
    const reportSetName = ReportNamesByRegion?.[region]?.[PaymentType.settlement]?.reportSetName;
    const endpoint = `/monthly-pay-details/complete-reports/${reportSetName}`;
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, startDate: { eq: startDate }, endDate: { eq: endDate } };
    }
    if (producerId) {
        params.where = { ...params.where, producer: { eq: producerId } };
    }
    if (producerBusinessId) {
        params.where = { ...params.where, producerBusinessId: { eq: producerBusinessId } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const unapproveAllProducerPayReportsQuery = ({ date, paymentType, federalOrder }) => {
    const endpoint = '/monthly-pay-details/unapprove-pay-reports';
    const body = {
        date,
        paymentType,
        federalOrder,
    };
    return buildExpressQuery(endpoint, { body });
};

export const unapproveAllProducerBusinessPayReportsQuery = ({ startDate, endDate, paymentType, paymentFrequency }) => {
    const endpoint = '/payment-reports/unapprove-producer-business-reports';
    const body = {
        start_date: startDate,
        end_date: endDate,
        payment_type: paymentType,
        payment_frequency: paymentFrequency,
    };
    return buildExpressQuery(endpoint, { body });
};

export const getScheduleConfigQuery = ({ fields }) => {
    const endpoint = '/schedule-config';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    return buildLoopbackQuery(endpoint, { params });
};

export const getAdhocAdvanceReportsQuery = ({ startDate, endDate }) => {
    const endpoint = '/payment-reports/adhoc-advance';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: startDate, end_date: endDate };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getAdhocAdvanceReportQuery = ({ id }) => {
    const endpoint = `/payment-reports/adhoc-advance/${id}`;
    const params = {};
    return buildExpressQuery(endpoint, { params });
};

export const getApprovedPaymentReportsQuery = ({ startDate, endDate, producerId }) => {
    const endpoint = '/payment-reports/';
    const params = {
        where: {
            report_status: 'approved',
        },
    };
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: { between: [startDate, endDate] } };
    }
    if (producerId) {
        params.where = { ...params.where, payee_id: producerId };
        params.populate = {
            payee_id: ['id', 'name', 'full_name', 'license_number'],
        };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getAvailableProducerBusinessPayReportsQuery = ({ startDate, endDate }) => {
    const endpoint = '/payment-reports/available-producer-business-reports';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: { eq: startDate }, end_date: { eq: endDate } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getMilkPriceQuery = ({ date, fields }) => {
    const endpoint = `/monthly-pay-details/producers/${date}`;
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    return buildExpressQuery(endpoint, { params });
};

export const getMilkPricesInDateRangeQuery = ({ startDate, endDate, id, fields }) => {
    const endpoint = '/milk-price';
    const params = {};
    if (startDate) {
        params.where = { ...params.where, end_date: { gte: startDate } };
    }
    if (endDate) {
        params.where = { ...params.where, start_date: { lte: endDate } };
    }
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    return buildExpressQuery(endpoint, { params });
};

export const getWeeklyAdvanceProducerPayReportsQuery = ({ startDate, endDate, fields }) => {
    const endpoint = '/payment-reports/weekly-advance';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: { eq: startDate }, end_date: { eq: endDate } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    return buildExpressQuery(endpoint, { params });
};

export const getLoansQuery = ({ startDate, endDate, id, ids }) => {
    const endpoint = `/payment-line-item-config/loans/${ids ? '' : 'get-loans'}`;
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: { eq: startDate }, end_date: { eq: endDate } };
    }
    if (ids) {
        params.where = { ...params.where, _id: { inq: ids } };
    }
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getLoanDetailsQuery = ({ id, projectPayments }) => {
    const endpoint = `/payment-line-item-config/loans/get-loan-details/${id}`;
    const params = {};
    if (projectPayments != null) {
        params.where = { projectPayments: { eq: projectPayments } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getLoanOriginalAmortizationDetailsQuery = ({ id }) => {
    const endpoint = `/payment-line-item-config/loans/get-original-amortization-loan-details/${id}`;
    return buildExpressQuery(endpoint, {});
};

export const generateLoanSummaryQuery = ({ monetaryValue, interestRate, period, paymentFrequency, startDate }) => {
    const endpoint = '/payment-line-item-config/loans/generate-loan-summary';
    const params = {};
    if (monetaryValue && interestRate && period && startDate && paymentFrequency) {
        params.where = { ...params.where, monetary_value: { eq: monetaryValue }, interest_rate: { eq: interestRate }, period: { eq: period }, start_date: { eq: startDate }, payment_frequency: { eq: paymentFrequency } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const createLoanQuery = ({ model }) => {
    const endpoint = '/payment-line-item-config/loans';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editLoanQuery = ({ model, id }) => {
    const endpoint = `/payment-line-item-config/loans/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const deleteLoanQuery = ({ id }) => {
    const endpoint = `/payment-line-item-config/loans/${id}`;
    return buildExpressQuery(endpoint, {});
};

export const createLoanPaymentQuery = ({ model }) => {
    const endpoint = '/payment-line-items/loans';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editLoanPaymentQuery = ({ model, id }) => {
    const endpoint = `/payment-line-items/loans/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const deleteLoanPaymentQuery = ({ id }) => {
    const endpoint = `/payment-line-items/loans/${id}`;
    return buildExpressQuery(endpoint, {});
};

export const getLoanPaymentsQuery = ({ startDate, endDate, payerPaymentReportId, id }) => {
    const endpoint = '/payment-line-items/loans';
    const params = {};
    params.deleted_at = null;
    if (startDate) {
        params.where = { ...params.where, payment_date: { gte: startDate } };
    }
    if (endDate) {
        params.where = { ...params.where, payment_date: { lte: endDate } };
    }
    if (startDate && endDate) {
        params.where = { ...params.where, payment_date: { between: [startDate, endDate] } };
    }
    if (payerPaymentReportId) {
        params.where = { ...params.where, payer_payment_report_id: { eq: payerPaymentReportId } };
    }
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    return buildExpressQuery(endpoint, { params });
};

export const deleteLineItemConfigQuery = ({ id }) => {
    const endpoint = `/payment-line-item-config/${id}`;
    return buildExpressQuery(endpoint, {});
};

export const createPaymentLineItemConfigQuery = ({ model }) => {
    const endpoint = '/payment-line-item-config';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editPaymentLineItemConfigQuery = ({ model, id }) => {
    const endpoint = `/payment-line-item-config/${id}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const getMerchandiseQuery = ({ id, fields, queryBuilder = buildExpressQuery }) => {
    const endpoint = '/merchandise';
    const params = {};
    if (id) {
        params.where = { ...params.where, _id: { eq: id } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    return queryBuilder(endpoint, { params });
};

export const getMerchandisePurchaseQuery = ({ id, fields, startDate, endDate, queryBuilder = buildExpressQuery }) => {
    const endpoint = '/merchandise-purchase';
    const params = {};
    if (id) {
        params.where = { ...params.where, _id: { eq: id } };
    }
    if (startDate && endDate) {
        params.where = { ...params.where, purchased_date: { between: [startDate, endDate] } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.populate = {
        merchandise_item: ['id', 'product_number', 'product_name', 'product_type'],
        purchased_by: ['id', 'name', 'license_number', 'hauler_number'],
    };
    return queryBuilder(endpoint, { params });
};

export const getAssigneeQuery = ({ ids, fields }) => {
    const endpoint = '/assignees';
    const params = {};
    if (ids) {
        params.where = { ...params.where, id: { inq: ids } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.populate = {
        producer: ['name', 'license_number'],
    };

    return buildExpressQuery(endpoint, { params });
};

export const getAssignmentQuery = ({ month, year, filters }) => {
    const endpoint = '/assignments';
    const params = {};
    if (month && year) {
        params.where = { ...params.where, date: `${year}-${month}` };
    }
    if (filters != null) {
        params.where = { ...params.where, ...filters };
    }
    params.populate = {
        assignee: ['assignee_name', 'assignee_number'],
        producer: ['name', 'license_number'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const getAssignmentConfigurationQuery = ({ id }) => {
    const endpoint = '/assignment-configuration';
    const params = {};
    if (id) {
        params.where = { ...params.where, _id: id };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getUnreadSharedFilesCountQuery = ({ id }) => {
    const endpoint = '/shared-files/count';
    const params = {};
    if (id) {
        params.sentTo_id = { elemMatch: { $eq: id } };
        params.read_status = { not: { $elemMatch: { $eq: id } } };
        params.deleted_at = null;
    }
    // return buildLoopbackQuery(endpoint, params);
    return `${getAPIHost() + endpoint}?where=${JSON.stringify(params)}`;
};

export const getUnreadMonthlyReportsCountQuery = ({ id }) => {
    const endpoint = '/producer-monthly-reports/count';
    const params = {};
    if (id) {
        params.or = [
            { producer_id: { exists: false }, read_status: { not: { $elemMatch: { $eq: id } } } },
            { producer_id: { eq: id }, read_status: { not: { $elemMatch: { $eq: id } } } },
        ];
    }
    params.deleted_at = null;
    // return buildLoopbackQuery(endpoint, params);
    return `${getAPIHost() + endpoint}?where=${JSON.stringify(params)}`;
};

export const getUnreadNotificationsCountQuery = ({ id }) => {
    const endpoint = '/notifications/count';
    const params = {};
    if (id) {
        params.users = { elemMatch: { $eq: id } };
        params.read_status = { not: { $elemMatch: { $eq: id } } };
    }
    params.deleted_at = null;
    // return buildLoopbackQuery(endpoint, params);
    return `${getAPIHost() + endpoint}?where=${JSON.stringify(params)}`;
};

export const getCustomNotificationDefinitionsQuery = ({ user }) => {
    const endpoint = '/custom-notification-definitions';
    const params = {};

    if (user) {
        params.where = { ...params.where, user };
    }

    return buildExpressQuery(endpoint, { params });
};

export const createUserQuery = ({ model }) => {
    const endpoint = '/users';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createMultisiteAccessQuery = ({ model }) => {
    const endpoint = '/link-user';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createProducerBusinessQuery = ({ model }) => {
    const endpoint = '/users';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createBaseTransferQuery = ({ model }) => {
    const endpoint = '/base-transfers';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createWashQuery = ({ model }) => {
    const endpoint = '/washes';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createWashFacilityQuery = ({ model }) => {
    const endpoint = '/wash-facilities';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createMonthlyPayDetailsMilkPriceQuery = ({ model, date }) => {
    const endpoint = `/monthly-pay-details/producers/${date}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createCloseDayQuery = ({ is_locked, date }) => {
    const endpoint = '/locked-days';
    const body = { is_locked, date };
    return buildExpressQuery(endpoint, { body });
};

export const createCloseMonthQuery = ({ is_locked, startDate, endDate }) => {
    const endpoint = '/locked-days';
    const body = { is_locked, startDate, endDate };
    return buildExpressQuery(endpoint, { body });
};

export const createClosePeriodQuery = ({ is_locked, startDate, endDate }) => {
    const endpoint = '/locked-days';
    const body = { is_locked, startDate, endDate };
    return buildExpressQuery(endpoint, { body });
};

export const createProcessFluidRouteSession = ({ model }) => {
    const endpoint = '/processed-fluid/route-sessions';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createProcessedFluidAdjustmentsQuery = ({ model }) => {
    const endpoint = '/processed-fluid/adjustments';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editProcessedFluidAdjustmentsQuery = ({ model, id }) => {
    const endpoint = `/processed-fluid/adjustments/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createRawMilkAdjustmentsQuery = ({ model }) => {
    const endpoint = '/route-sessions/adjustments';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editRawMilkAdjustmentsQuery = ({ model, id }) => {
    const endpoint = `/route-sessions/adjustments/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createRawMilkAdjustmentsForProcessorOnDateQuery = ({ model }) => {
    const endpoint = '/route-sessions/adjustments/create-adjustments-on-date';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createPickupQuery = ({ model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/pickups';
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const createDropoffQuery = ({ model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/dropoffs';
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const createLabReportQuery = ({ model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/lab-reports';
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const createDropoffSampleQuery = ({ model }) => {
    const endpoint = '/dropoff-samples';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createCommentQuery = ({ model }) => {
    const endpoint = '/comments';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createTrailerQuery = ({ model }) => {
    const endpoint = '/trailers';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createTruckQuery = ({ model }) => {
    const endpoint = '/trucks';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createRouteQuery = ({ model }) => {
    const endpoint = '/routes';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createSiloQuery = ({ model }) => {
    const endpoint = '/silos';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createMerchandiseQuery = ({ model }) => {
    const endpoint = '/merchandise';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createMerchandisePurchaseQuery = ({ model }) => {
    const endpoint = '/merchandise-purchase';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createAssigneeQuery = ({ model }) => {
    const endpoint = '/assignees';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createAssignmentQuery = ({ model }) => {
    const endpoint = '/assignments';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createAssignmentConfigurationQuery = ({ model }) => {
    const endpoint = '/assignment-configuration';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const deleteAssignmentConfigurationQuery = (key) => {
    const endpoint = `/assignment-configuration/${key}`;
    return buildExpressQuery(endpoint, {});
};

export const createRecurringAssignmentQuery = ({ model }) => {
    const endpoint = '/assignments/recurring-assignment';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createDeferralQuery = ({ model }) => {
    const endpoint = '/deferrals';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createFolderQuery = ({ model }) => {
    const endpoint = '/shared-files';
    const body = { ...model, is_folder: true };
    return buildLoopbackQuery(endpoint, { body });
};

export const createRouteSessionQuery = ({ model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/route-sessions';
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const createScheduleItemQuery = ({ model }) => {
    const endpoint = '/schedule-items';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const createScheduleSupplyItemQuery = ({ model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = '/schedule-supply-item';
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const createPayReportPaymentStatusQuery = ({ model }) => {
    const endpoint = '/payment-status/create-and-update-pay-report-payment-status';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editUserQuery = ({ key, model, queryBuilder }) => {
    const endpoint = `/users/${key}`;
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const editProducerBusinessQuery = ({ key, model }) => {
    const endpoint = `/users/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editWashQuery = ({ key, model }) => {
    const endpoint = `/washes/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editWashFacilityQuery = ({ key, model }) => {
    const endpoint = `/wash-facilities/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editBaseTransferQuery = ({ key, model }) => {
    const endpoint = `/base-transfers/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editMerchandiseQuery = ({ key, model }) => {
    const endpoint = `/merchandise/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editMerchandisePurchaseQuery = ({ key, model }) => {
    const endpoint = `/merchandise-purchase/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editAssigneeQuery = ({ key, model }) => {
    const endpoint = `/assignees/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editPaymentStatusQuery = ({ key, model }) => {
    const endpoint = `/payment-status/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editAssignmentQuery = ({ key, model }) => {
    const endpoint = `/assignments/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editAssignmentConfigurationQuery = ({ key, model }) => {
    const endpoint = `/assignment-configuration/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editRecurringAssignmentQuery = ({ model, key }) => {
    const endpoint = `/assignments/recurring-assignment/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editDeferralQuery = ({ key, model }) => {
    const endpoint = `/deferrals/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editDropoffSampleQuery = ({ key, model }) => {
    const endpoint = `/dropoff-samples/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editPickupQuery = ({ key, model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = `/pickups/${key}`;
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const deletePickupQuery = ({ key }) => {
    const endpoint = `/pickups/${key}`;
    return buildLoopbackQuery(endpoint, {});
};

export const editDropoffQuery = ({ key, model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = `/dropoffs/${key}`;
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const editTrailerQuery = ({ key, model }) => {
    const endpoint = `/trailers/${key}`;
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const editTruckQuery = ({ key, model }) => {
    const endpoint = `/trucks/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editCustomNotificationDefinitionQuery = ({ key, model }) => {
    const endpoint = `/custom-notification-definitions/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editRouteQuery = ({ key, model }) => {
    const endpoint = `/routes/${key}`;
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const editSiloQuery = ({ key, model }) => {
    const endpoint = `/silos/${key}`;
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const editLabReportQuery = ({ key, model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = `/lab-reports/${key}`;
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const editCommentQuery = ({ key, model }) => {
    const endpoint = `/comments/${key}`;
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const editRouteSessionQuery = ({ key, model, queryBuilder = buildLoopbackQuery }) => {
    const endpoint = `/route-sessions/${key}`;
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const editScheduleQuery = ({ date, schedule }) => {
    const endpoint = `/schedule/${date}`;
    const body = schedule;
    return buildLoopbackQuery(endpoint, { body });
};

export const editScheduleItemQuery = ({ key, model }) => {
    const endpoint = `/schedule-items/${key}`;
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const editScheduleSupplyItemQuery = ({ key, model, recur }) => {
    const endpoint = `/schedule-supply-item/${key}/${recur}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const bulkEditScheduleSupplyItemQuery = ({ keys, model }) => {
    const endpoint = '/schedule-supply-item/bulk';
    const body = { ids: keys, ...model };
    return buildExpressQuery(endpoint, { body });
};

export const bulkEditScheduleFluidItemQuery = ({ keys, model }) => {
    const endpoint = '/schedule-fluid-item/bulk';
    const body = { ids: keys, ...model };
    return buildExpressQuery(endpoint, { body });
};

export const bulkDeleteScheduleFluidItemQuery = ({ keys }) => {
    const endpoint = '/schedule-fluid-item/bulk';
    const body = { ids: keys };
    return buildExpressQuery(endpoint, { body });
};

export const bulkEditPickupsQuery = ({ keys, model }) => {
    const endpoint = '/pickups/update-fields-on-many-pickups';
    const body = { ids: keys, ...model };
    return buildExpressQuery(endpoint, { body });
};

export const bulkDeleteScheduleSupplyItemQuery = ({ keys }) => {
    const endpoint = '/schedule-supply-item/bulk';
    const body = { ids: keys };
    return buildExpressQuery(endpoint, { body });
};

export const editScheduleDemandItemQuery = ({ key, model }) => {
    const endpoint = `/schedule-demand-item/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editScheduleConfigQuery = ({ model }) => {
    const endpoint = '/schedule-config';
    const body = model;
    return buildLoopbackQuery(endpoint, { body });
};

export const deleteScheduleItemQuery = ({ key, date }) => {
    const endpoint = `/schedule-items/${date ? `${date}/` : ''}${key}`;
    return buildLoopbackQuery(endpoint, {});
};

export const deleteScheduleSupplyItemQuery = ({ key, recur }) => {
    const endpoint = `/schedule-supply-item/${key}/${recur}`;
    return buildExpressQuery(endpoint, {});
};

export const copyScheduleFluidItemsQuery = ({ model }) => {
    const endpoint = '/schedule-fluid-item/copy';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const copyScheduleSupplyItemsQuery = ({ model, queryBuilder = buildLoopbackQuery, endpoint = '/schedule-fluid-item/copy' }) => {
    const body = model;
    return queryBuilder(endpoint, { body });
};

export const requestScheduleItemLoadsQuery = ({ key, loads }) => {
    const endpoint = '/request-supply-item-loads/';
    const body = { id: key, loads };
    return buildLoopbackQuery(endpoint, { body });
};

export const requestScheduleItemsAmountQuery = ({ key }) => {
    const endpoint = '/request-supply-items-amount/';
    const body = { id: key };
    return buildLoopbackQuery(endpoint, { body });
};

export const removeScheduleItemsQuery = ({ ids }) => {
    const endpoint = '/remove-supply-items/';
    const body = { ids };
    return buildLoopbackQuery(endpoint, { body });
};

export const deleteCustomNotificationDefinitionsQuery = ({ id }) => {
    const endpoint = `/custom-notification-definitions/${id}`;
    return buildExpressQuery(endpoint, {});
};

export const getProducerDailyAverageProductionQuery = ({ id, start, end }) => {
    const endpoint = `/pickups/producer-daily-average/${id}/${moment(start).format('YYYY-MM-DD')}/${moment(end).format('YYYY-MM-DD')}`;
    return buildExpressQuery(endpoint, {});
};

export const getProducerMonthlyTotalProductionQuery = ({ id, start, end }) => {
    const endpoint = `/pickups/producer-total-production/${id}/${moment(start).format('YYYY-MM-DD')}/${moment(end).format('YYYY-MM-DD')}`;
    return buildExpressQuery(endpoint, {});
};

export const getDropoffStatisticsQuery = ({ start, end, haulingId }) => {
    const endpoint = '/dropoffs/summary-statistcs/';
    const params = {};
    if (haulingId) {
        params.where = { ...params.where, haulingId };
    }
    if (start && end) {
        params.where = { ...params.where, created_at: { between: [start, end] } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getCustomTableConfigurationQuery = ({ tableName }) => {
    const endpoint = `/custom-table-configuration/${tableName}`;
    return buildExpressQuery(endpoint, {});
};

export const getAdminCustomTableConfigurationQuery = ({ tableName }) => {
    const endpoint = `/custom-table-configuration/${tableName}/admin`;
    return buildExpressQuery(endpoint, {});
};

export const saveCustomTableConfigurationQuery = ({ configuration }) => {
    const endpoint = '/custom-table-configuration';
    const body = { ...configuration };
    return buildExpressQuery(endpoint, { body });
};

export const resetCustomTableConfigurationQuery = ({ tableName, mode }) => {
    const endpoint = `/custom-table-configuration/${tableName}/reset`;
    const body = { resetMode: mode };
    return buildExpressQuery(endpoint, { body });
};

export const getSplitProducerQuery = ({ fields }) => {
    const endpoint = '/split-producer-agreement';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.populate = {
        assignee: ['assignee_name', '_id'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const createSplitProducerQuery = ({ model }) => {
    const endpoint = '/split-producer-agreement';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editSplitProducerQuery = ({ key, model }) => {
    const endpoint = `/split-producer-agreement/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const getHaulingChargeQuery = ({ fields, start, end, filterDeleted, haulingId, producerId }) => {
    const endpoint = '/hauling-charge';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (start && end) {
        params.where = { ...params.where, date: { between: [start, end] } };
    }
    if (haulingId) {
        params.where = { ...params.where, 'hauler.id': { eq: haulingId }, approved_for_hauler: { eq: true } };
    }
    if (producerId) {
        params.where = { ...params.where, 'producer.id': { eq: producerId }, approved_for_produer: { eq: true }, 'hauling_rate_details.paid_by_producer': { eq: true } };
    }
    params.populate = {
        route_id: ['name', '_id'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const getGroupedUsers = () => {
    const endpoint = '/users/groupByRole';
    return buildExpressQuery(endpoint, {});
};

export const getUserGroupQuery = ({ populate }) => {
    const endpoint = '/user-groups';
    const params = {};
    if (populate) params.populate = populate;
    return buildExpressQuery(endpoint, { params });
};

export const createUserGroupQuery = ({ model }) => {
    const endpoint = '/user-groups';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editUserGroupQuery = ({ key, model }) => {
    const endpoint = `/user-groups/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const deleteUserGroupQuery = ({ key }) => {
    const endpoint = `/user-groups/${key}`;
    return buildExpressQuery(endpoint, {});
};

export const getPaymentStatusQuery = ({ fields, start, end, filters, filterDeleted }) => {
    const endpoint = '/payment-status';
    const params = {};
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }
    if (start && end) {
        params.where = { ...params.where, issue_date: { between: [start, end] } };
    }
    if (filters) {
        params.where = { ...params.where, ...filters };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getNextAvailableCheckNumberQuery = () => {
    const endpoint = '/payment-status';
    const params = {
        where: { payment_method: { eq: 'check' }, deleted_at: { eq: null } },
        order: 'payment_number DESC',
        limit: 1,
    };
    return buildExpressQuery(endpoint, { params });
};

export const getApprovedPaymentStatusByPaymentNumberQuery = ({ paymentNumber }) => {
    const endpoint = '/payment-status';
    const params = {
        where: { payment_approved: { eq: true }, payment_method: { eq: 'check' }, deleted_at: { eq: null }, payment_number: { eq: paymentNumber } },
        limit: 1,
    };
    return buildExpressQuery(endpoint, { params });
};

export const getHaulingContractByDateRangeQuery = (fields, startDate, endDate, haulingId, produerId, supplemental = false) => {
    const endpoint = '/hauling-contract/date-range';
    const params = {
        where: { deleted_at: { eq: null } },
    };
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (startDate && endDate) {
        params.where = { ...params.where, start_date: { eq: startDate }, end_date: { eq: endDate } };
    }
    if (supplemental) {
        params.where = { ...params.where, contract_type: { eq: HaulingContractType.HaulerProcessorRouteContract } };
    } else {
        params.where = { ...params.where, contract_type: { neq: HaulingContractType.HaulerProcessorRouteContract } };
    }
    if (haulingId) {
        params.where = { ...params.where, hauler_id: { eq: haulingId } };
    }
    if (produerId) {
        params.where = { ...params.where, producer_id: { eq: produerId } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const createHaulingContractQuery = ({ model }) => {
    const endpoint = '/hauling-contract';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const createEffectiveHaulerRateQuery = ({ model }) => {
    const endpoint = `/hauler-rate/${model.hauler_rate_id}/effective-hauler-rate/`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const updateEffectiveHaulerRateQuery = ({ model, date }) => {
    const endpoint = `/hauler-rate/${model.hauler_rate_id}/effective-hauler-rate/${date}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const addSampleBarcodeBatchQuery = ({ model }) => {
    const endpoint = '/sample-barcode-batches';
    return buildExpressQuery(endpoint, { body: model });
};

export const editSampleBarcodeBatchQuery = (key, model) => {
    const endpoint = `/sample-barcode-batches/${key}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const getSampleBarcodeBatchQuery = ({ id, startDate, endDate }) => {
    const endpoint = '/sample-barcode-batches';
    const params = {};
    if (id) {
        params.where = { ...params.where, _id: { eq: id } };
    }
    if (startDate && endDate) {
        params.where = { ...params.where, created_at: { between: [startDate, endDate] } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getBatchInfoForBarcodeQuery = ({ barcode, month, year }) => {
    const endpoint = '/sample-barcode-batches/barcode-in-batch-status';
    const params = {
        where: { barcode, month, year },
    };
    return buildExpressQuery(endpoint, { params });
};

export const getQualityStatusAndOverrideQuery = ({ date }) => {
    const endpoint = '/users/quality-status-and-override';
    const params = {};
    params.where = { date };
    return buildExpressQuery(endpoint, { params });
};

export const getBarnScoresQuery = ({ startDate, endDate }) => {
    const endpoint = '/barn-scores';
    const params = {};
    if (startDate && endDate) {
        params.where = { ...params.where, date: { between: [startDate, endDate] } };
    }
    params.populate = {
        producer_id: ['name', 'license_number', 'number_of_tanks'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const addBarnScoreQuery = (model) => {
    const endpoint = '/barn-scores';
    return buildExpressQuery(endpoint, { body: model });
};

export const editBarnScoreQuery = (id, model) => {
    const endpoint = `/barn-scores/${id}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const updateProducerOverrideQuery = (body) => {
    const endpoint = '/v1/labs/overrides';
    return buildServerlessLabQuery(endpoint, { body });
};

export const editHaulingContractQuery = ({ key, model }) => {
    const endpoint = `/hauling-contract/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const getHaulersWithCompletePayReportsQuery = ({ report_month }) => {
    const endpoint = '/hauler-monthly-pay/complete-pay-reports';
    const params = {};
    params.where = { report_month };
    return buildExpressQuery(endpoint, { params });
};

export const getHaulerMonthlyPayQuery = ({ hauler_id, hauler_ids, startDate, endDate }) => {
    const endpoint = '/hauler-monthly-pay';
    const params = {};
    if (hauler_id) {
        params.where = { ...params.where, 'hauler.id': hauler_id };
    }
    if (startDate && endDate) {
        params.where = { ...params.where, date: { between: [startDate, endDate] } };
    }
    if (hauler_ids) {
        params.where = { ...params.where, 'hauler.id': { inq: hauler_ids } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getHaulerAssignmentQuery = ({ month, year, fields }) => {
    const endpoint = '/hauler-assignment';
    const params = {};
    if (month && year) {
        const date = moment(`${year}-${month}`, 'YYYY-MM');
        params.where = { ...params.where, date: { between: [date.clone().startOf('month').toDate(), date.clone().endOf('month').toDate()] } };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    params.populate = {
        assignee: ['assignee_name', 'assignee_number'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const createHaulerAssignmentQuery = ({ model }) => {
    const endpoint = '/hauler-assignment';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editHaulerAssignmentQuery = ({ key, model }) => {
    const endpoint = `/hauler-assignment/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const getVendorMonthlyPayQuery = ({ vendor_id, vendor_ids, startDate, endDate }) => {
    const endpoint = '/vendor-monthly-pay-details';
    const params = {};
    if (vendor_id) {
        params.where = { ...params.where, 'vendor.id': vendor_id };
    }
    if (startDate && endDate) {
        params.where = { ...params.where, date: { between: [startDate, endDate] } };
    }
    if (vendor_ids) {
        params.where = { ...params.where, 'vendor.id': { inq: vendor_ids } };
    }
    return buildExpressQuery(endpoint, { params });
};

export const getVendorsWithCompletePayReportsQuery = ({ report_month }) => {
    const endpoint = '/vendor-monthly-pay-details/complete-pay-reports';
    const params = {};
    params.where = { report_month };
    return buildExpressQuery(endpoint, { params });
};

export const getProcessorFmmoMonthlyTotalsQuery = ({ startDate, endDate, selectedFmmo }) => {
    const params = {};
    params.order = 'processor.name ASC';
    if (selectedFmmo) {
        params.where = { fmmo: selectedFmmo };
    }
    if (startDate && endDate) {
        params.where = {
            ...params.where,
            date: {
                between: [startDate, endDate],
            },
        };
    }
    const endpoint = '/processor-fmmo-monthly-totals';
    return buildExpressQuery(endpoint, { params });
};

export const getHistoryQuery = ({ id, type }) => {
    const params = {};
    const endpoint = `/${type}/get-diffs/${id}/human-readable`;
    return buildExpressQuery(endpoint, { params });
};

export const addBarcodePrintQueueQuery = ({ model }) => {
    const endpoint = '/barcode-print-sequence';
    return buildExpressQuery(endpoint, { body: model });
};

export const editBarcodePrintQueueQuery = (key, model) => {
    const endpoint = `/barcode-print-sequence/${key}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const getBarcodePrintQueueQuery = ({ queueStatus, queueType, producerId, tankNumber, sortBy, limit }) => {
    const endpoint = '/barcode-print-sequence';
    const params = {};
    if (sortBy) {
        params.order = `${sortBy} DESC`;
    }
    if (limit) {
        params.limit = limit;
    }
    if (queueStatus) {
        params.where = { ...params.where, status: { eq: queueStatus } };
    }
    if (queueType) {
        params.where = { ...params.where, type: { eq: queueType } };
    }
    if (producerId) {
        params.where = { ...params.where, 'producer.id': { eq: producerId } };
    }

    if (tankNumber) {
        params.where = { ...params.where, tank: { eq: tankNumber } };
    }

    return buildExpressQuery(endpoint, { params });
};

export const getBarcodePrintHistoryQuery = (startDate, endDate) => {
    const endpoint = '/barcode-print-sequence';
    const params = {};
    params.where = { status: { neq: 'pending' }, created_at: { between: [startDate, endDate] } };
    return buildExpressQuery(endpoint, { params });
};

export const addMiscSampleQuery = (model) => {
    const endpoint = '/misc-samples';
    return buildExpressQuery(endpoint, { body: model });
};

export const editMiscSampleQuery = (id, model) => {
    const endpoint = `/misc-samples/${id}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const getMiscSampleQuery = (id) => {
    const endpoint = '/misc-samples';
    const params = {};
    params.where = { ...params.where, _id: { eq: id } };
    return buildExpressQuery(endpoint, { params });
};

export const listMiscSamplesQuery = ({ startDate, endDate, producerId }) => {
    const endpoint = '/misc-samples';
    const params = {};
    if (startDate && endDate) {
        const region = getUserRegion();
        if (region === 'prairie') {
            params.where = { ...params.where, created_at: { between: [startDate, endDate] } };
        } else {
            params.where = { ...params.where, date: { between: [startDate, endDate] } };
        }
    }
    if (producerId) {
        params.where = { ...params.where, producer_id: { eq: producerId } };
    }
    params.populate = {
        producer_id: ['name', 'license_number', 'number_of_tanks'],
    };
    return buildExpressQuery(endpoint, { params });
};

export const getLinksQuery = () => {
    const endpoint = '/helpful-links';
    return buildExpressQuery(endpoint, {});
};

export const updateLinksQuery = (links) => {
    const endpoint = '/helpful-links';
    return buildExpressQuery(endpoint, { body: links });
};

export const updateProducerConfigQuery = (producerConfig) => {
    const endpoint = '/helpful-links/config';
    return buildExpressQuery(endpoint, { body: producerConfig });
};

export const editProducerFmmoHistoryQuery = (producerId, month, selectedFmmos, selectedSubMarkets) => {
    const endpoint = '/users/update-producer-fmmo-history';
    const body = {
        producerId,
        month,
    };

    if (selectedFmmos != null) {
        body.fmmos = selectedFmmos;
    }

    if (selectedSubMarkets != null) {
        body.sub_markets = selectedSubMarkets;
    }

    return buildExpressQuery(endpoint, { body });
};

export const getPooledPickupsQuery = ({ startDate, endDate }) => {
    const endpoint = `/pickups/pooled-pickups/?startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`;
    return buildExpressQuery(endpoint, {});
};

export const getSignedURLQuery = (fileName) => {
    const endpoint = '/reports/get-signed-url';
    const params = {
        where: { fileName },
    };

    return buildExpressQuery(endpoint, { params });
};

export const getAllHaulerRatesQuery = () => {
    const endpoint = '/hauler-rate/';
    return buildExpressQuery(endpoint, {});
};

export const getEffectiveRatesByHaulerRateIdQuery = ({ haulerRateId }) => {
    const endpoint = `/hauler-rate/effective-hauler-rate/${haulerRateId}`;
    return buildExpressQuery(endpoint, {});
};

export const getHaulerRatesForHaulingContractQuery = ({ haulerId, startDate, endDate, haulerRateIds }) => {
    let endpoint = `/hauling-contract/hauler-rates/?hauler_id=${haulerId}&start_date=${startDate}`;
    if (endDate != null) {
        endpoint += `&end_date=${endDate}`;
    }
    if (haulerRateIds != null) {
        haulerRateIds.forEach((id) => {
            endpoint += `&hauler_rates_from_contract[]=${id}`;
        });
    }
    return buildExpressQuery(endpoint, {});
};

export const createMilkPriceQuery = (model) => {
    const endpoint = '/milk-price';
    return buildExpressQuery(endpoint, { body: { ...model, payee_filter: { role: 'producer', ...model.payee_filter } } });
};

export const editMilkPriceQuery = (key, model) => {
    const endpoint = `/milk-price/${key}`;
    return buildExpressQuery(endpoint, { body: { ...model, payee_filter: { role: 'producer', ...model.payee_filter } } });
};

export const deleteMilkPriceQuery = (key) => {
    const endpoint = `/milk-price/${key}`;
    return buildExpressQuery(endpoint, {});
};

export const addHaulerRateQuery = (model) => {
    const endpoint = '/hauler-rate';
    return buildExpressQuery(endpoint, { body: model });
};

export const addAdhocAdvanceReportQuery = (model) => {
    const endpoint = '/payment-reports/adhoc-advance';
    return buildExpressQuery(endpoint, { body: model });
};

export const editAdhocAdvanceReportQuery = (id, model) => {
    const endpoint = `/payment-reports/adhoc-advance/${id}`;
    return buildExpressQuery(endpoint, { body: model });
};

export const deleteAdhocPaymentReportQuery = (key) => {
    const endpoint = `/payment-reports/${key}`;
    return buildExpressQuery(endpoint, {});
};

export const createNotificationEventQuery = ({ eventType, model, sourceUserIds }) => {
    const endpoint = '/notifications/event';
    const body = {
        eventType,
        eventPayload: model,
        sourceUserIds,
    };
    return buildExpressQuery(endpoint, { body });
};

export const getTableDocsListQuery = () => {
    const endpoint = '/documentation/tables';
    return buildExpressQuery(endpoint, {});
};

export const getTableDocByIdQuery = ({ tableId }) => {
    const endpoint = `/documentation/tables/${tableId}`;
    return buildExpressQuery(endpoint, {});
};
