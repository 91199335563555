import { FuseLoadable } from '@fuse';

const ContractManagement = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            exact: true,
            path: '/contract-management',
            component: FuseLoadable({
                loader: () => {
                    return import('./ContractManagement');
                },
            }),
        },
        {
            exact: true,
            path: '/contract-management/creation/:id/:step',
            component: FuseLoadable({
                loader: () => {
                    return import('./contract-creation/ContractCreationWizard');
                },
            }),
        },
    ],
};

export default ContractManagement;
