import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import FormControl from '@material-ui/core/es/FormControl/FormControl';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { MultiSelectForm, CustomGoogleMap } from 'app/custom-widgets';
import _ from 'lodash';
import * as Actions from '../../store/actions';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { getDefaultLatitude, getDefaultLongitude, getDefaultZoom, getGoogleMapsURL } from '../../../utils';

class EditRouteForm extends Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            canSubmit: false,
            producerList: [],
            haulingList: [],
            selectedProducers: [],
            selectedHaulings: [],
            selectedType: 'milk',
            latitude: getDefaultLatitude(props.region),
            longitude: getDefaultLongitude(props.region),
            overrideZoom: getDefaultZoom(props.region),
            toAdd: null,
            toRemove: null,
        };
    }

    componentDidMount() {
        const { selectedType } = this.state;
        const { producers, haulingCompanies } = this.props;

        this.setState({ producerList: producers.filter((value) => value.type_of_fluid === selectedType), haulingList: haulingCompanies });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedType } = this.state;
        const { producers, haulingCompanies } = this.props;
        if (this.props !== prevProps) {
            this.setState({ producerList: producers.filter((value) => value.type_of_fluid === selectedType), haulingList: haulingCompanies });
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    transformProducers = (details) => {
        const { selectedProducers } = this.state;
        return details.map((value) => ({
            value: value.value,
            label: value.label,
            latitude: value.latitude,
            longitude: value.longitude,
            isSelected: selectedProducers ? selectedProducers.includes(value.value) : false,
        }));
    };

    addProducerFromMap = (value) => {
        if (!value.isSelected) {
            this.setState({ toAdd: [value] });
        } else {
            this.setState({ toRemove: [value] });
        }
    };

    onSubmit = (model) => {
        const { editData, showMessage, history, editRoute, addRoute, selectedHaulings, selectedProducers, selectedType, region } = { ...this.state, ...this.props };
        const submitModel = { ...model };
        submitModel.users = selectedProducers || [];
        submitModel.hauling_companies = region === 'PEI' ? selectedHaulings || [] : [];
        submitModel.type_of_fluid = editData ? _.lowerCase(editData.type_of_fluid) : _.lowerCase(selectedType);
        if (editData) {
            editRoute(submitModel, editData.id)
                .then(() => {
                    showMessage({ message: 'Successfully Edited Route.' });
                    history.replace({ pathname: '/list-routes' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        } else {
            addRoute(submitModel)
                .then(() => {
                    showMessage({ message: 'Successfully Added Route.' });
                    history.replace({ pathname: '/list-routes' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        }
    };

    transformList = (details) => {
        if (details && details.length > 0) {
            return details.map((value) => ({ label: value.name, value: value.id }));
        }
        return [];
    };

    handleListChange = (arr, type) => {
        if (arr && arr.length > 0) {
            this.setState({ [type]: arr.map((value) => value.value) });
        } else {
            this.setState({ [type]: null });
        }
    };

    handleTypeChange = (event) => {
        const { producers } = this.props;
        this.setState({ selectedType: event.target.value });
        this.setState({ selectedProducers: null });

        if (event.target.value === 'cream') {
            this.setState({ producerList: producers.filter((value) => value.type_of_fluid === 'cream') });
        } else {
            this.setState({ producerList: producers.filter((value) => value.type_of_fluid === 'milk') });
        }
    };

    render() {
        const { canSubmit, producerList, haulingList, selectedProducers, selectedHaulings, selectedType, latitude, longitude, toAdd, toRemove, overrideZoom } = this.state;
        const { editData, region, producers } = this.props;

        return (
            <div className="min-w-3/4 max-w-3/4">
                <Divider />
                <Formsy
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-col justify-center"
                >
                    <TextFieldFormsy
                        className="my-32"
                        type="text"
                        name="name"
                        label="Name"
                        value={editData ? editData.name : ''}
                        validations={{ minLength: 2 }}
                        validationErrors={{ minLength: 'Min character length is 3' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        near_me
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                    />
                    <div style={{ paddingBottom: 30 }}>
                        <FormControl className="w-full h-full">
                            <SelectSearchFormsy
                                className="my-16"
                                name="type_of_fluid"
                                label="Type of Fluid"
                                options={[
                                    { value: 'milk', name: 'Milk' },
                                    { value: 'cream', name: 'Cream' },
                                ]}
                                value={editData ? _.lowerCase(editData.type_of_fluid) : selectedType}
                                onChange={(value) => this.handleTypeChange(value) && this.handleListChange(null, 'selectedProducers')}
                                variant="standard"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                disabled={!!editData}
                            />
                        </FormControl>
                    </div>
                    <div style={{ paddingBottom: 30 }}>
                        <MultiSelectForm
                            label="Producers"
                            preselectedValues={editData ? this.transformList(editData.users) : selectedProducers}
                            listOfValues={editData ? producers.filter((value) => value.type_of_fluid === _.lowerCase(editData.type_of_fluid)) : producerList}
                            onChange={(value) => this.handleListChange(value, 'selectedProducers')}
                            isEdit={!!editData}
                            toAdd={toAdd}
                            toRemove={toRemove}
                            selectedType={selectedType}
                        />
                    </div>
                    {region === 'PEI' && (
                        <div style={{ paddingBottom: 30 }}>
                            <MultiSelectForm label="Hauling Companies" preselectedValues={editData ? this.transformList(editData.haulingCompanies) : selectedHaulings} listOfValues={haulingList} onChange={(value) => this.handleListChange(value, 'selectedHaulings')} isEdit={!!editData} />
                        </div>
                    )}
                    <div>
                        <CustomGoogleMap
                            googleMapURL={getGoogleMapsURL()}
                            loadingElement={<div style={{ height: '100%' }} />}
                            containerElement={<div style={{ height: '400px' }} />}
                            mapElement={<div style={{ height: '100%' }} />}
                            onPositionChange={this.handleMapChange}
                            lat={latitude}
                            lng={longitude}
                            draggable
                            overrideZoom={overrideZoom}
                            producerLocations={this.transformProducers(producerList)}
                            pinFunction={(value) => this.addProducerFromMap(value)}
                        />
                    </div>
                    <div className="flex justify-center" style={{ paddingTop: 40 }}>
                        <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT ROUTE' : 'ADD ROUTE'} disabled={!canSubmit}>
                            {editData ? 'Edit Route' : 'Add Route'}
                        </Button>
                    </div>
                </Formsy>
            </div>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addRoute: Actions.addRoute,
            editRoute: Actions.editRoute,
            showMessage: Actions.showMessage,
        },
        dispatch
    );
}
function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditRouteForm));
