/* eslint-disable no-await-in-loop */
import axios from 'axios';
import { getEnvironment } from '../../../utils';

const MicroserviceEndpoints = Object.freeze({
    Pay: 'pay',
    ProcessorProfile: 'processor-profile',
    Price: 'price',
    PayCalculationEngine: 'pay-calc-engine',
    Statements: 'pay-statement',
});

// TODO: Helper function for ecs deploy level, want pattern for HTTP reqs to microservices
const getEndpoint = (endpoint, isV2 = false) => {
    const environment = getEnvironment(window.location.hostname);
    const version = isV2 ? 'v2' : 'v1';
    if (environment === 'development') return `https://loc-mm-core-${endpoint}.milkmoovement.services/${version}`;
    if (environment === 'staging') return `https://stg-mm-core-${endpoint}.stg.milkmoovement.io/${version}`;
    if (environment === 'production') return `https://prod-mm-core-${endpoint}.prod.milkmoovement.io/${version}`;
    throw new Error('Cannot get prices services url. Environment not found');
};

export const getPricesServicesUrl = () => {
    return getEndpoint(MicroserviceEndpoints.Price);
};

export const getPayUrl = (isV2 = false) => {
    return getEndpoint(MicroserviceEndpoints.Pay, isV2);
};

export const getPayCalculationsUrl = (isV2 = false) => {
    return getEndpoint(MicroserviceEndpoints.PayCalculationEngine, isV2);
};

export const getPayCalcEngineUrl = (isV2 = false) => {
    return getEndpoint(MicroserviceEndpoints.PayCalculationEngine, isV2);
};

export const getStatementsUrl = (isV2 = false) => {
    return getEndpoint(MicroserviceEndpoints.Statements, isV2);
};

export const fetchAllPages = async (url, resourceKey) => {
    const { error, data } = await axios.get(url);
    const { nextPageUrl, nextPage } = data || {};

    if (error) throw new Error(error);
    const response = resourceKey ? data[resourceKey] : data;
    const next = nextPageUrl || nextPage;

    let newUrl = next;
    while (newUrl) {
        /* eslint-disable no-await-in-loop */

        try {
            const { data: newData, error: newError } = await axios.get(newUrl);

            const { nextPageUrl: newNextPageUrl, nextPage: newNextPage } = newData || {};

            if (newError) throw new Error(newError);
            const newResponse = resourceKey ? newData[resourceKey] : newData;
            response.push(...newResponse);
            const newNext = newNextPageUrl || newNextPage;
            newUrl = newNext;
        } catch (e) {
            // If anything other than 4** not found throw error pass it along
            if (e?.response?.status >= 400 && e?.response?.status < 500 && e?.response?.status !== 401) {
                newUrl = null;
            } else throw new Error(e);
        }
    }
    return response;
};
