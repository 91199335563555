import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import _ from 'lodash';
import path from 'path';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, InputAdornment, Typography, Alert } from '@mui/material';
import { farmLabelByRegion, getUserRegion, formatPhoneNumber, getAPIHost, getDefaultLatitude, getDefaultLongitude, unformattedPhoneNumber, generateTestId, haulingStopCategory, getAllFederalOrders } from '../../../utils';
import {
    districts,
    payZone,
    jurisdictionName,
    paymentMethod,
    haulingTypes,
    status,
    state,
    selfHaul,
    pickupType,
    outOfBusiness,
    prairiePaymentMethod,
    payFrequency,
    regionStateList,
    booleanYesOrNo,
    milkCategory,
    farmType,
    pumpType,
    dairyType,
    getRouteRegionLabel,
    labRegions,
    getPremiumProgramOptions,
    payTransactionDateBasisOptions,
} from '../form-components/ReactHookProducerFormSelects';
import { ReactHookFormSearchableSelect, ProducerFormCountySelect, ReactHookFormPhoneInput, ReactHookFormInput, producerValidationSchema, countyData } from '../form-components';
import Region from '../../constants/region';
import { BARCODE_PRINTING_REGIONS, EQUITY_REGIONS, TCJ_REGIONS } from '../../constants/bulkRegion';
import Dropzone from './Dropzone';
import { addProducer, editUser, showMessage } from '../../store/actions';
import PremiumProgramMultiselect from '../../configured-components/form/configured-inputs/special/PremiumProgramMultiselect';
import { getBulkChartsForProducer, uploadBulkChart, getFileURL, downloadBulkChart } from '../../repository';
import { ConfirmationDialog } from '../../custom-widgets';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import GeofenceManager from '../geofences/maps/GeofenceManager/GeofenceManager';
import Split from '../../split';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 'auto',
        width: '100%',
        height: '100%',
        '& .MuiAutocomplete-root .MuiFormControl-root': {
            marginTop: '8px',
        },
    },
    header: {
        paddingBottom: '10px',
    },
    status: {
        marginTop: '9px',
    },
    input: {
        marginTop: '15px',
    },
    date: {
        marginTop: '8px',
    },
}));

const ReactHookProducerForm = ({ haulingCompanies, processors, editData, routes }) => {
    const region = getUserRegion();
    const history = useHistory();
    const [alert, setAlert] = useState(null);
    const [latitude, setLatitude] = useState(getDefaultLatitude(region));
    const [longitude, setLongitude] = useState(getDefaultLongitude(region));
    const [bulkCharts, setBulkCharts] = useState([]);
    const [files, setFiles] = useState([]);
    const [tanks, setTanks] = useState([]);
    const [geofences, setGeofences] = useState([]);
    const [selectedCounty, setSelectedCounty] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const countiesMap = _.flatMap(countyData);

    const reactHookFormMethods = useForm({
        mode: 'all', // Sets validation onBlur + onChange + onSubmit
        defaultValues: {
            ...editData,
            county_id: _.find(countiesMap, (z) => z.id === editData?.county_id),
            type_of_fluid: editData ? _.find(dairyType, (z) => z.value === editData?.type_of_fluid) : { name: 'Milk', value: 'milk' },
            hauling_type_stop_limit: _.find(haulingTypes, (z) => z.value === editData?.hauling_type_stop_limit),
            zone: [Region.UNC, Region.DEMO, Region.CDI, Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS].includes(region) ? _.find(payZone(region), (z) => z.value === editData?.zone) : editData?.zone,
            status: editData ? _.find(status, (z) => z.value === editData?.status) : { name: 'Active', value: 'Active' },
            milk_category: _.find(milkCategory, (z) => z.value === editData?.milk_category),
            state: _.isEmpty(regionStateList(region)) ? _.find(state, (z) => z.value === editData?.state) : _.find(regionStateList(region), (z) => z.value === editData?.state),
            mailing_state: _.isEmpty(regionStateList(region)) ? _.find(state, (z) => z.value === editData?.mailing_state) : _.find(regionStateList(region), (z) => z.value === editData?.mailing_state),
            payroll_paid: _.find(booleanYesOrNo, (z) => z.value === editData?.payroll_paid),
            is_patronage_member: _.find(booleanYesOrNo, (z) => z.value === editData?.is_patronage_member),
            is_organic: _.find(booleanYesOrNo, (z) => z.value === editData?.is_organic),
            pump_type: [Region.UNC].includes(region) ? _.find(pumpType, (z) => z.value === editData?.pump_type) : editData?.pump_type,
            route_region: _.find(districts(region), (z) => z.value === editData?.route_region),
            jurisdiction_name: _.find(jurisdictionName, (z) => z.value === editData?.jurisdiction_name),
            payment_method: [Region.PRAIRIE].includes(region) ? _.find(prairiePaymentMethod, (z) => z.value === editData?.payment_method) : _.find(paymentMethod, (z) => z.value === editData?.payment_method),
            equity_payment_method: _.find(paymentMethod, (z) => z.value === editData?.equity_payment_method),
            default_hauling_company: _.find(haulingCompanies, (z) => z.value === editData?.default_hauling_company || ''),
            default_route: _.find(routes, (z) => z.value === editData?.default_route || ''),
            processor_id: _.find(processors, (z) => z.value === editData?.processor_id || ''),
            hauling_type: _.find(haulingTypes, (z) => z.value === editData?.hauling_type),
            pay_frequency: _.find(payFrequency(), (z) => z.value === editData?.pay_frequency),
            self_haul: _.find(selfHaul, (z) => z.value === editData?.self_haul),
            type_of_pickup: _.find(pickupType(region), (z) => z.value === editData?.type_of_pickup),
            updated_at: moment(editData?.updated_at).format('MM/DD/YYYY'),
            created_at: moment(editData?.created_at).format('MM/DD/YYYY'),
            out_of_business_date: editData?.out_of_business_date || null,
            start_date: editData?.start_date ?? editData?.created_at,
            tanks: editData?.tanks || [],
            scheduled_reports: editData?.scheduled_reports ?? [],
            farm_type: [Region.MMPA].includes(region) ? _.find(farmType, (z) => z.value === editData?.farm_type) : editData?.farm_type,
            phone: formatPhoneNumber(editData?.phone),
            barn_phone: formatPhoneNumber(editData?.barn_phone),
            fax_number: formatPhoneNumber(editData?.fax_number),
            federal_order: _.find(getAllFederalOrders(), (z) => z.value === editData?.federal_order),
            lab_region: _.find(labRegions(region), (z) => z.value === editData?.lab_region),
            split_producer: _.find(booleanYesOrNo, (z) => z.value === editData?.split_producer),
            geofence: editData?.geofence,
            hauling_stop_category: _.find(haulingStopCategory, (z) => z.value === editData?.hauling_stop_category),
            pay_transaction_date_basis: editData?.pay_transaction_date_basis != null ? payTransactionDateBasisOptions.find(({ value }) => value === editData?.pay_transaction_date_basis) : TCJ_REGIONS.includes(region) ? payTransactionDateBasisOptions[1] : payTransactionDateBasisOptions[0],
        },
        shouldUnregister: true,
        resolver: yupResolver(producerValidationSchema),
    });
    const { handleSubmit, watch, setValue, errors, control } = reactHookFormMethods;

    const selfHaulWatch = watch('self_haul');
    const statusWatch = watch('status');
    const bulkChartWatch = watch('bulk_tank_chart');
    const tankWatch = watch('tank');
    const defaultHaulingCompanyWatch = watch('default_hauling_company');

    useEffect(() => {
        if (editData) {
            if (editData?.latitude) setLatitude(editData.latitude);
            if (editData?.longitude) setLongitude(editData.longitude);
            if (editData?.id) getBulkChartsForProducer(editData.id).then((charts) => setBulkCharts(charts));
        }
    }, [editData]);

    useEffect(() => {
        if (selfHaulWatch) {
            setValue('hauling_type_stop_limit', '0');
        }
        if (tankWatch) {
            setTanks(
                Array.from({ length: tankWatch }, (v, i) => {
                    return {
                        tank_number: (i + 1)?.toString(),
                        calibration_date: '',
                        tank_capacity: '',
                        tank_location: '',
                    };
                })
            );
        }
    }, [selfHaulWatch, tankWatch]);

    const patch = (submitModel, id) => {
        dispatch(editUser(submitModel, id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully edited Producer' }));
                setAlert(null);
                history.replace({ pathname: '/list-producers' });
            })
            .catch((err) => {
                dispatch(showMessage({ message: 'Unable to edit producer' }));
                setAlert(err.message);
            });
    };

    const post = (submitModel) => {
        dispatch(addProducer(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully added Producer' }));
                setAlert(null);
                history.replace({ pathname: '/list-producers' });
            })
            .catch((err) => {
                dispatch(showMessage({ message: 'Unable to add producer' }));
                setAlert(err.message);
            });
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };

        // This function applies to the searchable select components and passes the value of the selection to the model
        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value !== undefined) {
                submitModel[key] = submitModel[key].value;
            }
        });

        submitModel.pay_transaction_date_basis = submitModel.pay_transaction_date_basis || '';
        submitModel.producer_business_number = parseFloat(submitModel?.producer_business_number) || undefined;
        submitModel.start_date = submitModel.start_date ? moment(submitModel.start_date).toDate() : undefined;
        submitModel.gl_number = submitModel?.gl_number?.toString();
        submitModel.state_number = submitModel?.state_number?.toString();
        submitModel.license_number = submitModel?.license_number?.toString();
        submitModel.district_id = _.find(districts(region), (z) => z.value === submitModel?.route_region)?.name;
        submitModel.county_id = submitModel.county_id?.id.toString() || null;
        submitModel.permit_number = submitModel?.permit_number?.toString() || null;
        submitModel.state_county_code = submitModel?.state_county_code?.toString();
        submitModel.market_admin_id = submitModel?.market_admin_id?.toString();
        submitModel.equity_id = submitModel?.equity_id?.toString();
        submitModel.pay_group = submitModel?.zone?.toString();
        submitModel.tanks = (submitModel.tanks || []).map((v, i) => ({ ...v, tank_number: (i + 1)?.toString() }));
        submitModel.phone = submitModel.phone ? unformattedPhoneNumber(submitModel.phone) : undefined;
        submitModel.barn_phone = submitModel.barn_phone ? unformattedPhoneNumber(submitModel.barn_phone) : undefined;
        submitModel.fax_number = submitModel.fax_number ? unformattedPhoneNumber(submitModel.fax_number) : undefined;
        submitModel.latitude = latitude?.toString() ?? getDefaultLatitude(region)?.toString();
        submitModel.longitude = longitude?.toString() ?? getDefaultLongitude(region)?.toString();
        submitModel.secondary_email = submitModel?.secondary_email ?? '';
        submitModel.number_of_barcodes_per_queue = submitModel?.number_of_barcodes_per_queue ? parseInt(submitModel.number_of_barcodes_per_queue) : undefined;

        if (geofences.length > 0) {
            const fence = geofences[0];
            fence.name = `${submitModel.name} - ${fence.type}`;
            delete fence._id;
            delete fence.deleted_at;
            submitModel.geofence_payload = fence;

            // Update the user model to reflect the new geofence
            submitModel.latitude = fence.center.coordinates[1].toString();
            submitModel.longitude = fence.center.coordinates[0].toString();
            submitModel.geofence_radius = fence.radius_meters;
        }

        // remove props that don't apply to PRODUCER user type for Prairie, Legacy, or CDI
        delete submitModel.bulk_tank_chart;
        delete submitModel.tableData;
        delete submitModel.CMTNumber;
        delete submitModel.rBST_sign_up_date;
        delete submitModel.rBST_processor_charge;
        delete submitModel.rBST_processor_share;
        delete submitModel.deleted_at;
        delete submitModel.updated_at;
        delete submitModel.created_at;
        delete submitModel.pickup_date;
        delete submitModel.pickup_days;
        delete submitModel.admin_id;
        delete submitModel.user_id;
        delete submitModel.is_subuser;
        delete submitModel.lab_number;
        delete submitModel.driver_number;
        delete submitModel.trailer_number;
        delete submitModel.daily_fluid_milk_quota;
        delete submitModel.industrial_msq;
        delete submitModel.timezone_preference;
        delete submitModel.rBST_free_program_processor;
        delete submitModel.fairlife_producer;
        delete submitModel.hauling_id;
        delete submitModel.index;
        delete submitModel.hauler;
        delete submitModel.hauler_name;
        delete submitModel.hauler_num;
        delete submitModel.bank_account_number;
        delete submitModel.bank_routing_number;
        delete submitModel.bank_transaction_code;
        delete submitModel.routing_number;
        delete submitModel.organic_producer;

        if (files.length) {
            const type = _.lowerCase(path.extname(files[0].name));

            if (!['csv', 'xlsx'].includes(type)) {
                dispatch(showMessage({ message: 'Please provide a valid .csv or .xlsx file.' }));
                return;
            }
            const obj = {
                file: files[0],
                producer_id: editData.id,
                bulk_tank: bulkChartWatch?.value,
            };

            const formData = new FormData();
            formData.append('file', obj.file);
            formData.append('producer_id', obj.producer_id);
            formData.append('bulk_tank', obj.bulk_tank);

            uploadBulkChart(formData)
                .then(() => {
                    showMessage({ message: `Successfully updated ${editData.name} Bulk Tank Chart!` });
                    history.goBack();
                })
                .catch(() => {
                    showMessage({ message: 'There was a problem uploading your file. Please contact support.' });
                });
        }

        if (editData) {
            delete submitModel.geofence_payload;
            delete submitModel.email;
            delete submitModel.username;

            patch(submitModel, editData.id);
        } else {
            post(submitModel);
        }
    };

    const downloadTemplateChart = () => {
        getFileURL('BulkCharts/1/Bulk_Chart_Template.xlsx').then((result) => {
            window.open(result, '_blank');
        });
    };

    const uploadNewChart = (newFiles) => {
        setFiles(newFiles);
    };

    const downloadCharts = () => {
        downloadBulkChart(editData.id, bulkChartWatch.value)
            .then((result) => {
                getFileURL(`BulkCharts/${bulkChartWatch.value}/${result.data}`)
                    .then((file) => {
                        window.open(file, '_blank');
                    })
                    .catch((error) => {
                        showMessage({ message: 'There was a problem downloading your file. Please contact support.' });
                    });
            })
            .catch((err) => {
                showMessage({ message: 'There was a problem downloading your file. Please contact support.' });
            });
    };

    const deleteBulkChart = () => {
        bulkCharts[bulkChartWatch.value - 1] = '';

        axios
            .patch(`${getAPIHost()}/users/${editData.id}`, { bulk_charts: bulkCharts })
            .then(() => {
                showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                history.replace({ pathname: '/edit-producer' });
            })
            .catch((err) => {
                showMessage({ message: 'There was a problem during deletion. Please contact support.' });
            });
    };

    const handleEditGeofenceFromMap = (newGeofences) => {
        setGeofences([...newGeofences]);
        setLongitude(newGeofences[0]?.center.coordinates[0] || getDefaultLongitude(region));
        setLatitude(newGeofences[0]?.center.coordinates[1] || getDefaultLatitude(region));
    };

    const render = () => {
        return (
            <LocalizationConsumer>
                {(localization) => (
                    <>
                        <Box sx={{ flexGrow: 1 }} className={classes.root}>
                            <FormProvider {...reactHookFormMethods}>
                                <form onSubmit={handleSubmit(onSubmit, errors)}>
                                    <Grid container spacing={4}>
                                        {alert && (
                                            <Grid item xs={12}>
                                                <Alert className="mt-32 mb-16" color="error">
                                                    {alert}
                                                </Alert>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Account Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="username"
                                                label="Username"
                                                fullWidth
                                                required
                                                fieldOpts={{
                                                    validations: { ...(!editData && { minLength: 4 }) },
                                                    type: 'text',
                                                    variant: 'standard',
                                                    ...(editData && { disabled: true }),
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <i className="text-20 material-icons" color="action">
                                                                    person
                                                                </i>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                fieldOpts={{
                                                    type: 'email',
                                                    variant: 'standard',
                                                    ...(editData && { disabled: true }),
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <i className="text-20 material-icons" color="action">
                                                                    person
                                                                </i>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="secondary_email"
                                                label="Secondary Email"
                                                fullWidth
                                                fieldOpts={{
                                                    type: 'email',
                                                    variant: 'standard',
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <i className="text-20 material-icons" color="action">
                                                                    person
                                                                </i>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        {!editData && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput
                                                        name="password"
                                                        label="Password"
                                                        type="password"
                                                        required={!editData}
                                                        fieldOpts={{
                                                            validations: { minLength: 8 },
                                                            type: 'password',
                                                            variant: 'standard',
                                                            InputProps: {
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <i className="text-20 material-icons" color="action">
                                                                            person
                                                                        </i>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput
                                                        name="password_confirmation"
                                                        label="Confirm Password"
                                                        type="password"
                                                        required={!editData}
                                                        fieldOpts={{
                                                            validations: { minLength: 8, equalsField: 'password' },
                                                            type: 'password',
                                                            variant: 'standard',
                                                            InputProps: {
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <i className="text-20 material-icons" color="action">
                                                                            person
                                                                        </i>
                                                                    </InputAdornment>
                                                                ),
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {editData && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput name="created_at" label="Date Added" fieldOpts={{ disabled: true, variant: 'standard' }} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput name="updated_at" label="Last Updated" fieldOpts={{ disabled: true, variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Producer Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="license_number" label="Producer ID" fullWidth fieldOpts={{ type: 'text', variant: 'standard', margin: 'normal' }} required />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="name" label="Farm Name" fieldOpts={{ type: 'text', variant: 'standard' }} required />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="full_name" label="Producer Name" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        {[Region.MMPA].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput name="producer_name_number" label="Name Number" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput name="producer_name_code" label="Name Code" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="status" label="Status" options={status} required />
                                        </Grid>
                                        {statusWatch?.value === 'Active' && (
                                            <>
                                                <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <Controller
                                                            name="start_date"
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => {
                                                                return <DatePicker data-testid={generateTestId('Start Date', 'date-picker')} className={classes.date} fullWidth onChange={onChange} format="MM/DD/YYYY" value={value} label="Start Date" clearable />;
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                                <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                                    <ReactHookFormInput name="reason_added" label="Added Reason" fieldOpts={{ variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {statusWatch?.value === 'Inactive' && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                                        <Controller
                                                            name="out_of_business_date"
                                                            control={control}
                                                            render={({ field: { onChange, value } }) => {
                                                                return <DatePicker data-testid={generateTestId('Out of Business Date', 'date-picker')} className={classes.date} fullWidth onChange={onChange} format="MM/DD/YYYY" value={value} label="Out of Business Date" clearable />;
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="out_of_business_reason" label="Out Of Business Reason" variant="standard" options={outOfBusiness} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="split_producer" label="Pseudo Producer?" options={booleanYesOrNo} required />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="milk_category" label="Category" options={milkCategory} disabled={editData?.milk_category} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="type_of_fluid" label="Type of Fluid" options={dairyType} required />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="route_region" label={getRouteRegionLabel(region)} options={districts(region)} />
                                        </Grid>
                                        {[Region.PRAIRIE, Region.BONGARDS, ...TCJ_REGIONS].includes(region) && (
                                            <Grid item xs={12} md={4}>
                                                <ProducerFormCountySelect name="county_id" label="Field County ID" options={countyData} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="field_representative" label="Field Rep" fullWidth fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="permit_number" label="State Permit #" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormInput name="btu" label="BTU" fullWidth fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        {[Region.MMPA].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="producer_contract_number" label="Contract #" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="nutritionist" label="Nutritionist" fullWidth fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput fullWidth name="producer_local_number" label="Local #" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.PRAIRIE].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="processor_id" label={`${localization.general.processor_singular}`} options={processors} />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="lab_region" label="Lab Region" options={labRegions(region)} />
                                                </Grid>

                                                <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                                    <ReactHookFormInput name="state_county_code" label="State & County Code" fieldOpts={{ type: 'number', variant: 'standard' }} />
                                                </Grid>

                                                <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                                    <ReactHookFormInput name="market_admin_id" label="Market Admin Identification" fieldOpts={{ type: 'number', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.CDI].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="producer_business_number" label="Producer Business Number" fullWidth />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="state_number" label="State Number" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.MMPA, Region.UNC].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="farm_type" label="Farm Type" options={farmType} />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="is_organic" label="Organic" options={booleanYesOrNo} required />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.UNC].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="pump_type" label="Pump Type" options={pumpType} />
                                                </Grid>
                                            </>
                                        )}
                                        {![Region.CDI].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="type_of_pickup" label="Pickup Type" options={pickupType(region)} />
                                                </Grid>
                                            </>
                                        )}
                                        {Split.getBooleanTreatment('can_access_equity_pages') && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="equity_id" label="Equity Id" fullWidth fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.PRAIRIE, Region.UNC].includes(region) && (
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormInput name="deferral_years" label="Deferral Years" fullWidth fieldOpts={{ type: 'number', variant: 'standard' }} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Producer Address Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="address1"
                                                label={`${farmLabelByRegion(region)} Address Line 1`}
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="address2"
                                                label={`${farmLabelByRegion(region)} Address Line 2`}
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                            <ReactHookFormInput name="town" label={`${farmLabelByRegion(region)} City`} fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="state" label={`${farmLabelByRegion(region)} State`} variant="standard" options={_.isEmpty(regionStateList(region)) ? state : regionStateList(region)} />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                            <ReactHookFormInput fullWidth name="postal_code" label={`${farmLabelByRegion(region)} Postal / ZIP Code`} fieldOpts={{ type: 'text', variant: 'standard' }} />
                                        </Grid>
                                        {[Region.MMPA, Region.UNC].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ProducerFormCountySelect name="county_id" label="County Name and ID" options={countyData} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput fullWidth name="township_name" label="Township Name" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        {[Region.CDI].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <ProducerFormCountySelect name="county_id" label="County ID" options={countyData} selectedCounty={selectedCounty} setSelectedCounty={setSelectedCounty} />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormSearchableSelect name="jurisdiction_name" label="Jurisdiction" options={jurisdictionName} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Mailing Address Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="mailing_address1"
                                                label="Mailing Address Line 1"
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                    disabled: [Region.CDI].includes(region),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput
                                                name="mailing_address2"
                                                label="Mailing Address Line 2"
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                    disabled: [Region.CDI].includes(region),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                            <ReactHookFormInput
                                                name="mailing_city"
                                                label="Mailing City"
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                    disabled: [Region.CDI].includes(region),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="mailing_state" label="Mailing State" variant="standard" disabled={[Region.CDI].includes(region)} options={_.isEmpty(regionStateList(region)) ? state : regionStateList(region)} />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ paddingTop: '25px' }}>
                                            <ReactHookFormInput
                                                name="mailing_zip"
                                                label="Mailing Postal / Zip Code"
                                                fieldOpts={{
                                                    type: 'text',
                                                    variant: 'standard',
                                                    disabled: [Region.CDI].includes(region),
                                                }}
                                            />
                                        </Grid>
                                        {[Region.MMPA].includes(region) && (
                                            <Grid item xs={12}>
                                                <ReactHookFormInput fullWidth name="temp_address" label="Temporary Mailing Address" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Contact Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormPhoneInput name="phone" label="Producer Phone Number" region={region} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormPhoneInput name="barn_phone" label="Farm Phone Number" region={region} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormPhoneInput name="fax_number" label="Fax Number" region={region} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Payment Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="payroll_paid" label="Payroll Paid" options={booleanYesOrNo} required />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="payment_method" label="Payment Method" options={[Region.PRAIRIE].includes(region) ? prairiePaymentMethod : paymentMethod} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="pay_frequency" label="Pay Frequency" options={payFrequency()} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormSearchableSelect name="federal_order" label="Federal Order" options={getAllFederalOrders()} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormSearchableSelect name="zone" label={[Region.PRAIRIE, Region.DEMO].includes(region) ? 'Pay Group' : 'Pay Zone'} options={payZone(region)} />
                                        </Grid>
                                        {[Region.CDI].includes(region) && (
                                            <Grid item xs={12} md={6}>
                                                <ReactHookFormInput name="gl_number" label="GL Account #" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                            </Grid>
                                        )}
                                        {[Region.PRAIRIE].includes(region) && (
                                            <Grid item xs={12} md={6}>
                                                <ReactHookFormSearchableSelect name="is_patronage_member" label="Patronage" options={booleanYesOrNo} required />
                                            </Grid>
                                        )}
                                        {EQUITY_REGIONS.includes(region) && (
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormSearchableSelect name="equity_payment_method" label="Equity Payment Method" options={paymentMethod} />
                                            </Grid>
                                        )}
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormInput name="pay_to_name" label="Pay to Name" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormInput name="award_name" label="Award Name" fieldOpts={{ type: 'text', variant: 'standard' }} />
                                            </Grid>
                                        </>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormSearchableSelect name="pay_transaction_date_basis" label="Payment Based On" options={payTransactionDateBasisOptions} />
                                        </Grid>
                                        {[Region.MMPA].includes(region) && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.header} variant="h5">
                                                        Premium Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <PremiumProgramMultiselect
                                                        name="premium_programs"
                                                        labelKey="name"
                                                        keysToRemove={['name']}
                                                        optionIdKeys={['program_name', 'program_group']}
                                                        props={{
                                                            defaultValue: getPremiumProgramOptions(region).filter((program) => editData?.premium_programs?.find((prog) => program.program_name === prog.program_name && program.program_group === prog.program_group)) || [],
                                                            label: 'Premium Program',
                                                            options: getPremiumProgramOptions(region),
                                                            variant: 'standard',
                                                        }}
                                                        // wrap formatting
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Hauling Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="default_hauling_company" label="Default Hauler" options={haulingCompanies} />
                                        </Grid>
                                        {[Region.PRAIRIE].includes(region) && defaultHaulingCompanyWatch && routes && (
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormSearchableSelect name="default_route" label="Default Route" options={routes.filter((route) => route?.haulingCompanies.includes(defaultHaulingCompanyWatch.value))} required={routes.filter((route) => route?.haulingCompanies.includes(defaultHaulingCompanyWatch.value))?.length > 0} />
                                            </Grid>
                                        )}
                                        <Grid item xs={12} md={4}>
                                            <ReactHookFormSearchableSelect name="self_haul" label="Self Haul" options={selfHaul} required />
                                        </Grid>
                                        {[Region.UNC].includes(region) && (
                                            <Grid item xs={12} md={4}>
                                                <ReactHookFormSearchableSelect name="hauling_stop_category" label="Hauling Stop Category" options={haulingStopCategory} />
                                            </Grid>
                                        )}
                                        {[Region.CDI].includes(region) && (
                                            <>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect disabled={selfHaulWatch?.value === true} name="hauling_type" label="Stop Count Type" options={haulingTypes} />
                                                </Grid>

                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormInput name="hauling_type_stop_limit" label="Stop Count Type Limit" fieldOpts={{ disabled: selfHaulWatch?.value === true, type: 'text', variant: 'standard' }} />
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <ReactHookFormSearchableSelect name="type_of_pickup" label="Pickup Type" options={pickupType(region)} />
                                                </Grid>
                                            </>
                                        )}
                                        {BARCODE_PRINTING_REGIONS.includes(region) && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.header} variant="h5">
                                                        Barcode Printing and Queues Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ReactHookFormInput name="number_of_barcodes_per_queue" label="Number of Barcodes Per Queue" fieldOpts={{ type: 'number', variant: 'standard' }} />
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Tank Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ReactHookFormInput name="tank" label="Number of Tanks" fieldOpts={{ type: 'number', variant: 'standard' }} required />
                                        </Grid>
                                        {tankWatch && (
                                            <Grid item xs={12}>
                                                <Typography className={classes.header} variant="h5">
                                                    Calibration Dates for Tanks
                                                </Typography>
                                                <Grid item xs={12} md={6}>
                                                    {tanks.map((e, i) => {
                                                        return (
                                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                <Controller
                                                                    name={`tanks.${i}.calibration_date`}
                                                                    control={control}
                                                                    defaultValue={null}
                                                                    rules={{ required: true }}
                                                                    render={({ field: { onChange, value } }) => {
                                                                        return <DatePicker data-testid={generateTestId(`Tank ${i + 1} Calibration Date`, 'date-picker')} className={classes.date} fullWidth onChange={onChange} format="MM/DD/YYYY" value={value} label={`Tank ${i + 1} Calibration Date`} clearable />;
                                                                    }}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        );
                                                    })}
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!!editData && (
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Typography className={classes.header} variant="h5">
                                                        Bulk Charts for Tanks
                                                    </Typography>
                                                    <Typography variant="h10">*Select a tank to view/update its bulk chart</Typography>
                                                    <ReactHookFormSearchableSelect name="bulk_tank_chart" label="Bulk Tank Chart" options={Array.from({ length: parseInt(editData?.tank) }, (e, i) => i + 1).map((n) => ({ value: n.toString(), name: n.toString() }))} />

                                                    <Grid item xs={12} md={6}>
                                                        <Button disabled={!bulkChartWatch} variant="contained" onClick={downloadTemplateChart}>
                                                            Download Template Bulk Chart
                                                        </Button>
                                                        {bulkCharts && bulkCharts[bulkChartWatch?.value - 1] !== '' && bulkChartWatch && (
                                                            <>
                                                                <Button variant="contained" color="secondary" disabled={!bulkCharts} onClick={downloadCharts} className="mt-16">
                                                                    Download Current Chart
                                                                </Button>

                                                                <ConfirmationDialog disabled={!bulkChartWatch} title="Delete Current Chart" message="Are you sure you want to delete this bulk chart" action={deleteBulkChart} />
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <Typography className="my-16">
                                                        <div className="draggable">
                                                            Replace/Upload Chart: &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                                            <Dropzone handleDrop={uploadNewChart} message="Drag and drop your bulk chart here, or click to select files" />
                                                        </div>
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h5">
                                                Geolocation Information
                                            </Typography>
                                            <Typography variant="h10">Add a geofence to around the plant location</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReactHookFormInput
                                                placeholder={`${latitude},  ${longitude}`}
                                                name="location"
                                                label="Geographic Location"
                                                fieldOpts={{
                                                    variant: 'standard',
                                                    disabled: true,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <i className="text-20 material-icons" color="action">
                                                                    location_on
                                                                </i>
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                }}
                                            />
                                            <Box display="flex">
                                                <GeofenceManager
                                                    center={{ lat: latitude, lng: longitude }}
                                                    onChange={handleEditGeofenceFromMap}
                                                    maxFences={1}
                                                    user_id={editData?.id ?? editData?._id}
                                                    // Force Line Indentation
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                                            <Grid item xs={3}>
                                                <Button variant="contained" color="secondary" className="mx-auto my-16" type="submit">
                                                    {editData ? 'Edit Producer' : 'Add Producer'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </FormProvider>
                        </Box>
                    </>
                )}
            </LocalizationConsumer>
        );
    };
    return <>{render()}</>;
};

export default ReactHookProducerForm;
