import Invoicing from './invoicing';
import SiloInvoices from './silo-invoices';
import Receiving from './receiving';
import Bays from './user-management/plant-management/bays';
import Tanks from './user-management/producer-management/tanks';
import LoanManagement from './loan-management';
import PaymentLineItemConfigs from './payment-line-item-configs';
import ContractManagement from './contract-management';

export default [...Invoicing, ...SiloInvoices, Receiving, ...Bays, ...Tanks, ...PaymentLineItemConfigs, ...LoanManagement, ContractManagement];
