import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/moo-rules',
            component: FuseLoadable({
                loader: () => import('./PaymentLineItemConfigsApp'),
            }),
        },
        {
            path: '/create-moo-rule',
            component: FuseLoadable({
                loader: () => import('./MutatePaymentLineItemConfigs'),
            }),
        },
        {
            path: '/edit-moo-rule/:id',
            component: FuseLoadable({
                loader: () => import('./MutatePaymentLineItemConfigs'),
            }),
        },
    ],
};

export default [Index];
