const commodities = {
    cream: 'Cream',
    condensed_skim: 'Condensed Skim',
    skim: 'Skim Milk',
    dry_sweet_whey: 'Dry Sweet Whey',
    non_fat_dry_milk_powder: 'Non Fat Dry Milk Powder',
};

const config = {
    commodities,
};

export default config;
