import React, { useEffect, useCallback, useContext } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import Big from 'big.js';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Box, Button, Grid, debounce, makeStyles } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { showMessage, addBaseTransfer, editBaseTransfer } from 'app/store/actions';
import moment from 'moment-timezone';
import _ from 'lodash';
import LocalizationContext from 'app/localization/LocalizationContext';
import { generateTestId, setSubmitModelSelectValues, getUserRegion } from 'app/../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, number, date } from 'yup';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    dropdown: {
        marginTop: '-8px',
    },
}));

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

const BaseTransferForm = ({ editData, producers, baseTransfers }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const localization = useContext(LocalizationContext);
    const region = getUserRegion();

    const BaseTransferSchema = object({
        producer_to: object().required('Transfer To is a required field'),
        producer_from: object().optional().nullable(),
        start_date: date().required('Transfer Date is a required field'),
        end_date: date()
            .optional()
            .nullable()
            .when('start_date', (start_date, schema) => {
                if (start_date != null && moment(start_date).isValid()) {
                    return schema.min(start_date, 'Transfer End Date must be after Transfer Date');
                }
                return schema;
            }),
        transfer_amount: number().required('Transfer Amount is a required field').typeError('Transfer Amount must be a number'),
        producer_to_old_base: number().required(`Current ${localization.general.transfer} is a required field`).min(0, `Current ${localization.general.transfer} must be greater than or equal 0`).typeError(`Current ${localization.general.transfer} must be a number`),
        producer_to_new_base: number().required(`New ${localization.general.transfer} is a required field`).min(0, `New ${localization.general.transfer} must be greater than or equal 0`).typeError(`New ${localization.general.transfer} must be a number`),
        producer_from_old_base: number().optional().nullable().min(0, `Current ${localization.general.transfer} must be greater than or equal 0`).typeError(`Current ${localization.general.transfer} must be a number`),
        producer_from_new_base: number()
            .optional()
            .nullable()
            .when('producer_from', (producer_from, schema) => {
                if (producer_from != null) {
                    return schema.min(0, `New ${localization.general.transfer} must be greater than or equal 0`);
                }
                return schema;
            })
            .typeError(`New ${localization.general.transfer} must be a number`),
    });

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            start_date: editData?.start_date ? moment(editData?.start_date) : undefined,
            end_date: editData?.end_date ? moment(editData?.end_date) : undefined,
            producer_to: _.find(producers, (z) => z.value === editData?.producer_to?._id),
            producer_from: _.find(producers, (z) => z.value === editData?.producer_from?._id),
            transfer_amount: editData?.transfer_amount ?? undefined,
            producer_to_old_base: editData?.producer_to_old_base ?? undefined,
            producer_from_old_base: editData?.producer_from_old_base ?? undefined,
            producer_to_new_base: editData?.producer_to_new_base ?? undefined,
            producer_from_new_base: editData?.producer_from_new_base ?? undefined,
        },
        resolver: yupResolver(BaseTransferSchema),
    });
    const { handleSubmit, watch, setValue, errors, control } = reactHookFormMethods;

    const producerToWatch = watch('producer_to');
    const producerFromWatch = watch('producer_from');
    const producerToOldBaseWatch = watch('producer_to_old_base');
    const producerFromOldBaseWatch = watch('producer_from_old_base');
    const transferAmountWatch = watch('transfer_amount');
    const startDateWatch = watch('start_date');

    useEffect(() => {
        setValue('producer_from_new_base', new Big(producerFromOldBaseWatch || 0).minus(new Big(transferAmountWatch || 0)));
        setValue('producer_to_new_base', new Big(producerToOldBaseWatch || 0).plus(new Big(transferAmountWatch || 0)));
    }, [transferAmountWatch, producerToOldBaseWatch, producerFromOldBaseWatch]);

    const findPreviousBaseTransfer = (baseTransfer, producerId) => {
        const producerMatch = baseTransfer?.producer_to?._id === producerId || baseTransfer?.producer_from?._id === producerId;
        const startDateMatch = moment(baseTransfer.start_date).isSameOrBefore(moment(startDateWatch), 'day');
        const endDateMatch = baseTransfer?.end_date == null || moment(baseTransfer.end_date).isSameOrAfter(moment(startDateWatch), 'day');
        return startDateWatch != null ? startDateMatch && endDateMatch && producerMatch : producerMatch;
    };

    const getProducerBaseAmount = (baseTransfer, producerId) => {
        return baseTransfer?.producer_to?._id === producerId ? baseTransfer.producer_to_new_base : baseTransfer.producer_from_new_base;
    };

    const setProducerCurrentBase = (producerId, baseFieldName) => {
        if (producerId != null && editData == null) {
            const previousBaseTransfer = baseTransfers.find((baseTransfer) => {
                return findPreviousBaseTransfer(baseTransfer, producerId);
            });
            const previousBaseAmount = previousBaseTransfer != null ? getProducerBaseAmount(previousBaseTransfer, producerId) : 0;
            setValue(baseFieldName, previousBaseAmount);
        }
    };

    useEffect(() => {
        setProducerCurrentBase(producerToWatch?.value, 'producer_to_old_base');
    }, [producerToWatch]);

    useEffect(() => {
        setProducerCurrentBase(producerFromWatch?.value, 'producer_from_old_base');
    }, [producerFromWatch]);

    useEffect(() => {
        setProducerCurrentBase(producerToWatch?.value, 'producer_to_old_base');
        setProducerCurrentBase(producerFromWatch?.value, 'producer_from_old_base');
    }, [startDateWatch]);

    const transformProducerLabel = (option) => {
        if (region === 'CDI') {
            return `${option.license_number ?? ''} - ${option.state_number ?? ''} - ${option.name ?? ''}`;
        }
        return `${option.license_number ?? ''} - ${option.name ?? ''}`;
    };

    const post = (submitModel) => {
        dispatch(addBaseTransfer(submitModel))
            .then(() => {
                dispatch(showMessage({ message: `Successfully Added ${localization.general.transfer} Transfer` }));
                history.replace({ pathname: '/base-transfers' });
            })
            .catch((err) => {
                showMessage({ message: err.message });
            });
    };

    const put = (submitModel) => {
        dispatch(editBaseTransfer(submitModel, editData._id))
            .then(() => {
                dispatch(showMessage({ message: `Successfully Edited ${localization.general.transfer} Transfer` }));
                history.replace({ pathname: '/base-transfers' });
            })
            .catch((err) => {
                showMessage({ message: err.message });
            });
    };

    const onSubmit = (model) => {
        const submitModel = setSubmitModelSelectValues(model);
        submitModel.start_date = submitModel?.start_date ? moment(submitModel.start_date).startOf('day').format() : moment().startOf('day').format();
        submitModel.end_date = submitModel?.end_date ? moment(submitModel.end_date).endOf('day').format() : undefined;
        submitModel.producer_to_old_base = parseFloat(submitModel.producer_to_old_base || 0);
        submitModel.producer_from_old_base = parseFloat(submitModel.producer_from_old_base || 0);
        submitModel.producer_to_new_base = parseFloat(submitModel.producer_to_new_base || 0);
        submitModel.producer_from_new_base = parseFloat(submitModel.producer_from_new_base || 0);
        submitModel.transfer_amount = parseFloat(submitModel.transfer_amount || 0);
        if (!editData) {
            // these values are set by a pre-save hook on the model
            delete submitModel.producer_from_new_base;
            delete submitModel.producer_to_new_base;
        }
        if (!submitModel?.end_date) {
            delete submitModel.end_date;
        }
        if (!submitModel?.producer_from) {
            delete submitModel.producer_from;
            delete submitModel.producer_from_old_base;
            delete submitModel.producer_from_new_base;
        }
        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <div>
                <FormProvider {...reactHookFormMethods}>
                    <form noValidate onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="start_date"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field: { onChange, value } }) => {
                                            return <DatePicker data-testid={generateTestId('Start Date', 'date-picker')} fullWidth openTo="year" label="Transfer Date" disableToolbar format={'MMMM DD, YYYY'} value={value} onChange={onChange} />;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="end_date"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    data-testid={generateTestId('End Date', 'date-picker')}
                                                    fullWidth
                                                    openTo="year"
                                                    label="Transfer End Date"
                                                    disableToolbar
                                                    format={'MMMM DD, YYYY'}
                                                    minDate={startDateWatch ? moment(startDateWatch).startOf('day').add(1, 'day') : undefined}
                                                    minDateMessage={'Transfer End Date must be after Transfer Date'}
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            );
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactHookFormInput name="transfer_amount" label="Transfer Amount" type="number" required />
                            </Grid>
                            <Grid item xs={12} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect label={'Transfer From'} name={'producer_from'} options={producers.sort((a, b) => collator.compare(a.license_number, b.license_number))} customRender={transformProducerLabel} />
                            </Grid>
                            {producerFromWatch?.value && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <ReactHookFormInput name="producer_from_old_base" label={`Current ${localization.general.transfer}`} type="number" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ReactHookFormInput name="producer_from_new_base" label={`New ${localization.general.transfer}`} type="number" disabled />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect label={'Transfer To'} name={'producer_to'} options={producers.sort((a, b) => collator.compare(a.license_number, b.license_number))} customRender={transformProducerLabel} required />
                            </Grid>
                            {producerToWatch?.value && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <ReactHookFormInput name="producer_to_old_base" label={`Current ${localization.general.transfer}`} type="number" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ReactHookFormInput name="producer_to_new_base" label={`New ${localization.general.transfer}`} type="number" disabled />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>
                                <Box>
                                    <Button type="submit" variant="contained" color="primary">
                                        {`${editData ? 'Edit' : 'Add'} ${localization.general.transfer}`}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
        );
    };

    return <>{render()}</>;
};

export default withRouter(BaseTransferForm);
