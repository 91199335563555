import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers-pro';

const getBoxStyles = ({ isWithinRange, isStart, isEnd }, theme) => ({
    ...(isWithinRange && {
        backgroundColor: theme.palette.secondary.main,
    }),
    ...(isStart && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isEnd && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
});

const getPickersDayStyles = ({ isToday, isWithinRange, isStart }, theme) => ({
    '&.Mui-selected': {
        ...(isStart && {
            // Required to remove the built-in highlight for the first day
            backgroundColor: 'transparent !important',
        }),
    },
    '&.Mui-selected:hover': {
        ...(isStart && {
            backgroundColor: 'transparent',
        }),
    },
    ...(isToday && {
        border: `1px solid ${theme.palette.primary.light}`,
    }),
    '&:not(.Mui-selected)': {
        ...(isToday && {
            border: `1px solid ${theme.palette.primary.light}`,
        }),
    },
    ...(isWithinRange && {
        color: theme.palette.common.white,
    }),
});

/**
 * Custom component to handle highlighted dates in MUI pro date pickers.
 * @param {Object} props - The properties passed to the custom day component.
 * @param {import('moment').Moment} props.day - The date being rendered.
 * @param {Object} props.range - The range of dates to apply highlights.
 * @param {import('moment').Moment} props.range.startDate - The start date of the highlighted range.
 * @param {import('moment').Moment} props.range.endDate - The end date of the highlighted range.
 * @param {Object} props.rest - Additional properties passed to the component.
 */

const CustomPickersDay = ({ day, range, ...rest }) => {
    const { startDate, endDate } = range;

    if (!startDate || !endDate) {
        return <PickersDay {...rest} day={day} />;
    }

    const isWithinRange = day.isSameOrAfter(startDate, 'day') && day.isSameOrBefore(endDate, 'day');
    const isToday = day.isSame(moment(), 'day');
    const isStart = day.isSame(startDate, 'day');
    const isEnd = day.isSame(endDate, 'day');

    return (
        <Box sx={(theme) => getBoxStyles({ isWithinRange, isStart, isEnd }, theme)}>
            <PickersDay
                {...rest}
                day={day}
                sx={(theme) => getPickersDayStyles({ isToday, isWithinRange, isStart }, theme)}
                // wrap
            />
        </Box>
    );
};

export default CustomPickersDay;
