import React from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import TrailerEventDetails from './helper-components/TrailerEventDetails';

const MapPointTrailerEvent = ({ event, color = '#00A2F5' }) => {
    const { latitude, longitude, _id } = event;
    const center = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
    };

    const circleIcon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 1,
        scale: 10,
        strokeWeight: 0,
    };

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -height,
    });

    return (
        <>
            <Marker
                key={_id}
                position={center}
                icon={circleIcon}
                // wrap
            />
            <OverlayView
                key={_id}
                position={center}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
                // wrap
            >
                <TrailerEventDetails event={event} />
            </OverlayView>
        </>
    );
};

export default React.memo(MapPointTrailerEvent);
