import axios from 'axios';
import _ from 'lodash';
import { getProcessorsQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getProcessorListDetails = (id, includeDeleted, isExpressRequired = false) =>
    new Promise((resolve, reject) => {
        const fields = [
            'created_at',
            'deleted_at',
            'email',
            'secondary_email',
            'id',
            'name',
            'permissions',
            'geofence',
            'latitude',
            'longitude',
            'updated_at',
            'username',
            'license_number',
            'federal_order',
            'pooled_handler',
            'rBST_free_program_processor',
            'rBST_processor_charge',
            'rBST_processor_share',
            'fmmo_history',
            'processor_pooling_fmmo',
            'milk_choice_history',
            'plant_type',
            'requires_rapid_test',
            'requires_pump_off',
            'enable_mobile_receiver_ui',
            'enable_mobile_rapid_test_ui',
            'enable_processed_fluids_qa_receiving',
            'plant_qualified_status',
            'address1',
            'address2',
            'town',
            'state',
            'postal_code',
            'status',
            'customer_id',
            'processor_default_weight',
            'processor_class_type',
            'is_organic',
            'settlement_gl_number',
            'advance_gl_number',
            'enable_processed_fluids_qa_receiving',
        ];

        const params = { fields, ...(!includeDeleted && { filterDeleted: true }), ...(!!id && { processorId: id }) };

        const query = getProcessorsQuery({ ...params, ...(isExpressRequired && { queryBuilder: buildExpressQuery }) });
        const request = axios.get(query);

        request
            .then((response) => {
                const processors = _.orderBy(response.data, ['name'], ['asc']);

                resolve({ processors });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProcessorListDetails;
